import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import Header2 from "../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { Select, MenuItem } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { toast, ToastContainer } from "react-toastify";
import ghostTag from "../.././assets/OBJECTS.png";
import guestsImg from "../.././assets/guests_logo.png";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import Avatar from "@mui/material/Avatar";
import { MoonLoader } from "react-spinners";

import { getTokenFromLocalStorage } from "../authService";
import noEvent from "../../assets/MyEvents.png";
import Footer from "../FooterPages/Footer";
import calenderImg from "../../assets/ph_calendar-duotone.png";
import timeImg from "../../assets/ph_clock.png";
import ageImg from "../../assets/Age.png";
import genderImg from "../../assets/icons8_gender.png";
import dressImg from "../../assets/dresscode 1.png";
import locationImg from "../../assets/location2.png";
import fareImg from "../../assets/what i will Cover.png";
import descImg from "../../assets/Event_desc.png";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "32px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "28px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    body2: {
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Lora",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
    subtitle1: {
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Lora",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});
const listtextStyle = {
  color: "#202226",
  fontFamily: "Lora",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingLeft: "10px",
};

const customTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            borderColor: "#863895",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:hover:not($disabled):before": {
            borderColor: "#863895",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover:not($disabled):before": {
            borderColor: "#863895",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:hover:not($disabled):before": {
            borderColor: "#863895",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          border: "1px solid #863895",
          width: "calc(100% - 50px)",
          marginTop: "30px",
          marginBottom: "16px",
          marginLeft: "25px",
          color: "#863895",
          "&:focus": {
            outline: "none", // Remove focus outline
            borderColor: "#863895",
          },
        },
        icon: {
          color: "#863895", // Set the color you want for the dropdown icon
        },
        select: {
          color: "#863895", // Set the text color of the selected value
        },
      },
    },
    sx: {
      "&:focus": {
        outline: "none",
        borderColor: "#ced4da",
      },
      "textarea:focus": {
        boxShadow: "none !important",
        borderColor: "#863895 !important",
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#fff", // Set the background color of the dropdown list
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#863895", // Set the background color on hover
            color: "#fff", // Set the text color on hover
          },
          "&.Mui-selected": {
            backgroundColor: "#A46AAF", // Set the specific background color for the selected menu item
            color: "#000", // Set the text color for the selected menu item
          },
        },
      },
    },
  },
});

const MyEvents = ({ eventListProp }) => {
  const [open, setOpen] = useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [isSafetyConcern, setSafetyConcern] = useState(true);
  const [eventList, setEventList] = useState(null);
  const [forceUpdate, setForceUpdate] = React.useState(false);
  const [allRequestList, setAllRequestList] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [profileImageData, setProfileImageData] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [filteredRequestList, setFilteredRequestList] = useState([]);
  const [cancelEventId, setCancelEventId] = useState("");
  const [cancelEventText, setCancelEventText] = useState("");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  // const images = [
  //   profileImageData?.profile_img,
  //   profileImageData?.image_2,
  //   profileImageData?.image_3,
  //   profileImageData?.image_4,
  //   // Add more image URLs as needed
  // ];

  const navigate = useNavigate();
  const handleCancelEventText = (e) => {
    setCancelEventText(e.target.value);
  };

  const getUpdatedRequestList = () => {
    const storedList = localStorage.getItem("updatedRequestList");
    return storedList ? JSON.parse(storedList) : [];
  };

  const updateAndSetRequestList = (updatedList) => {
    localStorage.setItem("updatedRequestList", JSON.stringify(updatedList));
    setFilteredRequestList(updatedList);
    // debugger
  };

  const handleAvatarClick = async (userId) => {
    try {
      // Get the token
      const token = getTokenFromLocalStorage();

      // Make the API request using Axios
      const response = await axios.get(`/get_profile_images/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set the API response data in the state
      setProfileImageData(response.data.data);
      setCurrentImageIndex(0);
      setOpenPopup(true);
    } catch (error) {
      // Handle any errors
      console.error("Error:", error.message);
    }
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const images = [
    profileImageData?.profile_img,
    profileImageData?.image_2,
    profileImageData?.image_3,
    profileImageData?.image_4,
    // Add more image URLs as needed
  ];

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const genderText =
    profileImageData?.gender === "0"
      ? "Other"
      : profileImageData?.gender === "1"
      ? "Male"
      : profileImageData?.gender === "2"
      ? "Female"
      : "";

  const handleDecline = (eventId, userId) => {
    const updatedRequestList = filteredRequestList.filter(
      (request) => request.event_id !== eventId || request.user_id !== userId
    );

    // console.log("Updated Request List:", updatedRequestList);

    updateAndSetRequestList(updatedRequestList);
  };
  const handleConsider = (eventId, userId) => {
    // Update the status of the considered request
    const updatedRequestList = filteredRequestList.map((request) =>
      request.event_id === eventId && request.user_id === userId
        ? { ...request, status: "4" } // Update status to "3" for considered
        : request
    );

    // Update the request list with the updated status
    updateAndSetRequestList(updatedRequestList);
  };

  const handleReasonClick = () => {
    if (!cancelEventText.trim()) {
      // If blank, show error using toast.error and return without making the API call
      toast.error("Remarks field is required.");
      return;
    }

    handleClose();

    const formData = new FormData();
    formData.append("remarks", cancelEventText);
    formData.append("event_id", cancelEventId);

    const token = getTokenFromLocalStorage();

    axios
      .post("cancelRequestEvent", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          fetchEventDetails();

          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const handleClick = async (
  //   buttonId,
  //   event_details,
  //   user_details,
  //   handleConsider
  // ) => {
  //   const token = getTokenFromLocalStorage();

  //   try {
  //     if (!token) {
  //       console.error("Token not found in local storage");
  //       return;
  //     }

  //     const apiUrl = "changeRequestStatus";

  //     let status;
  //     if (buttonId === "acceptButton") {
  //       status = "1";
  //     } else if (buttonId === "declineButton") {
  //       status = "2";
  //       const confirmed = await Swal.fire({
  //         title: 'Are you sure?',
  //         text: 'Declining a Potential invitee request may result in your profile being tagged.',
  //         icon: 'warning',
  //         showCancelButton: true,
  //         confirmButtonText: 'Yes, I am sure',
  //         cancelButtonText: 'Cancel',
  //       });

  //       if (!confirmed.value) {
  //         return; // If user cancels, return without further processing
  //       }
  //     } else if (buttonId === "considerButton") {
  //       status = "4";
  //     }

  //     const params = {
  //       event_id: event_details.id,
  //       user_id: user_details.id,
  //       status: status,
  //     };

  //     const response = await axios.post(apiUrl, params, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     // console.log("API Response:", response.data);

  //     if (response.data.success) {
  //       if (buttonId === "acceptButton") {
  //         // Update the status of the accepted request in the list
  //         const updatedRequestList = filteredRequestList.map((request) =>
  //           request.event_id === event_details.id &&
  //           request.user_id === user_details.id
  //             ? { ...request, status: "1" } // Update status to "1" for accepted
  //             : request
  //         );
  //         updateAndSetRequestList(updatedRequestList);
  //       }

  //       if (buttonId === "declineButton") {
  //         // Update the status of the declined request in the list
  //         const updatedRequestList = filteredRequestList.map((request) =>
  //           request.event_id === event_details.id &&
  //           request.user_id === user_details.id
  //             ? { ...request, status: "2" } // Update status to "2" for declined
  //             : request
  //         );
  //         updateAndSetRequestList(updatedRequestList);
  //         handleDecline(event_details.id, user_details.id);
  //       }

  //       if (buttonId === "considerButton") {
  //         // Update the status of the considered request in the list
  //         const updatedRequestList = filteredRequestList.map((request) =>
  //           request.event_id === event_details.id &&
  //           request.user_id === user_details.id
  //             ? { ...request, status: "4" } // Update status to "3" for considered
  //             : request
  //         );

  //         updateAndSetRequestList(updatedRequestList);

  //         // Trigger success toast message
  //         toast.success("Now you can communicate with the invitee", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error in API request:", error);
  //   }
  // };
  const handleClick = async (
    buttonId,
    event_details,
    user_details,
    handleConsider
  ) => {
    const token = getTokenFromLocalStorage();

    try {
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "changeRequestStatus";

      let status;
      if (buttonId === "acceptButton") {
        status = "1";
      } else if (buttonId === "declineButton") {
        // Find the request in the filteredRequestList
        const currentRequest = filteredRequestList.find(
          (request) =>
            request.event_id === event_details.id &&
            request.user_id === user_details.id
        );

        // Check if the current status is "1"
        if (currentRequest && currentRequest.status === "1") {
          const confirmed = await Swal.fire({
            title: "Are you sure?",
            text: "Declining an accepted request may result in your profile being ghost tagged.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, I am sure",
            cancelButtonText: "Cancel",
          });

          if (!confirmed.value) {
            return;
          }
        }

        status = "2";
      } else if (buttonId === "considerButton") {
        status = "4";
      }

      const params = {
        event_id: event_details.id,
        user_id: user_details.id,
        status: status,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        if (buttonId === "acceptButton") {
          // Update the status of the accepted request in the list
          const updatedRequestList = filteredRequestList.map((request) =>
            request.event_id === event_details.id &&
            request.user_id === user_details.id
              ? { ...request, status: "1" } // Update status to "1" for accepted
              : request
          );
          updateAndSetRequestList(updatedRequestList);
        }

        if (buttonId === "declineButton") {
          // Update the status of the declined request in the list
          const updatedRequestList = filteredRequestList.map((request) =>
            request.event_id === event_details.id &&
            request.user_id === user_details.id
              ? { ...request, status: "2" } // Update status to "2" for declined
              : request
          );
          updateAndSetRequestList(updatedRequestList);
          handleDecline(event_details.id, user_details.id);
        }

        if (buttonId === "considerButton") {
          // Update the status of the considered request in the list
          const updatedRequestList = filteredRequestList.map((request) =>
            request.event_id === event_details.id &&
            request.user_id === user_details.id
              ? { ...request, status: "4" } // Update status to "4" for considered
              : request
          );

          updateAndSetRequestList(updatedRequestList);

          // Trigger success toast message
          toast.success("Now you can communicate with the invitee", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.error("Error in API request:", error);
    }
  };

  const handleEventChange = (event) => {
    const newSelectedEventId = event.target.value;
    setSelectedEventId(newSelectedEventId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        if (!token) {
          console.error("Token not found in local storage");
        }
        // debugger

        const requestListResponse = await axios.get(
          `allRequestList/${selectedEventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // debugger

        const allRequestListData =
          requestListResponse.data &&
          requestListResponse.data.data &&
          Array.isArray(requestListResponse.data.data)
            ? requestListResponse.data.data
            : [];
        // debugger
        setAllRequestList(allRequestListData);
        const newFilteredRequestList = allRequestListData.filter(
          (obj) => obj.status !== "2"
        );
        setFilteredRequestList(newFilteredRequestList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedEventId]);

  const fetchEventDetails = async () => {
    try {
      const token = getTokenFromLocalStorage();

      // if (!token) {
      //   console.error("Token not found in local storage");

      //   navigate('/login');
      //   return;
      // }

      const listResponse = await axios.get("eventList", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response for Event List:", listResponse);
      console.log("Raw API Response for Event List:", listResponse.data);

      const eventListData =
        listResponse.data &&
        listResponse.data.data &&
        Array.isArray(listResponse.data.data)
          ? listResponse.data.data
          : [];

      if (Array.isArray(eventListData)) {
        setEventList(eventListData);
        setSelectedEventId(eventListData[0].id);
      }

      eventListData.map(async (event) => {
        const data = event;
        const startDate = new Date(data.starttime);
        const endDate = new Date(data.endtime);

        const startDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.startDateComponent = startDate.toLocaleDateString(
          "en-US",
          startDateOptions
        );

        const startTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.startTimeComponent = startDate.toLocaleTimeString(
          "en-US",
          startTimeOptions
        );

        const endDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.endDateComponent = endDate.toLocaleDateString(
          "en-US",
          endDateOptions
        );
        const endTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.endTimeComponent = endDate.toLocaleTimeString(
          "en-US",
          endTimeOptions
        );

        try {
          const response = await axios.get(`eventDetails/${event.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      });
    } catch (error) {
      console.error("Error fetching event list:", error);
    }
  };

  useEffect(() => {
    // if (localStorage.getItem("status")) {
    fetchEventDetails();
    // }
  }, []);

  const handleClickOpen = (itemId) => {
    setOpen(true);
    setCancelEventId(itemId);
  };

  const handleClose = () => {
    setOpen(false);
    setReasonDialogOpen(false);
  };

  const handleYesClick = () => {
    setOpen(false);
    setReasonDialogOpen(true);
  };

  const handleEditClick = (item) => {
    // console.log("Selected Event ID:", item);
    // setEvent(item);
  };

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[35px] mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />
        <div
          style={{
            // background:
            //   "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={6}
            sx={{
              width: "100%",
              margin: "auto",
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={eventList && eventList.length > 0 ? 6 : 12}
              lg={eventList && eventList.length > 0 ? 6 : 12}
              xl={eventList && eventList.length > 0 ? 6 : 12}
            >
              {headerLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 9999,
                  }}
                >
                  <MoonLoader color="#863895" size={80} />
                </div>
              )}
              <ThemeProvider theme={headingFont}>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "35px", marginLeft: "15px" }}
                >
                  My Events (
                  {eventList && eventList.length
                    ? eventList.length.toString().padStart(2, "0")
                    : "0"}
                  )
                </Typography>
              </ThemeProvider>

              {eventList && eventList.length > 0 ? (
                eventList.map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: "100%",
                      borderRadius: "20px",
                      // margin: "5px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                      backgroundColor: "rgba(160, 195, 255, 0.03)",
                      marginBottom: index < eventList.length - 1 ? "20px" : 0,
                    }}
                  >
                    <CardContent>
                      <Grid
                        container
                        mb={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                          marginLeft: "20px",
                        }}
                      >
                        <ThemeProvider theme={headingFont}>
                          <Typography variant="h5" mb={1}>
                            {item.event_name}
                          </Typography>
                        </ThemeProvider>

                        <ThemeProvider theme={headingFont}>
                          <Typography variant="subtitle1">
                            {item.description}
                          </Typography>
                        </ThemeProvider>
                      </Grid>

                      <List aria-label="contacts">
                        <ListItem>
                          <img
                            src={calenderImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />

                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.startDateComponent} To{" "}
                              {item.endDateComponent}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <img
                            src={timeImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />

                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.startTimeComponent} To{" "}
                              {item.endTimeComponent}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <img
                            src={ageImg}
                            alt="EventImage"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.age_from} to {item.age_to}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <img
                            src={genderImg}
                            alt="EventImage"
                            style={{
                              width: "22px",
                              height: "22px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {(() => {
                                const genderId = parseInt(item.gender, 10);

                                switch (genderId) {
                                  case 1:
                                    return "Male";
                                  case 2:
                                    return "Female";
                                  case 3:
                                    return "";
                                  case 4:
                                    return "All";
                                  default:
                                    return "Unknown";
                                }
                              })()}{" "}
                              {""} {item.gender_description}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <img
                            src={dressImg}
                            alt="EventImage"
                            style={{
                              width: "24px",
                              height: "25px",
                            }}
                          />
                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.dresscode}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        {item.no_of_invities !== null && (
                          <ListItem>
                            <img
                              src={guestsImg}
                              alt="EventImage"
                              style={{
                                width: "25px",
                                height: "22px",
                              }}
                            />
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.no_of_invities}
                                {"\u00A0"}Guests
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        )}
                        <ListItem>
                          <img
                            src={locationImg}
                            alt="EventImage"
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          />

                          <ListItemText>
                            <Typography
                              style={listtextStyle}
                              variant="subtitle2"
                            >
                              {item.location}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                        {item.eventfeature && (
                          <ListItem>
                            <img
                              src={fareImg}
                              alt="EventImage"
                              style={{
                                width: "24px",
                                height: "25px",
                              }}
                            />
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.eventfeature}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        )}

                        {item.activity_details && (
                          <ListItem>
                            <img
                              src={descImg}
                              alt="EventImage"
                              style={{
                                width: "24px",
                                height: "25px",
                              }}
                            />
                            <ListItemText>
                              <Typography
                                style={listtextStyle}
                                variant="subtitle2"
                              >
                                {item.activity_details}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        )}
                      </List>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Stack direction="row" spacing={2} mb={2}>
                        <Button
                          variant="outlined"
                          sx={{
                            border: "1px solid #FF2B2B !important",
                            borderRadius: "6px",
                            "&:hover": {
                              backgroundColor: "none",
                            },
                            color: "#FF2B2B",
                            padding: "14px 50px",
                            fontFamily: "Lora",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            textTransform: "none !important",
                            fontFeatureSettings: `'clig' off, 'liga' off`,
                          }}
                          onClick={() => handleClickOpen(item.id)}
                        >
                          Cancel Event
                        </Button>
                        <Dialog
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          sx={{ textAlign: "center" }}
                        >
                          <DialogTitle
                            id="alert-dialog-title"
                            sx={{
                              color: "#222",
                              fontFamily: "Lora",
                              fontSize: "20px !important ",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Are you sure you'd like to cancel your event?
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText
                              id="alert-dialog-description"
                              sx={{
                                color: "#383838",
                                fontFamily: "Lora",
                                fontSize: "14px !important ",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textAlign: "center",
                              }}
                            >
                              Cancelling within 48 hours of an event may result
                              in your profile being tagged.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <Button
                              onClick={handleClose}
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                "&:hover": {
                                  backgroundColor: "none",
                                  border: "1px solid #863895",
                                },
                                color: "#863895",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              No
                            </Button>
                            <Button
                              onClick={handleYesClick}
                              sx={{
                                width: "100%",
                                maxWidth: 200,

                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: "rgba(134, 56, 149, 0.75)",
                                "&:hover": {
                                  backgroundColor: "rgba(134, 56, 149, 0.75)",
                                  border: "1px solid #863895",
                                  color: "#FFF",
                                },
                                color: "#FFF",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                              autoFocus
                            >
                              Yes, Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Dialog
                          open={reasonDialogOpen}
                          onClose={handleClose}
                          aria-labelledby="reason-dialog-title"
                          aria-describedby="reason-dialog-description"
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <DialogTitle
                            id="reason-dialog-title"
                            sx={{
                              color: "#222",
                              fontFamily: "Lora",
                              fontSize: "20px !important ",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            Reason for Cancellation
                          </DialogTitle>
                          <DialogContent>
                            <Stack direction="row" spacing={2}>
                              <Button
                                onClick={() => {
                                  setSafetyConcern(true);
                                }}
                                sx={{
                                  width: "100%",
                                  border: "1px solid #863895",
                                  borderRadius: "6px",
                                  backgroundColor: isSafetyConcern
                                    ? "rgba(134, 56, 149, 0.75) !important"
                                    : "none",
                                  color: isSafetyConcern ? "#FFF" : "#863895",
                                  fontFamily: "Lora",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                  fontFeatureSettings: `'clig' off, 'liga' off`,
                                }}
                              >
                                Safety Concern
                              </Button>
                              <Button
                                onClick={() => {
                                  setSafetyConcern(false);
                                }}
                                sx={{
                                  width: "100%",
                                  border: "1px solid #863895",
                                  borderRadius: "6px",
                                  backgroundColor: isSafetyConcern
                                    ? "none"
                                    : "rgba(134, 56, 149, 0.75) !important",
                                  color: isSafetyConcern ? "#863895" : "#FFF",
                                  fontFamily: "Lora",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                  fontFeatureSettings: `'clig' off, 'liga' off`,
                                }}
                              >
                                Other
                              </Button>
                            </Stack>

                            <TextField
                              id="name"
                              onChange={(e) => handleCancelEventText(e)}
                              placeholder={
                                isSafetyConcern
                                  ? "Tell us about the safety concern..."
                                  : "Tell us what happened..."
                              }
                              label=""
                              type="name"
                              multiline
                              fullWidth
                              variant="outlined"
                              rows={6}
                              sx={{
                                marginTop: "20px",
                                "&:focus": {
                                  outline: "none",
                                  borderColor: "#ced4da",
                                },
                                "&& textarea:focus": {
                                  boxShadow: "none !important",
                                  borderColor: "#863895 !important",
                                },
                              }}
                            />
                          </DialogContent>
                          <DialogActions sx={{ justifyContent: "center" }}>
                            <Button
                              onClick={handleReasonClick}
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                border: "1px solid #863895",
                                borderRadius: "6px",
                                backgroundColor: "rgba(134, 56, 149, 0.75)",
                                "&:hover": {
                                  backgroundColor: "rgba(134, 56, 149, 0.75)",
                                  border: "1px solid #863895",
                                  color: "#FFF",
                                },
                                color: "#FFF",
                                fontFamily: "Lora",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                                fontFeatureSettings: `'clig' off, 'liga' off`,
                              }}
                            >
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                        <Button
                          key={index}
                          variant="contained"
                          component={Link}
                          to={`/edit_event/${item.id}`}
                          sx={{
                            border: "1px solid #863895",
                            backgroundColor: "#863895",
                            borderRadius: "5px",
                            padding: "14px 50px",
                            "&:hover": {
                              backgroundColor: "#863895",
                              color: "#FFF",
                            },
                            color: "#FFF",
                            fontFamily: "Lora",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            textTransform: "none !important",
                            fontFeatureSettings: `'clig' off, 'liga' off`,
                          }}
                          onClick={() => handleEditClick(item)}
                        >
                          Edit Event
                        </Button>
                      </Stack>
                    </CardActions>
                  </Card>
                ))
              ) : (
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 1200,
                    textAlign: "center",
                    height: "auto",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "rgba(160, 195, 255, 0.03)",
                  }}
                >
                  <img
                    src={noEvent}
                    alt="Mobile"
                    style={{
                      width: "35%", // Initial width for larger screens
                      height: "auto", // Maintain aspect ratio
                      "@media screen and (max-width: 600px)": {
                        width: "50% !important",
                      },
                    }}
                  />

                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        marginTop: 2,
                        color: "#2F2F2F",
                        textAlign: "center",
                        fontFamily: "Lora",
                        fontSize: "32px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                        "@media screen and (max-width: 600px)": {
                          fontSize: "24px", // Adjust the font size for smaller screens
                        },
                        "@media screen and (max-width: 400px)": {
                          fontSize: "16px", // Further adjust the font size for even smaller screens
                        },
                      }}
                    >
                      No events to show. Please create an event.
                    </Typography>
                    <Link to="/create_event" style={{ textDecoration: "none" }}>
                      <Button
                        sx={{
                          marginTop: 2,
                          backgroundColor: "#863895",
                          "&:hover": {
                            backgroundColor: "#863895",
                          },
                          "@media screen and (max-width: 600px)": {
                            width: "60%", // Adjust the button width for smaller screens
                            fontSize: "9px", // Adjust the font size for smaller screens
                          },
                        }}
                        variant="contained"
                      >
                        Create Event
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              )}
            </Grid>
            {eventList && eventList.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                {/* <Grid
                container
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
               
                <Stack>
                  <ThemeProvider theme={headingFont}>
                    <Typography variant="h6">Requests Received</Typography>
                  </ThemeProvider>
                </Stack>
                <div>
                  <Select
                    value={selectedEventId}
                    onChange={(e) => handleEventChange(e)}
                    style={{
                      width: "300px",
                      borderRadius: "10px",
                      border: "2px solid #863895  !important",
                    }}
                  >
                    {eventList &&
                      eventList.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.event_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </div>
              </Grid> */}
                <ThemeProvider theme={headingFont}>
                  <Typography
                    variant="h6"
                    sx={{ marginBottom: "35px", marginLeft: "15px" }}
                  >
                    Potential Invitees
                  </Typography>
                </ThemeProvider>
                <Card
                  sx={{
                    width: "100%",
                    borderRadius: "20px",
                    flexDirection: "column",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                    backgroundColor: "rgba(160, 195, 255, 0.03)",
                  }}
                >
                  {/* <Select
                  value={selectedEventId}
                  onChange={(e) => handleEventChange(e)}
                  style={{
                    border: "2px solid #863895 !important",
                    width: "calc(100% - 16px)", 
                    marginTop: "30px",
                    marginBottom: "16px",
                    marginLeft: "8px", 
                    marginRight: "8px",
                  }}
                >
                  {eventList &&
                    eventList.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.event_name}
                      </MenuItem>
                    ))}
                </Select> */}
                  <ThemeProvider theme={customTheme}>
                    <Select
                      value={selectedEventId}
                      onChange={(e) => handleEventChange(e)}
                    >
                      {eventList &&
                        eventList.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.event_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </ThemeProvider>
                  {filteredRequestList.map((filteredRequestItem, index) => (
                    <CardContent
                      key={filteredRequestItem.id}
                      sx={{
                        paddingBottom: "0px", // Set your desired paddingBottom value
                        paddingTop: "0px",
                        opacity: filteredRequestItem.isDisabled ? 0.5 : 1, // Adjust the opacity based on your condition
                        pointerEvents: filteredRequestItem.isDisabled
                          ? "none"
                          : "auto", // Disable pointer events based on your condition
                      }}
                    >
                      <Stack>
                        <ThemeProvider theme={headingFont}>
                          <Typography variant="h5">
                            {filteredRequestItem.user_details.title}
                          </Typography>
                        </ThemeProvider>
                      </Stack>
                      <List
                        sx={{
                          width: "100%",
                        }}
                      >
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <button
                              onClick={() =>
                                handleAvatarClick(
                                  filteredRequestItem.user_details.id
                                )
                              }
                              style={{
                                cursor: "pointer",
                                border: "none",
                                background: "none",
                                padding: 0,
                              }}
                            >
                              <Avatar
                                onClick={() => setOpenPopup(true)}
                                alt={filteredRequestItem.user_details.name}
                                src={filteredRequestItem.profile_img}
                                sx={{ width: "52px", height: "52px" }}
                              />
                            </button>
                          </ListItemAvatar>
                          <Stack
                            sx={{ marginLeft: "24px", position: "relative" }}
                          >
                            {filteredRequestItem.user_details.is_ghost ===
                              1 && (
                              <img
                                src={ghostTag}
                                alt="ellipseTwelve"
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  position: "absolute",
                                  left: "-24px",
                                  top: "19%",
                                  transform: "translate(0, -50%)",
                                  zIndex: 1,
                                }}
                                className="sm:left-58"
                              />
                            )}

                            <Typography
                              variant="h6"
                              sx={{
                                color: "#222",
                                fontFamily: "Lora",
                                fontSize: "18px",
                                left: "16%",
                                fontStyle: "normal",
                                fontWeight: "700",
                                textTransform: "none !important",
                              }}
                            >
                              {filteredRequestItem.user_details.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "var(--General-Dark, #222)",
                                fontFamily: "Lora",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                textTransform: "none !important",
                              }}
                            >
                              {(() => {
                                const genderId = parseInt(
                                  filteredRequestItem.user_details.gender,
                                  10
                                );

                                switch (genderId) {
                                  case 1:
                                    return "Male";
                                  case 2:
                                    return "Female";
                                  case 3:
                                    return "";
                                  case 4:
                                    return "All";
                                  default:
                                    return "Unknown";
                                }
                              })()}{" "}
                              {""}{" "}
                              {
                                filteredRequestItem.user_details
                                  .gender_description
                              }
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#222",
                                fontFamily: "Lora",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "30px",
                                textTransform: "none !important",
                              }}
                            >
                              {filteredRequestItem.user_details.age}
                            </Typography>
                          </Stack>
                        </ListItem>
                        <Stack spacing={2} direction="row">
                          <Button
                            id="considerButton"
                            variant="outlined"
                            sx={{
                              width: "80%",
                              border: "0.968px solid #863895",
                              padding: "11px 0px",
                              backgroundColor: "#863895 !important",
                              // filteredRequestItem.status === "1"
                              //   ? "rgba(134, 56, 149, 0.21)"
                              //   : "#863895 !important",
                              borderRadius: "6px",
                              color: "#FFF",
                              // filteredRequestItem.status !== "1"
                              //   ? "#FFF"
                              //   : "#863895",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                              // "&[disabled]": {
                              //   backgroundColor:
                              //     filteredRequestItem.status === "1"
                              //       ? "rgba(134, 56, 149, 0.21)"
                              //       : filteredRequestItem.status === "4"
                              //       ? "#D1BD0A !important"
                              //       : "#863895 !important",
                              //   color:
                              //     filteredRequestItem.status === "4"
                              //       ? "#FFF"
                              //       : undefined,

                              //   cursor: "not-allowed",
                              // },
                            }}
                            // disabled={
                            //   filteredRequestItem.status === "1" ||
                            //   filteredRequestItem.status === "4"
                            // }
                            onClick={() =>
                              handleClick(
                                "considerButton",
                                filteredRequestItem.event_details,
                                filteredRequestItem.user_details,
                                handleConsider
                              )
                            }
                          >
                            {filteredRequestItem.status === "4"
                              ? "Request Considered"
                              : "Consider Request"}
                          </Button>

                          <Button
                            id="declineButton"
                            variant="outlined"
                            sx={{
                              width: "80%",
                              border: "0.968px solid #863895",
                              padding: "11px 0px",
                              backgroundColor: "#863895 !important",
                              // filteredRequestItem.status === "1"
                              //   ? "rgba(134, 56, 149, 0.21)"
                              //   : "#863895",
                              color: "#FFF",
                              // filteredRequestItem.status !== "1"
                              //   ? "#FFF"
                              //   : "#863895",
                              borderRadius: "6px",
                              // "&:hover": {
                              //   backgroundColor:
                              //     filteredRequestItem.status === "1"
                              //       ? "rgba(134, 56, 149, 0.21)"
                              //       : "#863895",
                              //   color: "#FFF",
                              // },
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                              // "&[disabled]": {
                              //   backgroundColor:
                              //     filteredRequestItem.status === "1"
                              //       ? "rgba(134, 56, 149, 0.21)"
                              //       : undefined,
                              //   color:
                              //     filteredRequestItem.status === "1"
                              //       ? ""
                              //       : undefined,
                              //   cursor: "not-allowed",
                              // },
                            }}
                            // disabled={filteredRequestItem.status === "1"}
                            onClick={() =>
                              handleClick(
                                "declineButton",
                                filteredRequestItem.event_details,
                                filteredRequestItem.user_details,
                                handleDecline
                              )
                            }
                          >
                            Decline Request
                          </Button>

                          <Button
                            id="acceptButton"
                            variant="outlined"
                            sx={{
                              width: "80%",
                              border:
                                filteredRequestItem.status === "1"
                                  ? "0.968px solid #389563 !important"
                                  : "0.968px solid #863895 !important",

                              padding: "11px 0px",
                              backgroundColor:
                                filteredRequestItem.status === "1"
                                  ? "#389563 !important"
                                  : "#863895 !important",
                              color: "#FFF",
                              borderRadius: "6px",
                              // "&:hover": {
                              //   backgroundColor:
                              //     filteredRequestItem.status === "1"
                              //       ? "#389563"
                              //       : "#863895",
                              // },

                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                              // Make the disabled style more specific
                              // "&[disabled]": {
                              //   backgroundColor:
                              //     filteredRequestItem.status === "1"
                              //       ? "#389563"
                              //       : undefined,
                              //   color:
                              //     filteredRequestItem.status === "1"
                              //       ? "#FFF"
                              //       : undefined,
                              //   cursor: "not-allowed",
                              // },
                            }}
                            // disabled={filteredRequestItem.status === "1"}
                            onClick={() =>
                              handleClick(
                                "acceptButton",
                                filteredRequestItem.event_details,
                                filteredRequestItem.user_details
                              )
                            }
                          >
                            {filteredRequestItem.status === "1"
                              ? "Request Accepted"
                              : "Accept Request"}
                          </Button>
                        </Stack>
                      </List>
                    </CardContent>
                  ))}
                </Card>
              </Grid>
            )}
            {/* {openPopup && (
              <div
                // onClick={handleClosePopup}
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0,0,0,0.5)",
                  zIndex: 1000,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    // width: "300px",
                    width: "80%",
                    textAlign: "center",
                    padding: "16px",
                    cursor: "auto",
                    position: "relative",
                  }}
                >
                  <IconButton
                    onClick={handleClosePopup}
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      color: "#863895",
                      border: "2px solid #863895",
                      borderRadius: "50%",
                      padding: "8px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <CardContent>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Lora",
                        fontSize: {
                          xs: "16px",
                          md: "30px",
                        },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "43.939px", // 150%
                      }}
                    >
                      {profileImageData && profileImageData.name}
                    </Typography>

                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Lora",
                        fontSize: {
                          xs: "14px",
                          md: "24px",
                        },
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "43.939px", // 150%
                      }}
                    >
                      {genderText}
                    </Typography>
                  </CardContent>
                  <div style={{ position: "relative", textAlign: "center" }}>
                    <img
                      src={images[currentImageIndex]}
                      alt={`Image ${currentImageIndex + 1}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "70vh",
                        margin: "auto",
                      }}
                    />
                    <IconButton
                      onClick={handlePrev}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "15%",
                        transform: "translate(-100%, -50%)",
                        color: currentImageIndex === 0 ? "#ccc" : "#863895",
                        borderColor:
                          currentImageIndex === 0 ? "#ccc" : "#863895",
                      }}
                      disabled={currentImageIndex === 0}
                    >
                      <ChevronLeftIcon
                        fontSize="large"
                        sx={{
                          border: "2px solid",
                          borderRadius: "50%",
                          padding: "8px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={handleNext}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15%",
                        transform: "translate(100%, -50%)",
                        color:
                          currentImageIndex === images.length - 1
                            ? "#ccc"
                            : "#863895",
                        borderColor:
                          currentImageIndex === images.length - 1
                            ? "#ccc"
                            : "#863895",
                      }}
                    >
                      <ChevronRightIcon
                        fontSize="large"
                        sx={{
                          border: "2px solid",
                          borderRadius: "50%",
                          padding: "8px",
                          backgroundColor: "#fff",
                        }}
                        disabled={currentImageIndex === images.length - 1}
                      />
                    </IconButton>
                  </div>
                </Card>
              </div>
            )} */}
            {openPopup && (
              <Dialog
                open={openPopup}
                onClose={handleClosePopup}
                fullScreen
                sx={{ "& .MuiDialog-paper": { overflow: "hidden" } }}
              >
                <DialogContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "100vh",
                  }}
                >
                  <IconButton
                    onClick={handleClosePopup}
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      color: "#863895",
                      border: "2px solid #863895",
                      borderRadius: "50%",
                      padding: "8px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <div style={{ position: "relative", textAlign: "center" }}>
                    <img
                      src={images[currentImageIndex]}
                      alt={`Image ${currentImageIndex + 1}`}
                      style={{
                        maxWidth: "100%",
                        maxHeight: "70vh",
                        margin: "auto",
                      }}
                    />
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePrev();
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "15%",
                        transform: "translate(-100%, -50%)",
                        color: currentImageIndex === 0 ? "#ccc" : "#863895",
                        borderColor:
                          currentImageIndex === 0 ? "#ccc" : "#863895",
                      }}
                      disabled={currentImageIndex === 0}
                    >
                      <ChevronLeftIcon
                        fontSize="large"
                        sx={{
                          border: "2px solid",
                          borderRadius: "50%",
                          padding: "8px",
                          backgroundColor: "#fff",
                        }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNext();
                      }}
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15%",
                        transform: "translate(100%, -50%)",
                        color:
                          currentImageIndex === images.length - 1
                            ? "#ccc"
                            : "#863895",
                        borderColor:
                          currentImageIndex === images.length - 1
                            ? "#ccc"
                            : "#863895",
                      }}
                      disabled={currentImageIndex === images.length - 1}
                    >
                      <ChevronRightIcon
                        fontSize="large"
                        sx={{
                          border: "2px solid",
                          borderRadius: "50%",
                          padding: "8px",
                          backgroundColor: "#fff",
                        }}
                        disabled={currentImageIndex === images.length - 1}
                      />
                    </IconButton>
                  </div>
                </DialogContent>
              </Dialog>
            )}
          </Grid>

          <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
            <Footer />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />
        </div>
      </div>
    </>
  );
};

export default MyEvents;
