import React, { useState, useEffect, useCallback } from "react";
import Header2 from "../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { debounce } from "lodash";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { IconButton, InputAdornment } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Footer from "../FooterPages/Footer";
import { useUserAuth } from "../../context/userAuthContext.js";
import TelegramIcon from "@mui/icons-material/Telegram";
import "../../fonts.css";
import { updateOnlineStatus } from "../../Firebase.jsx";
import { usersRef } from "../../Firebase.jsx";
import { getTokenFromLocalStorage } from "../authService";
import { MoonLoader } from "react-spinners";
import { Dialog, DialogContent } from "@mui/material";

import {
  collection,
  query,
  onSnapshot,
  Timestamp,
  addDoc,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { Badge, Chip, FormControl, OutlinedInput } from "@mui/material";
import { db } from "../../Firebase.jsx";
import { DrawerWithNav } from "../../pages/Chat/components/DrawerWithNav.jsx";
import moment from "moment";
import chatImage from "../../assets/chat.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

const ResponsiveTab = styled(Tab)`
  width: 50%;
  font-size: 0.82em;

  @media (min-width: 600px) {
    font-size: 1.2em;
  }

  @media (min-width: 960px) {
    font-size: 0.82em;
  }
`;
export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const Chat = () => {
  const [value, setValue] = React.useState("1");
  const [roomId, setRoomId] = React.useState("");
  const [isEventCompleted, setIsEventCompleted] = React.useState(false);
  const [selectedPerson, setSelectedPerson] = React.useState([]);
  const [owner, setOwner] = React.useState([]);
  const [selectedPersonOnlineStatus, setSelectedPersonOnlineStatus] =
    useState(null);
  const [selectedPersonLastSeen, setSelectedPersonLastSeen] = useState(null);
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  const [blocked, setBlocked] = useState(false);
  const [chat, setChat] = React.useState([]);
  const [blockedBySomeone, setBlockedBySomeone] = useState(false);
  const [chatCount, setChatCount] = useState("0");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const { user } = useUserAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (blockedBySomeone) {
      console.log(`${selectedPerson.data.uid} is blocked by someone.`);
    }
  }, [blockedBySomeone, selectedPerson]);

  const formatLastSeen = (lastSeen) => {
    if (lastSeen instanceof Date) {
      const currentDate = new Date();
      const lastSeenDate = lastSeen;

      const isToday =
        currentDate.toDateString() === lastSeenDate.toDateString();
      const isYesterday = currentDate.getDate() - 1 === lastSeenDate.getDate();

      if (isToday) {
        return `Today at ${lastSeenDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      } else if (isYesterday) {
        return `Yesterday at ${lastSeenDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      } else {
        return `${lastSeenDate.toDateString()} at ${lastSeenDate.toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" }
        )}`;
      }
    } else {
      return "N/A";
    }
  };

  window.addEventListener("beforeunload", async () => {
    if (user) {
      try {
        await updateOnlineStatus(user.uid, false);
      } catch (error) {
        console.error("Error updating current user online status:", error);
      }
    }
  });

  document.addEventListener("visibilitychange", async () => {
    if (user && document.visibilityState === "hidden") {
      try {
        await updateOnlineStatus(user.uid, false);
      } catch (error) {
        console.error("Error updating current user online status:", error);
      }
    }
  });

  useEffect(() => {
    let interval;
    let fetchedLastSeen = false;

    const fetchLastSeen = async () => {
      if (
        !fetchedLastSeen &&
        selectedPerson &&
        selectedPerson.data &&
        selectedPerson.data.uid
      ) {
        const selectedPersonId = selectedPerson.data.uid;
        const selectedPersonDocRef = doc(usersRef, `${selectedPersonId}`);
        const docSnap = await getDoc(selectedPersonDocRef);

        if (docSnap.exists()) {
          const isOnline = docSnap.data()?.online || false;
          setSelectedPersonOnlineStatus(isOnline);
          if (isOnline) {
            console.log("Selected person is online.");
            clearInterval(interval);
          } else {
            const lastSeenTimestamp = docSnap.data()?.lastSeen;

            if (lastSeenTimestamp !== null && lastSeenTimestamp !== undefined) {
              const lastSeenDate = lastSeenTimestamp?.toDate();
              console.log(
                "Last seen timestamp for selected person:",
                lastSeenDate
              );

              setSelectedPersonLastSeen(lastSeenDate);
            } else {
              console.log("Last seen timestamp is null or undefined");
            }
          }

          fetchedLastSeen = true;
          clearInterval(interval);
        }
      }
    };

    fetchLastSeen(); // Fetch last seen when selected person changes

    // Clear interval to prevent memory leaks
    return () => clearInterval(interval);
  }, [selectedPerson]);

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      setChat([...chat, { sender: "user", text: message }]);
      setMessage("");
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const deleteChat = async () => {
    try {
      const messagesRef = collection(db, "chats", roomId, "messages");
      const querySnapshot = await getDocs(messagesRef);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      const chatRoomRef = doc(db, "chats", roomId);
      await deleteDoc(chatRoomRef);

      // Clear the chat state
      setMessages([]);
      setRoomId("");
      setSelectedPerson([]);
      setOwner([]);
    } catch (error) {
      console.error("Error deleting chat:", error);
    }
  };

  const scrollToBottom = () => {
    var objDiv = document.getElementById("boxData");
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  };

  const getMessages = useCallback(
    (roomId) => {
      return onSnapshot(
        query(
          collection(db, "chats", roomId, "messages"),
          orderBy("time", "asc")
        ),
        (querySnapshot) => {
          const messages = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(messages);
        }
      );
    },
    [db]
  );

  const findBlockValue = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let nestedObject = obj[key];

        // Check if nestedObject has 'blocked' and 'isBlockedByMe' properties
        if (
          nestedObject.hasOwnProperty("blocked") &&
          nestedObject.hasOwnProperty("isBlockedByMe")
        ) {
          let blockedValue = nestedObject.blocked;
          let isBlockedByMeValue = nestedObject.isBlockedByMe;

          return blockedValue || isBlockedByMeValue;
        }
      }
    }
  };

  const handlePersonChat = async (person, eventCompletedOrNOt) => {
    if (eventCompletedOrNOt) {
      setIsEventCompleted(eventCompletedOrNOt);
    }
    const myId = user.uid;

    const otherPersonId = person.data.uid;

    if (myId && otherPersonId) {
      const roomId = [myId, otherPersonId].sort().join("");
      const chatsRef = doc(db, "chats", roomId);

      setRoomId(roomId);
      const isBlocked = findBlockValue(person.data.roomDetail);
      // debugger
      setSelectedPerson({ ...person, blocked: isBlocked });
      // setOwner(myId);
      getMessages(roomId);

      const docSnap = await getDoc(chatsRef);
      const isBlockedByMe = docSnap?.data()?.[myId]?.blocked;
      const isBlockedForMe = docSnap?.data()?.[otherPersonId]?.blocked;

      setBlocked(isBlockedForMe);
      setOwner(isBlockedByMe);
      // console.log(`My blocked status from Firestore: ${isBlockedByMe}`);

      if (docSnap.exists()) {
        updateDoc(chatsRef, {
          [myId]: {
            ...docSnap.data()[myId],
            unread_count: 0,
          },
        });
      } else {
        await setDoc(chatsRef, {
          [myId]: {
            ...(docSnap.data() || {}),
            unread_count: 0,
          },
        });
      }

      setTimeout(() => {
        scrollToBottom();
      }, 500);

      if (!docSnap.exists()) {
        await setDoc(chatsRef, {
          [myId]: {
            unread_count: 0,
            blocked: false,
          },
        });
      } else {
        const isBlocked = docSnap.data()?.[otherPersonId]?.blocked || false;

        console.log(`Blocked status from Firestore: ${isBlocked}`);

        setSelectedPerson((prevPerson) => ({
          ...prevPerson,
          blocked: isBlocked,
        }));
      }
    }
  };

  const fetchAllAcceptedRequestData = async () => {
    try {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "allAcceptConsiderRequest";

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response:", response.data);
      return response.data;
      // setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const blockApi = async (user) => {
    const token = getTokenFromLocalStorage();
    const userFriendsData = await fetchAllAcceptedRequestData();
    const userData = userFriendsData.data.filter(
      (obj) => obj.email === user.email
    );
    // const eventIds = userData.map((obj) => obj.event_id);
    try {
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "blockUnblockUser";

      const params = {
        user_id: userData[0].id,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response:", response.data);
    } catch (apiError) {
      console.error("Error in API call:", apiError);
    }
  };
  const unBlockApi = async (user) => {
    const token = getTokenFromLocalStorage();
    const userFriendsData = await fetchAllAcceptedRequestData();
    const userData = userFriendsData.data.filter(
      (obj) => obj.email === user.email
    );
    // const eventIds = userData.map((obj) => obj.event_id);
    try {
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "unblockUser";

      const params = {
        user_id: userData[0].id,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response:", response.data);
    } catch (apiError) {
      console.error("Error in API call:", apiError);
    }
  };

  const handleBlockToggle = async () => {
    try {
      const myId = user.uid;
      const otherPersonId = selectedPerson.data.uid;
      const roomId = [myId, otherPersonId].sort().join("");
      const chatsRef = doc(db, "chats", roomId);

      const unsubscribe = onSnapshot(chatsRef, async (docSnap) => {
        try {
          console.log("Snapshot received", docSnap);

          unsubscribe();

          const isBlockedByMe = docSnap?.data()?.[myId]?.isBlockedByMe || false;
          const isBlockedForMe =
            docSnap?.data()?.[otherPersonId]?.blocked || false;

          const updatedData = {
            [otherPersonId]: {
              blocked: !isBlockedByMe,
              isBlockedByMe: false,
            },
            [myId]: {
              blocked: false,
              isBlockedByMe: !isBlockedForMe,
            },
          };

          await updateDoc(chatsRef, updatedData);
          await new Promise((resolve) => setTimeout(resolve, 500));

          setSelectedPerson((prevPerson) => ({
            ...prevPerson,
            blocked: !isBlockedForMe,
          }));
          setBlocked(!isBlockedForMe);

          // Integrate API call or any additional logic here
          if (!selectedPerson.blocked) {
            await blockApi(selectedPerson.data);
          } else {
            await unBlockApi(selectedPerson.data);
          }
        } catch (error) {
          console.error("Error updating block status:", error);
        }
      });
    } catch (error) {
      console.error("Error toggling block:", error);
    }
  };

  const updateUnreadCount = useCallback(async () => {
    if (!roomId || !selectedPerson?.data?.uid) return;

    const chats_ref = doc(db, "chats", roomId);
    const partnerId = selectedPerson.data.uid;
    const docSnap = await getDoc(chats_ref);

    if (docSnap.exists()) {
      let roomDetail = docSnap.data();
      let partnerUnreadCount = roomDetail?.[partnerId]?.unread_count || 0;
      await updateDoc(chats_ref, {
        [partnerId]: {
          unread_count: partnerUnreadCount + 1,
        },
      });
    } else {
      await setDoc(chats_ref, {
        [partnerId]: {
          unread_count: 1,
        },
      });
    }
  }, [db, roomId, selectedPerson]);

  const debouncedUpdateUnreadCount = useCallback(
    debounce(updateUnreadCount, 300),
    [updateUnreadCount]
  );
  useEffect(() => {
    if (selectedPerson) {
      debouncedUpdateUnreadCount();
    }
  }, [selectedPerson, debouncedUpdateUnreadCount]);

  useEffect(() => {
    if (roomId) {
      const unsubscribe = getMessages(roomId);

      return () => {
        unsubscribe();
      };
    }
  }, [roomId, getMessages]);
  useEffect(() => {
    const fetchLastSeen = async () => {
      if (selectedPerson?.data?.uid) {
        const selectedPersonId = selectedPerson.data.uid;
        const selectedPersonDocRef = doc(usersRef, `${selectedPersonId}`);
        const docSnap = await getDoc(selectedPersonDocRef);

        if (docSnap.exists()) {
          const isOnline = docSnap.data()?.online || false;
          setSelectedPersonOnlineStatus(isOnline);
          if (!isOnline) {
            const lastSeenTimestamp = docSnap.data()?.lastSeen;
            if (lastSeenTimestamp) {
              const lastSeenDate = lastSeenTimestamp.toDate();
              setSelectedPersonLastSeen(lastSeenDate);
            }
          }
        }
      }
    };

    fetchLastSeen(); // Fetch last seen when selected person changes
  }, [selectedPerson, usersRef]);

  const sendMsg = async (e) => {
    e.preventDefault();
    const msg = message.trim();

    if (msg) {
      const msgObj = {
        time: Timestamp.now(),
        message: msg,
        sender: user.uid,
        receiver: selectedPerson.data.uid,
      };

      setMessages((oldArray) => [...oldArray, msgObj]);
      updateUnreadCount();
      setTimeout(() => {
        scrollToBottom();
      }, 100);

      try {
        await addDoc(collection(db, "chats", roomId, "messages"), msgObj);
      } catch (error) {
        console.error(error);
      }

      setMessage("");
    } else {
      setMessage("");
    }
  };

  function capitalizeFirstLetter(str) {
    if (typeof str === "string" && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return str;
  }
  const avatarContent =
    selectedPerson?.data?.profileImage &&
    selectedPerson.data.profileImage.length > 0 ? (
      <Avatar
        src={selectedPerson.data.profileImage[0]}
        aria-label="profile-image"
      />
    ) : (
      <Avatar aria-label="profile-icon">
        {selectedPerson?.data?.displayName
          ? capitalizeFirstLetter(selectedPerson.data.displayName.charAt(0))
          : ""}
      </Avatar>
    );
  const handleAvatarClick = () => {
    console.log("Avatar clicked!");
    const profileImages = selectedPerson?.data?.profileImages;
    setCurrentImageIndex(0);
    setOpenPopup(true);
    if (profileImages && profileImages.length > 0) {
      const images = profileImages.map((image) => image);
      // console.log("Profile Images Data:", images);
      setImages(images);
    }
  };
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-lato gap-[60px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />

        <Grid
          container
          spacing={1}
          sx={{ width: "100%", margin: "auto", height: "auto" }}
        >
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {headerLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 9999,
                  }}
                >
                  <MoonLoader color="#863895" size={80} />
                </div>
              )}
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "20px",
                  margin: "auto",
                  backgroundColor: "rgba(160, 195, 255, 0.06)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <CardContent>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <ResponsiveTab
                          // sx={{ width: "50%" }}
                          label="New Events"
                          value="1"
                        />
                        <ResponsiveTab label="Completed Events" value="2" />
                      </TabList>
                    </Box>
                    <Grid
                      container
                      spacing={2}
                      style={{ maxHeight: "calc(100vh - 176px)" }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TabPanel
                          value="1"
                          style={{ padding: 0, cursor: "pointer" }}
                        >
                          {value === "1" && (
                            <DrawerWithNav
                              handlePersonChat={handlePersonChat}
                              setChatCount={setChatCount}
                            />
                          )}
                        </TabPanel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TabPanel
                          value="2"
                          style={{ padding: 0, cursor: "pointer" }}
                        >
                          <DrawerWithNav
                            handlePersonChat={handlePersonChat}
                            completedEvent={true}
                            setChatCount={setChatCount}
                          />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </TabContext>
                </CardContent>
              </Card>
            </Grid>
            {chatCount === 0 ? (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                  marginTop: "8px",
                  maxHeight: "calc(100vh - 100px)",
                }}
              >
                <Card
                  sx={{
                    width: "100%",
                    maxWidth: 800,
                    textAlign: "center",
                    borderRadius: "16px",
                    mx: "auto",
                    p: 5,
                    boxSizing: "border-box",
                    backgroundColor: "rgba(160, 195, 255, 0.06)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={chatImage}
                      alt="Mobile"
                      style={{
                        width: "100%",
                        maxWidth: "350px",
                        height: "auto",
                      }}
                    />
                  </div>
                  <CardContent>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: {
                          xs: "1.0rem",
                          sm: "1.2rem",
                        },
                      }}
                    >
                      To activate the chat feature, express interest in posted
                      events or create your own event and connect with potential
                      invitees.
                    </Typography>
                  </CardContent>

                  <Stack
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: { xs: 2, sm: 0 },
                    }}
                  >
                    <Link to="/create_event" style={{ textDecoration: "none" }}>
                      <Button
                        sx={{
                          marginBottom: { xs: 2, sm: 0 },
                          backgroundColor: "#863895",
                          "&:hover": {
                            backgroundColor: "#863895",
                          },
                        }}
                        variant="contained"
                      >
                        Create Event
                      </Button>
                    </Link>
                    <Link to="/home" style={{ textDecoration: "none" }}>
                      <Button
                        sx={{
                          marginBottom: { xs: 2, sm: 0 },
                          backgroundColor: "#863895",
                          "&:hover": {
                            backgroundColor: "#863895",
                          },
                        }}
                        variant="contained"
                      >
                        Explore Events
                      </Button>
                    </Link>
                  </Stack>
                </Card>
              </Stack>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "auto",
                }}
              >
                {selectedPerson?.data ? (
                  <Card
                    sx={{
                      width: "100%",
                      borderRadius: "20px",
                      margin: "2px",
                      height: "auto",
                      overflow: "auto",
                      backgroundColor: "rgba(160, 195, 255, 0.06)",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <CardHeader
                      avatar={
                        <div onClick={handleAvatarClick}>{avatarContent}</div>
                      }
                      title={
                        <div>
                          <div
                            style={{
                              color: "#000",
                              fontFamily: "Lora",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "bold",
                              lineHeight: "normal",
                            }}
                          >
                            {capitalizeFirstLetter(
                              selectedPerson?.data?.displayName
                            )}

                            {!selectedPerson.blocked && !owner && (
                              <>
                                {selectedPersonOnlineStatus && (
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "green",
                                    }}
                                  >
                                    Online
                                  </p>
                                )}

                                {!selectedPersonOnlineStatus && (
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: selectedPersonOnlineStatus
                                        ? "green"
                                        : "grey",
                                      display:
                                        selectedPersonOnlineStatus ||
                                        selectedPersonLastSeen
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    {selectedPersonOnlineStatus
                                      ? "Online"
                                      : selectedPersonLastSeen &&
                                        formatLastSeen(selectedPersonLastSeen)}
                                  </p>
                                )}
                              </>
                            )}
                          </div>

                          {owner && (
                            <div
                              style={{
                                fontWeight: "bold",
                                fontStyle: "normal",
                                fontWeight: 700,
                                color: "red",
                                "@media only screen and (max-width: 768px)": {
                                  fontSize: "24px",
                                },
                                "@media only screen and (max-width: 480px)": {
                                  fontSize: "12px",
                                },
                              }}
                            >
                              <p>You're Blocked!</p>
                            </div>
                          )}

                          {blocked && (
                            <div
                              style={{
                                fontWeight: "bold",
                                fontStyle: "normal",
                                color: "red",
                                "@media only screen and (max-width: 768px)": {
                                  fontSize: "24px",
                                },
                                "@media only screen and (max-width: 480px)": {
                                  fontSize: "12px",
                                },
                              }}
                            >
                              <p>You have blocked this user.</p>
                            </div>
                          )}
                        </div>
                      }
                      action={
                        <>
                          <IconButton
                            aria-label="settings"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                deleteChat();
                                handleClose();
                              }}
                              sx={{
                                color: "#4F4F4F",
                                fontFamily: "Lora",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                              }}
                            >
                              Delete Chat
                            </MenuItem>
                            <Divider />

                            {!owner && (
                              <MenuItem
                                onClick={() => {
                                  handleClose();
                                  handleBlockToggle();
                                }}
                                sx={{
                                  color: selectedPerson.blocked
                                    ? "#28a745"
                                    : "#FF2E2E",
                                  fontFamily: "Lora",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                {selectedPerson.blocked ? "Unblock" : "Block"}
                              </MenuItem>
                            )}
                          </Menu>
                        </>
                      }
                    />

                    <CardContent>
                      <Box
                        sx={(theme) => ({
                          pt: 1,
                          [theme.breakpoints.down("lg")]: {
                            px: 2,
                          },
                        })}
                      >
                        <Divider />
                      </Box>

                      <>
                        <Box
                          sx={{
                            maxHeight: "calc(100vh - 300px)",
                            pt: 1.5,
                            display: "flex",
                            flexDirection: "column",
                            overflow: "auto",
                          }}
                          id="boxData"
                        >
                          <Box
                            sx={{
                              flex: 1,
                              px: 3,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-end",
                              mb: 2,
                              maxHeight: "100%",
                            }}
                            className="msgWrapper"
                          >
                            {messages?.map((msg, i) => (
                              <Box
                                key={i}
                                className={
                                  msg.sender === user.uid
                                    ? "myMessage"
                                    : "notMyMessage"
                                }
                                sx={{
                                  alignSelf:
                                    msg.sender === user.uid
                                      ? "end"
                                      : "flex-start",
                                  my: 0.3,
                                  maxWidth: "80%",
                                }}
                              >
                                <Chip
                                  color={
                                    msg.sender === user.uid
                                      ? "primary"
                                      : "secondary"
                                  }
                                  label={
                                    <Box display="flex" flexDirection="column">
                                      <span style={{ fontWeight: "500" }}>
                                        {msg.message}
                                      </span>
                                      <Typography
                                        variant="body2"
                                        fontWeight={"bold"}
                                      >
                                        {moment(
                                          msg.time.toDate().toString()
                                        ).format("D-MMM-YY, h:mm a")}
                                      </Typography>
                                    </Box>
                                  }
                                  sx={{
                                    fontSize: "large",
                                    height: "auto",
                                    p: 1,
                                    borderTopRightRadius:
                                      msg.sender === user.uid ? "0px" : "16px",
                                    borderTopLeftRadius:
                                      msg.sender !== user.uid ? "0px" : "16px",
                                    "& span": {
                                      whiteSpace: "normal",
                                    },
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>

                        <form
                          onSubmit={sendMsg}
                          style={{ position: "sticky", bottom: 0, left: 0 }}
                        >
                          <Box
                            display="flex"
                            sx={{
                              px: 1,
                              alignItems: "center",
                              mb: 0.5,
                              width: "100%",
                            }}
                          >
                            {!isEventCompleted &&
                              !selectedPerson.blocked &&
                              !owner && (
                                <Box sx={{ mr: 1, flex: 1 }}>
                                  <FormControl
                                    sx={{ m: 1, width: "100%" }}
                                    variant="outlined"
                                    disabled={
                                      selectedPerson.blocked ||
                                      selectedPerson.isBlockedByMe
                                    }
                                  >
                                    <OutlinedInput
                                      value={message}
                                      sx={{
                                        width: "100%",
                                        "& fieldset": {
                                          borderColor: "#863895 !important",
                                        },
                                        "& [type='text']:focus": {
                                          boxShadow: "none",
                                        },
                                      }}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton type="submit" edge="end">
                                            <TelegramIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      placeholder="Message"
                                    />
                                  </FormControl>
                                </Box>
                              )}
                          </Box>
                        </form>
                      </>
                    </CardContent>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 800,
                      textAlign: "center",
                      borderRadius: "16px",
                      mx: "auto", // Center the Card horizontally
                      p: 5, // Add padding to the Card content
                      boxSizing: "border-box", // Ensure padding doesn't affect the width
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={chatImage}
                        alt="Mobile"
                        style={{
                          width: "100%",
                          maxWidth: "350px",
                          height: "auto",
                        }}
                      />
                    </div>
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: {
                            xs: "1.0rem", // Adjust the font size for extra-small screens
                            sm: "1.2rem", // Adjust the font size for small screens and above
                          },
                        }}
                      >
                        Ready to chat? Once someone is interested in your
                        activity and you consider their request (or vice versa),
                        you may kick off a conversation!
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {openPopup && (
          <Dialog
            open={openPopup}
            onClose={handleClosePopup}
            fullScreen
            sx={{ "& .MuiDialog-paper": { overflow: "hidden" } }}
          >
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
              }}
            >
              <IconButton
                onClick={handleClosePopup}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  color: "#863895",
                  border: "2px solid #863895",
                  borderRadius: "50%",
                  padding: "8px",
                  backgroundColor: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>

              <div style={{ position: "relative", textAlign: "center" }}>
                <img
                  src={images[currentImageIndex]}
                  alt={`Image ${currentImageIndex + 1}`}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh",
                    margin: "auto",
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrev();
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "15%",
                    transform: "translate(-100%, -50%)",
                    color: currentImageIndex === 0 ? "#ccc" : "#863895",
                    borderColor: currentImageIndex === 0 ? "#ccc" : "#863895",
                  }}
                  disabled={currentImageIndex === 0}
                >
                  <ChevronLeftIcon
                    fontSize="large"
                    sx={{
                      border: "2px solid",
                      borderRadius: "50%",
                      padding: "8px",
                      backgroundColor: "#fff",
                    }}
                  />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNext();
                  }}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "15%",
                    transform: "translate(100%, -50%)",
                    color:
                      currentImageIndex === images.length - 1
                        ? "#ccc"
                        : "#863895",
                    borderColor:
                      currentImageIndex === images.length - 1
                        ? "#ccc"
                        : "#863895",
                  }}
                  disabled={currentImageIndex === images.length - 1}
                >
                  <ChevronRightIcon
                    fontSize="large"
                    sx={{
                      border: "2px solid",
                      borderRadius: "50%",
                      padding: "8px",
                      backgroundColor: "#fff",
                    }}
                    disabled={currentImageIndex === images.length - 1}
                  />
                </IconButton>
              </div>
            </DialogContent>
          </Dialog>
        )}
        <div className="flex flex-col md:gap-10 gap-[100px] items-center w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Chat;
