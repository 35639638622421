import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Button, ListItem, ListItemIcon } from "@mui/material";
import "../../../fonts.css";
import { ListItemSecondaryAction, Badge, useTheme } from "@mui/material";

import { StyledBadge } from "../../../Components/pages/Chat.js";
import { useUserAuth } from "../../../context/userAuthContext";
import { getTokenFromLocalStorage } from "../../../Components/authService.js";
// import firebase from '../../../Firebase.jsx';
import {
  collection,
  documentId,
  getCountFromServer,
  limit,
  onSnapshot,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { db } from "../../../Firebase";
import { useDrawer } from "../../../context/drawerContext";

// Importing Firestore module

// Now you can use getCountFromServer

export function DrawerWithNav(props) {
  /**
   * @states
   */
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [myObject, setMyObject] = useState([]);
  const [allRoomIds, setAllRoomIds] = useState([]);
  const [userLimit, setUserLimit] = useState(15);
  const [totalUsers, setTotalUsers] = useState(0);

  /**
   * @variables
   */
  // const { open, setOpen } = useDrawer();
  const { user, logOut } = useUserAuth(); // Assuming useUserAuth provides user information

  const [data, setData] = useState(null);
  const [roomMessages, setRoomMessages] = useState({});

  const theme = useTheme();
  const badgeStyle = {
    "& .MuiBadge-badge": {
      // color: 'white',
      backgroundColor: "#863895",
    },
  };

  useEffect(() => {
    if (user && user.uid) {
      const getRoomIdsForUser = async (userId) => {
        try {
          const chatsQuery = query(collection(db, "chats"));
          const querySnapshot = await getDocs(chatsQuery);

          const roomIds = [];

          querySnapshot.forEach((document) => {
            const roomId = document.id;
            // console.log("Room Id:", roomId);
            roomIds.push(roomId);
          });

          const chatsSnapshot = await getDocs(chatsQuery);

          return roomIds;
        } catch (error) {
          console.error("Error fetching room IDs:", error.message);
          throw error;
        }
      };

      const getLatestMessage = async (roomId) => {
        const messagesQuery = query(
          collection(db, "chats", roomId, "messages"),
          orderBy("time", "desc"),
          limit(1)
        );
        const messagesSnapshot = await getDocs(messagesQuery);

        if (!messagesSnapshot.empty) {
          const latestMessage = messagesSnapshot.docs[0].data();
          return latestMessage;
        }

        return null;
      };

      const fetchRoomMessages = async () => {
        try {
          const roomIds = await getRoomIdsForUser(user.uid);

          const messages = {};

          for (const roomId of roomIds) {
            const latestMessage = await getLatestMessage(roomId);
            messages[roomId] = latestMessage;
          }

          setRoomMessages(messages);
        } catch (error) {
          console.error("Error fetching room messages:", error.message);
        }
      };

      const unsubscribe = onSnapshot(query(collection(db, "chats")), () => {
        fetchRoomMessages();
      });

      return () => {
        unsubscribe();
      };
    }
  }, [user, setRoomMessages]);

  useEffect(() => {
    (async () => {
      const coll = collection(db, "users");
      const snapshot = await getCountFromServer(coll);
      const count = snapshot.data().count;
      setTotalUsers(count);
    })();
  }, []);

  React.useEffect(() => {
    if (user?.uid) {
      const q = query(
        collection(db, "users"),
        where(documentId(), "==", user.uid)
      );
      onSnapshot(q, (qSnapshot) => {
        setMyObject(
          qSnapshot.docs.map((doc) => {
            return doc.data();
          })[0]
        );
      });
    }
  }, [user]);

  React.useEffect(() => {
    setUsers();
  }, [user, userLimit]);

  React.useEffect(() => {
    if (allRoomIds?.length > 0) {
      allRoomIds.forEach((roomid) => {
        const q = query(
          collection(db, "chats"),
          where(documentId(), "==", roomid)
        );
        onSnapshot(q, (qSnapshot) => {
          qSnapshot.docs.forEach((doc) => {
            let roomDetail = doc.data();
            let finalData = allUsers.map((item) => {
              if (item.data && item.data.roomIdWithMe === roomid) {
                item.data.roomDetail = roomDetail;
              }
              return item;
            });
            const arrUniq = [
              ...new Map(finalData.map((v) => [v.data.uid, v])).values(),
            ];
            setAllUsers(arrUniq);
          });
        });
      });
    }
  }, [allRoomIds]);

  const fetchData = async () => {
    try {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "allAcceptConsiderRequest";

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setUsers = async () => {
    if (user && user.uid) {
      const userFriendsData = await fetchData();

      if (
        userFriendsData &&
        userFriendsData.data &&
        userFriendsData.data.length > 0
      ) {
        const status = props.completedEvent ? "Completed" : "Active";
        const activeUserFriends = userFriendsData.data.filter(
          (obj) => obj.is_active === status
        );
        const userFriendsEmails = activeUserFriends.map((obj) => obj.email);
        if (props.setChatCount) {
          props.setChatCount(new Set(userFriendsEmails).size);
        }
        if (userFriendsEmails.length > 0) {
          const q = query(
            collection(db, "users"),
            where("email", "in", userFriendsEmails),
            limit(userLimit)
          );

          onSnapshot(q, (querySnapshot) => {
            setAllUsers((prevUsers) => [
              ...prevUsers,
              ...querySnapshot.docs.map((document) => {
                const data = document.data();
                const roomid = [user.uid, data.uid].sort().join("");
                setAllRoomIds((oldArray) => [...oldArray, roomid]);

                const isCurrentUser = data.uid === user.uid;

                if (!isCurrentUser) {
                  data.roomIdWithMe = roomid;

                  if (data.displayName) {
                    data.displayName = data.displayName;
                  } else {
                    data.displayName = data.fullName || "Unknown Name";
                  }

                  if (
                    data.profileImages &&
                    Array.isArray(data.profileImages) &&
                    data.profileImages.length > 0
                  ) {
                    data.profileImage = [data.profileImages[0]];
                  } else {
                    data.profileImage = ["default-image-url"];
                  }

                  // console.log("Profile images:", data.profileImages);

                  return { data };
                }

                return null;
              }),
            ]);
          });
        } else {
          console.log("No valid email addresses to query");
        }
      } else {
        console.log("No friends to fetch");
      }
    }
  };

  function capitalizeFirstLetter(str) {
    if (typeof str === "string" && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  }

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    const date = new Date(timestamp.seconds * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;
  };

  return (
    <>
      <List
        sx={{
          width: "100%",
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        <>
          {allUsers?.map((item, index) => {
            const unreadCount = item?.data?.roomDetail
              ? typeof item?.data?.roomDetail[user?.uid]?.unread_count ===
                "number"
                ? item?.data?.roomDetail[user?.uid]?.unread_count
                : 0
              : 0;

            const profileImage = item?.data?.profileImage[0];
            const roomId = item?.data?.roomIdWithMe;
            const latestMessage = roomMessages[roomId];
            return (
              <ListItem
                alignItems="flex-start"
                sx={{
                  paddingLeft: 0,
                  paddingBottom: 0,

                  paddingRight: 0,
                  [theme.breakpoints.down("sm")]: {
                    flexDirection: "column",
                  },
                }}
                onClick={() => {
                  props.handlePersonChat(item);
                  // setOpen(false);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: "auto",
                    justifyContent: "center",
                    marginRight: 1,
                  }}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                  >
                    {profileImage ? (
                      <Avatar alt="" src={profileImage} />
                    ) : (
                      <Avatar>
                        {item?.data?.displayName
                          ? capitalizeFirstLetter(
                              item.data.displayName.charAt(0)
                            )
                          : ""}
                      </Avatar>
                    )}
                  </StyledBadge>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: "18px",
                        color: "#000",
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "normal",
                      }}
                    >
                      {item?.data?.displayName || "Unknown Name"}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontSize: "16px",
                          color: "#000",
                          fontFamily: "Lora",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "normal",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {latestMessage
                          ? latestMessage.message.length > 20
                            ? latestMessage.message.substring(0, 20) + "..."
                            : latestMessage.message
                          : ""}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <ListItemSecondaryAction>
                  <Box textAlign="center">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: "12px",
                        color: "#000",
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      {latestMessage ? formatTimestamp(latestMessage.time) : ""}
                    </Typography>
                    <Badge
                      badgeContent={unreadCount}
                      label={unreadCount}
                      color={"primary"}
                      sx={badgeStyle}
                    ></Badge>
                  </Box>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
          <ListItem disablePadding>
            {allUsers.length >= userLimit && (
              <Box mb={2} mt={0.5} width={"100%"}>
                <Button
                  sx={{ width: "100%" }}
                  onClick={() => setUserLimit((prevLimit) => prevLimit + 5)}
                >
                  Load more
                </Button>
              </Box>
            )}
          </ListItem>
        </>
      </List>
    </>
  );
}
