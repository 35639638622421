import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/userAuthContext";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import "react-toastify/dist/ReactToastify.css";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AccessibilityNewOutlinedIcon from "@mui/icons-material/AccessibilityNewOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast, ToastContainer } from "react-toastify";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import Google from "../assets/Google__G__Logo.png";
import facebook from "../assets/facebook.png";
import Divider from "@mui/material/Divider";
import { Img } from "./Img";
import { Text } from "./Text";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import OtpPopup from "./OtpPopup";
import { saveTokenToLocalStorage, AUTH_TOKEN_KEY } from "./authService";
import genderImg from "../assets/profileGender.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getTokenFromLocalStorage } from "./authService";
import HeaderSignup from "./Header/HeaderSignup";

const SignUpPage = () => {
  const [open, setOpen] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [showOtpBox, setShowOtpBox] = React.useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [user1, setUser1] = React.useState([]);
  const [profile, setProfile] = React.useState([]);

  const [signupLoading, setSignupLoading] = React.useState(false);
  const { error, SignUp, currentuser } = useUserAuth();
  const [err, setError] = useState("");
  const [backError, setBackError] = useState("");
  const [user, setUser] = useState({
    FullName: "",
    email: "",
  });

  const [signUpFormData, setSignUpFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
    age: "",
    gender: "",
    address: "",
    latitude: "",
    longitude: "",
    password: "",
    retypePassword: "",
  });

  const navigate = useNavigate();
  const [isRememberMeChecked, setRememberMeChecked] = useState(false);

  const handleCloseDialog = async () => {
    setDialogOpen(false);
    setApiSuccess(false);
    navigate("/home");
  };

  const handleAutoClose = () => {
    setTimeout(() => {
      handleCloseDialog();
    }, 5000);
  };

  useEffect(() => {
    if (apiSuccess) {
      setDialogOpen(true);
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (dialogOpen) {
      handleAutoClose();
    }
  }, [dialogOpen]);

  const handleRememberMeChange = (event) => {
    setRememberMeChecked(event.target.checked);
  };

  const handleClickShowRetypePassword = () => {
    setShowRetypePassword(!showRetypePassword);
  };

  const handleMouseDownRetypePassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (user1) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user1.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user1.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          console.log("User Profile:", res.data);
          const userEmail = res.data.email;
          console.log("User Email:", userEmail);

          axios
            .post("socialMediaLogin", { email: userEmail, login_type: 2 })
            .then((response) => {
              // console.log("Social Media Login API Response:", response.data);

              if (response.status === 200 && response.data.success) {
                const token = response.data.data.token;
                // console.log(token);
                saveTokenToLocalStorage(token);

                navigate("/home");
              } else {
                toast.error(response.data.message);
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  }, [user1, setProfile, navigate, toast]);

  const handleInputChange = (event, field) => {
    setSignUpFormData({
      ...signUpFormData,
      [field]: event.target.value,
    });

    if (field === "password" || field === "retypePassword") {
      setPasswordError("");
      const minLength = 8; // Minimum password length, adjust as needed

      if (event.target.value.length < minLength) {
        setPasswordError(
          `Password must be at least ${minLength} characters long`
        );
      }
    }
    if (field === "email") {
      setEmailError("");
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(event.target.value)) {
        setEmailError("Please enter a valid email address");
      }
    }

    if (field === "phone") {
      setPhoneError("");
      const phonePattern = /^\d+$/;
      if (!phonePattern.test(event.target.value)) {
        setPhoneError("Phone number must contain only numeric digits");
      }
    }

    if (field === "age") {
      setAgeError("");
      const ageValue = parseInt(event.target.value, 10);

      if (isNaN(ageValue) || ageValue < 18 || ageValue > 99) {
        setAgeError("Age must be between 18 and 99 years");
      }
    }
  };

  const handleContinueWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser1(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleContinueWithFacebook = () => {
    // window.location.href = "your_facebook_redirect_url";
  };

  const handleSignUpClick = async (e) => {
    setSignupLoading(true);

    e.preventDefault();
    // Check for email validation error
    if (emailError) {
      setIsToastOpen(true);

      toast.error("Please fix the email validation error before proceeding");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3500);
      setSignupLoading(false);
      return;
    }

    // Check for phone number validation error
    if (phoneError) {
      setIsToastOpen(true);
      toast.error("Phone number must be numeric");
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3500);
      setSignupLoading(false);
      return;
    }

    // Check for age validation error
    const ageValue = parseInt(signUpFormData.age, 10);
    if (isNaN(ageValue) || ageValue < 18 || ageValue > 99) {
      setIsToastOpen(true);
      toast.error("Age must be 18 years and above", {});
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3500);
      setSignupLoading(false);
      return;
    }
    if (passwordError) {
      setIsToastOpen(true);
      toast.error(passwordError);
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3500);
      setSignupLoading(false);
      return;
    }

    // Check for empty required fields
    if (
      !signUpFormData.email ||
      !signUpFormData.password ||
      !signUpFormData.retypePassword ||
      !signUpFormData.gender ||
      !signUpFormData.address ||
      !signUpFormData.phone
    ) {
      setIsToastOpen(true);

      toast.error("Please fill in all the required fields", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3500);
      setSignupLoading(false);
      return;
    }

    // Check for password match
    if (signUpFormData.password !== signUpFormData.retypePassword) {
      toast.error("Passwords do not match. Please re-enter your passwords");
      setSignupLoading(false);
      return;
    }

    // If all validations pass, proceed with the API call
    try {
      const response = await axios.post("register", signUpFormData);

      //  console.log(response.data.success);

      if (response.data.status === 200) {
        const token = response.data.data.token;
        const userId = response.data.data.id;

        // saveTokenToLocalStorage(token, userId);

        setSignupLoading(false);

        toast.success(response.data.message || "OTP Sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: true,
          onClose: () => {
            setShowOtpBox(true);
          },
        });
        // debugger
      } else if (response.data.status === 400 && !response.data.success) {
        if (
          response.data.message ===
          "You've used different login method at the time of signed up"
        ) {
          // Handle the specific case where a different sign-up method was used at login
          toast.error(
            "You've used Social-login for Signup,You're getting redirected",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );

          // Call handleContinueWithGoogle function
          handleContinueWithGoogle();
        } else if (
          response.data.message ===
          "Email is already taken try with diffrent email"
        ) {
          // Handle the specific case where the email is already taken

          toast.error(
            "Email is already taken. Please try with a different email",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            }
          );
        }
      } else {
        // Handle other status codes
        const errorMessage =
          response.data.message || "Signup failed. Please try again";

        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } catch (error) {
      // Handle general error
      console.error(error);

      toast.error("An error occurred while processing your request", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      // Ensure that setSignupLoading(false) is called after handling all cases
      setSignupLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenFromLocalStorage();
      const apiUrl = "header";

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (
          !token ||
          (response.data.status === 401 && !response.data.success)
        ) {
          console.error(
            "Token not found in local storage or unauthorized API access"
          );
          console.log("Token:", token);
          console.log("Response:", response);

          if (response.data.status === 401 && !response.data.success) {
            // Navigate to the login page
            navigate("/sign_up");
          }

          return; // Stop further execution if token is not present or unauthorized access
        }

        if (
          response.status === 200 &&
          response.data.success &&
          token &&
          response.data.status === 200
        ) {
          const responseData = response.data.data;

          // Navigate to the home page
          navigate("/home");
        } else {
          console.error("Failed to get data:", response.data.message);
        }
      } catch (error) {
        console.error("Error while making the API request:", error.message);

        // Navigate to the login page in case of an error
        navigate("/sign_up");
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <div
        className="flex flex-col font-lato gap-[35px] mx-auto w-full"
        style={{
          background: "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
        }}
      >
        <HeaderSignup />
        <div
          style={{
            // background:
            //   "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Container
            style={{
              padding: 20,
              width: "100%",
              height: "auto",
              maxWidth: "1000px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Paper
              elevation={3}
              style={{
                padding: 10,
                height: "auto",
              }}
            >
              <Grid container spacing={1} justify="center">
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <img
                    src="images/img_rectangle406.png"
                    alt="LoginImage"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Grid container justifyContent="center" spacing={1}>
                    <div className="bg-header flex md:flex-1 flex-col items-center justify-center rounded-md w-[30%] md:w-full mt-2">
                      <div className="flex flex-col items-center justify-center w-full md:w-full xs:w-full">
                        <Img
                          className="h-[60px] md:h-auto max-w-full mx-auto"
                          src="images/img_group1171274834.svg"
                          alt="group1171274834"
                        />
                        <Text
                          className="mt-[7px] text-[8.87px] text-white-A700 tracking-[2.22px]"
                          size="txtLatoBold887"
                        >
                          ACCOMPANIED
                        </Text>
                        <Text
                          className="text-[4.43px] text-white-A700_8c tracking-[1.11px]"
                          size="txtInterRegular443"
                        >
                          LIVELY. SAVVY. PEOPLE
                        </Text>
                      </div>
                    </div>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={10}
                      lg={10}
                      xl={10}
                      direction="column"
                    >
                      <form>
                        <div style={{ position: "relative", width: "100%" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: 0,
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "gray",
                            }}
                          >
                            <PersonOutlinedIcon />
                          </div>
                          <input
                            style={{
                              fontFamily: "Lora, serif",
                              fontWeight: 400,
                              borderColor: "transparent",
                              boxShadow: "none",
                              color: "inherit",
                              width: "calc(100% - 5px)",
                              borderBottom: "1px solid gray",
                              paddingLeft: "36px",
                              outline: "none",
                              background: "transparent",
                              marginBottom: "10px",
                            }}
                            id="input-with-icon-textfield"
                            required
                            name="username"
                            placeholder="Username"
                            value={signUpFormData.name}
                            onChange={(e) => handleInputChange(e, "name")}
                          />
                        </div>

                        <div>
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: 0,
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              <MailOutlineIcon />
                            </div>
                            <input
                              style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                borderColor: "transparent",
                                boxShadow: "none",
                                color: "inherit",
                                width: "calc(100% - 5px)",
                                borderBottom: "1px solid gray",
                                paddingLeft: "36px",
                                outline: "none",
                                background: "transparent",
                                marginBottom: "10px",
                              }}
                              id="input-with-icon-textfield"
                              placeholder="Email"
                              required
                              name="email"
                              value={signUpFormData.email}
                              onChange={(e) => handleInputChange(e, "email")}
                            />
                          </div>

                          {emailError && (
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {emailError}
                            </p>
                          )}
                        </div>

                        <div>
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: 0,
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              <LocalPhoneOutlinedIcon />
                            </div>
                            <input
                              style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                borderColor: "transparent",
                                boxShadow: "none",
                                color: "inherit",
                                width: "calc(100% - 5px)",
                                borderBottom: "1px solid gray",
                                paddingLeft: "36px",
                                outline: "none",
                                background: "transparent",
                                marginBottom: "10px",
                              }}
                              id="input-with-icon-textfield"
                              required
                              name="phone"
                              placeholder="Phone"
                              value={signUpFormData.phone}
                              onChange={(e) => handleInputChange(e, "phone")}
                            />
                          </div>

                          {phoneError && (
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {phoneError}
                            </p>
                          )}
                        </div>

                        <div>
                          <div style={{ position: "relative", width: "100%" }}>
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: 0,
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              <AccessibilityNewOutlinedIcon />
                            </div>
                            <input
                              style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                borderColor: "transparent",
                                boxShadow: "none",
                                color: "inherit",
                                width: "calc(100% - 5px)",
                                borderBottom: "1px solid gray",
                                paddingLeft: "36px",
                                outline: "none",
                                background: "transparent",
                                marginBottom: "0px",
                              }}
                              id="input-with-icon-textfield"
                              required
                              name="age"
                              placeholder="Age"
                              value={signUpFormData.age}
                              onChange={(e) => handleInputChange(e, "age")}
                            />
                          </div>
                          {ageError && (
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {ageError}
                            </p>
                          )}
                        </div>

                        <FormControl
                          variant="standard"
                          sx={{
                            mb: 1,
                            width: "100%", // Set the width to 100% for responsiveness
                            maxWidth: "600px",
                          }}
                        >
                          {signUpFormData.gender === "" && (
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              style={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                paddingLeft: "37px",
                                color: "gray",

                                position: "absolute",
                                top:
                                  signUpFormData.gender !== "" ? "0px" : "0px",
                              }}
                            >
                              Gender
                            </InputLabel>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderBottom: "1px solid gray",
                              padding: "10px",
                              position: "relative",
                              width: "100%", // Set the width to 100% for responsiveness
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "56%",
                                left: "0px",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                                color: "gray",
                              }}
                            >
                              <img
                                src={genderImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  // marginRight: "10px",
                                }}
                              />
                            </div>

                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={signUpFormData.gender}
                              onChange={(e) => handleInputChange(e, "gender")}
                              sx={{
                                fontFamily: "Lora, serif",
                                fontWeight: 400,
                                color: "inherit",

                                width: "100%",
                                marginLeft: "27px",
                                top: 4,
                                "&:before": {
                                  borderBottom: "none !important",
                                },
                                "&:after": {
                                  borderBottom: "none !important",
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: { borderBottom: "1px solid #ccc" },
                              }}
                              inputProps={{
                                disableUnderline: true,
                              }}
                            >
                              <MenuItem value={0}>Other</MenuItem>
                              <MenuItem value={1}>Male</MenuItem>
                              <MenuItem value={2}>Female</MenuItem>
                            </Select>
                          </div>

                          <style>
                            {`
                          /* Styles for small screens */
                          @media only screen and (max-width: 600px) {
                            div[style^="display: flex; align-items: center; border-bottom: 1px solid gray; padding: 10px; position: relative;"] {
                              width: 100%;
                            }
                          }
                        `}
                          </style>
                        </FormControl>

                        <div style={{ position: "relative", width: "100%" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: 0,
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "gray",
                            }}
                          >
                            <LocationOnOutlinedIcon />
                          </div>
                          <input
                            style={{
                              fontFamily: "Lora, serif",
                              fontWeight: 400,
                              borderColor: "transparent",
                              boxShadow: "none",
                              color: "inherit",
                              width: "calc(100% - 5px)",
                              borderBottom: "1px solid gray",
                              paddingLeft: "36px",
                              outline: "none",
                              background: "transparent",
                              marginBottom: "5px",
                            }}
                            id="input-with-icon-textfield"
                            required
                            name="Location"
                            placeholder="Location"
                            value={signUpFormData.address}
                            onChange={(e) => handleInputChange(e, "address")}
                          />
                        </div>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: 0,
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "gray",
                            }}
                          >
                            <HttpsOutlinedIcon />
                          </div>
                          <div>
                            <div>
                              <input
                                style={{
                                  fontFamily: "Lora, serif",
                                  fontWeight: 400,
                                  borderColor: "transparent",
                                  boxShadow: "none",
                                  color: "inherit",
                                  width: "calc(100% - 5px)",
                                  borderBottom: "1px solid gray",
                                  paddingLeft: "36px",
                                  marginBottom: "5px",
                                }}
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                required
                                name="password"
                                value={signUpFormData.password}
                                onChange={(e) =>
                                  handleInputChange(e, "password")
                                }
                              />
                            </div>
                            {/* {passwordError && (
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "red",
                              }}
                            >
                              {passwordError}
                            </p>
                          )} */}
                          </div>
                          <IconButton
                            aria-label="toggle password visibility"
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: 0,
                              transform: "translateY(-50%) translateX(-50%)",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </div>

                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: 0,
                              transform: "translateY(-50%)",
                              pointerEvents: "none",
                              color: "gray",
                            }}
                          >
                            <HttpsOutlinedIcon />
                          </div>
                          <input
                            style={{
                              fontFamily: "Lora, serif",
                              fontWeight: 400,
                              borderColor: "transparent",
                              boxShadow: "none",
                              color: "inherit",
                              width: "calc(100% - 5px)",
                              borderBottom: "1px solid gray",
                              paddingLeft: "36px",
                              marginBottom: "10px",
                            }}
                            type={showPassword ? "text" : "password"}
                            required
                            placeholder="Re-type Password"
                            value={signUpFormData.retypePassword}
                            onChange={(e) =>
                              handleInputChange(e, "retypePassword")
                            }
                          />
                          <IconButton
                            aria-label="toggle password visibility"
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: 0,
                              transform: "translateY(-50%) translateX(-50%)",
                            }}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </div>
                        {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          // marginTop: "8px",
                        }}
                      >
                        <Link
                          to="/"
                          component="button"
                          variant="body2"
                          onClick={() => navigate("/")}
                          style={{
                            fontFamily: "Lora",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color:
                              "linear-gradient(90deg, #8098F9 -0.77%, #2A45B2 -0.76%, #F98080 107.17%)",
                            display: "flex", // Use flex display
                            alignItems: "center", // Align items in the center vertically
                            textDecoration: "none", // Remove default underline
                            marginLeft: "auto",
                          }}
                        >
                          <ArrowBackIosIcon
                            style={{ fontSize: "16px", marginRight: "3px" }}
                          />{" "}
                          Back To Home
                        </Link>
                      </div> */}

                        {/* <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isRememberMeChecked}
                              onChange={handleRememberMeChange}
                            />
                          }
                          label="Remember Me"
                        />
                      </div> */}

                        <style>
                          {`
                        /* Styles for small screens */
                        @media only screen and (max-width: 600px) {
                          input {
                            width: 100%;
                            padding-left: 10px;
                          }
                  
                          div[style^="position: relative; width: 100%;"] {
                            width: 100%;
                          }
                        }
                      `}
                        </style>
                      </form>
                      <>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          sx={{
                            display: "inline-flex",
                            height: "48px",
                            padding: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            flexShrink: 0,
                            borderRadius: "10px",
                            background:
                              "linear-gradient(90deg, #8098F9 -0.77%, #2A45B2 -0.76%, #F98080 107.17%) !important",
                            "@media screen and (max-width: 600px)": {
                              // Adjust styles for screens with a maximum width of 600px
                              height: "48px",
                              padding: "8px",
                              fontSize: "14px",
                              gap: "6px",
                            },
                            mt: "10px",
                          }}
                          onClick={handleSignUpClick}
                          disabled={signupLoading || isToastOpen}
                        >
                          {signupLoading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                          Sign Up
                        </Button>

                        {/* <div className="text-center my-3">
                        <span
                          className="fw-500 fs-16 text-white"
                          style={{
                            marginRight: "10px",
                            fontFamily: "Lora,",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          Already a member?
                        </span>

                        <Link
                          to="/sign_up"
                          component="button"
                          variant="body2"
                          style={{
                            fontFamily: "Lora,",
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#8098F9",
                          }}
                          onClick={() => navigate("/login")}
                        >
                          Login
                        </Link>
                      </div> */}

                        {showOtpBox && (
                          <OtpPopup
                            openOtpPopup={showOtpBox}
                            setOpenOtpPopup={setShowOtpBox}
                            isForgetPassword={false}
                            setIsForgetPassword={null}
                            email={signUpFormData.email}
                            setShowResetPasswordPopup={null}
                            setApiSuccess={setApiSuccess}
                          />
                        )}
                      </>
                      {apiSuccess && (
                        <Dialog
                          open={dialogOpen}
                          onClose={handleDialogClose}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "50vh", // Adjust the minimum height as needed
                          }}
                        >
                          <Card
                            sx={{
                              width: "100%",
                              maxWidth: 375,
                              textAlign: "center",
                              maxHeight: "90vh", // Adjust the maximum height as needed
                            }}
                          >
                            <img
                              src="images/signup.gif"
                              alt="Loading"
                              style={{
                                width: "100%",
                                maxHeight: "70%",
                                objectFit: "cover",
                              }}
                            />
                            <CardContent>
                              <Typography
                                variant="h5"
                                component="div"
                                style={{
                                  color: "#09090B",
                                  textAlign: "center",
                                  fontFamily: "Lora",
                                  fontSize: "24px",
                                  fontStyle: "normal",
                                  fontWeight: 700,
                                  lineHeight: "normal",
                                }}
                              >
                                You have successfully registered!
                              </Typography>
                            </CardContent>
                          </Card>
                        </Dialog>
                      )}

                      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }}>
                        or continue with
                      </Divider>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleContinueWithGoogle}
                          sx={{
                            color: "#09090B !important",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "16px",
                          }}
                        >
                          <img
                            src={Google}
                            alt="Google"
                            style={{
                              width: "14px",
                              height: "14px",
                              marginRight: "8px",
                            }}
                          />
                          Google
                        </Button>

                        <Button
                          variant="outlined"
                          onClick={handleContinueWithFacebook}
                          sx={{
                            color: "#09090B !important",
                            fontFamily: "Montserrat !important",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "16px",
                          }}
                        >
                          <img
                            src={facebook}
                            alt="Facebook"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "8px",
                            }}
                          />
                          Facebook
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
