import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  Dialog,
  Typography,
  Button,
  TextField,
  Grid,
  DialogContent,
  Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { getTokenFromLocalStorage } from "../../authService";
import CircularProgress from "@mui/material/CircularProgress";

const CheckoutForm = ({
  handlePayment,
  profileId,
  membershipId,
  open,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {

      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (error) {
        console.error(error.message);
        return;
      }
      // console.log(token.id);

      handlePayment(token.id);
      setLoginLoading(true);

      const headers = {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        "Content-Type": "application/json",
      };

      try {
        const response = await axios.post(
          "/add_order",
          {
            user_id: profileId,
            membership_id: membershipId,
            stripe_token: token.id,
          },
          { headers }
        );
        if (response.data.status === 200) {
      setIsToastOpen(true);

          toast.success("Congratulations! Your membership plan is now active", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3500);
        setLoginLoading(false);

          onClose();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        setLoginLoading(false);

        }
      } catch (error) {
        console.error("Error adding order:", error);
      }
      setLoginLoading(false);

    } catch (error) {
      console.error("Error creating token:", error);
    }
    setLoginLoading(false);

  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogContent
        sx={{
          padding: "24px", // You can adjust the padding as needed
          backgroundColor: "#8d6db5", // Set background color to #863895
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            minWidth: "400px",
            backgroundColor: "#EDEDED",
            padding: "12px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              fontFamily: "Lora",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "28px",
              letterSpacing: "0em",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Card Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#863895",
                      "::placeholder": {
                        color: "#202226",
                      },
                    },
                    invalid: {
                      color: "#FE0307",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              {/* <TextField
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            /> */}
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={2} direction="row" alignItems="end">
                <Button
                  variant="outlined"
                  onClick={onClose}
                  style={{
                    color: "#863895", // Text color
                    borderColor: "#863895", // Border color
                    fontFamily: "Lora", // Font family
                    fontSize: "12px", // Font size
                    fontWeight: 700, // Font weight
                    lineHeight: "25px", // Line height
                    letterSpacing: "0em", // Letter spacing
                    textAlign: "left", // Text alignment
                    textTransform: "none",

                  }}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    backgroundColor: "#863895", // Background color
                    color: "white", // Text color
                    fontFamily: "Lora", // Font family
                    fontSize: "12px", // Font size
                    fontWeight: 700, // Font weight
                    lineHeight: "25px", // Line height
                    letterSpacing: "0em", // Letter spacing
                    textAlign: "left", // Text alignment
                    textTransform: "none",
                  }}
                  disabled={loginLoading || isToastOpen}

                >
                   {loginLoading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                  Pay Now
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CheckoutForm;
