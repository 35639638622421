import React, { useState, useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Header2 from "../../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkOut";
import acceptedImg from "../../../assets/noBlockedUsers.svg";
import { toast, ToastContainer } from "react-toastify";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Footer from "../../FooterPages/Footer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CakeIcon from "@mui/icons-material/Cake";
import TransgenderIcon from "@mui/icons-material/Transgender";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import BentoIcon from "@mui/icons-material/Bento";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import symbols_edit from "../../../assets/symbols_edit.png";
import OBJECTS from "../../../assets/OBJECTS.png";
import logout from "../../../assets/logout.png";
import InputAdornment from "@mui/material/InputAdornment";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { getTokenFromLocalStorage } from "../../authService";
import { updateOnlineStatus } from "../../../Firebase";
import "../../../fonts.css";
import { db } from "../../../Firebase";
import ghostTag from "../../../assets/OBJECTS.png";
import personImg from "../../../assets/material-symbols_person.png";
import emailImg from "../../../assets/profileEmail.png";
import callImg from "../../../assets/material-symbols_call.png";
import descImg from "../../../assets/profileDesc.png";
import ageImg from "../../../assets/profileAge.png";
import addressImg from "../../../assets/mdi_location.png";
import calenderImg from "../../../assets/ph_calendar-duotone.png";
import genderImg from "../../../assets/profileGender.png";
import { CardMedia } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import {
  collection,
  query,
  onSnapshot,
  Timestamp,
  addDoc,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  setDoc,
  getDocs,
  deleteDoc,
  where,
} from "firebase/firestore";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "20px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },

    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    body2: {
      // width: '90px',
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});

const listtextStyle = {
  color: "#202226",
  fontFamily: "Lora",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingLeft: "10px",
};
const ProfilePage = ({ handleLogout, open, setOpen }) => {
  const [newPassword, setNewPassword] = useState("");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [profileId, setProfileId] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [open2, setOpen2] = React.useState(false);
  const [dataList, setDataList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [image2File, setImage2File] = useState(
    "https://via.placeholder.com/200x190"
  );
  const [image3File, setImage3File] = useState(
    "https://via.placeholder.com/200x190"
  );
  const [image4File, setImage4File] = useState(
    "https://via.placeholder.com/200x190"
  );
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    user_type: "1",
    name: "",
    phone: "",
    gender: "1",
    address: "",
    age: "",
    new_password: "",
  });

  const [profileData, setProfileData] = useState(null);
  const [membershipId, setMembershipId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [buttonValue, setButtonValue] = useState("1");
  const [selectedFile, setSelectedFile] = useState(
    "https://via.placeholder.com/200x190"
  );
  const theme = useTheme();
  const cardColors = ["#808080", "#863895", "#2E5CFF"];

  // const [image1, setImage1] = useState("https://via.placeholder.com/200x190");

  const handleDelete = (setImage) => {
    setImage("https://via.placeholder.com/200x190"); // Set the image URL to an empty string or null
  };

  const handleFileChange = (event, imageNumber) => {
    const file = event.target.files[0];

    // Choose the correct state key based on the imageNumber
    let stateKey = "";
    switch (imageNumber) {
      case 1:
        stateKey = "profile_img";
        break;
      case 2:
        stateKey = "image_2";
        break;
      case 3:
        stateKey = "image_3";
        break;
      case 4:
        stateKey = "image_4";
        break;
      default:
        break;
    }

    // Update the state only for the corresponding image
    setUserDetails((prevUserDetails) => ({
      ...prevUserDetails,
      [stateKey]: URL.createObjectURL(file),
    }));

    // Update the selected file for further use (if needed)
    switch (imageNumber) {
      case 1:
        setSelectedFile(file);
        break;
      case 2:
        setImage2File(file);
        break;
      case 3:
        setImage3File(file);
        break;
      case 4:
        setImage4File(file);
        break;
      default:
        break;
    }
  };
  const handleOpen = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const stripePromise = loadStripe(
    "pk_test_51OrhDf042YBPNJH4he7oDW7jWAJHn2LHjJwFq2NwqY8DD34hINl4EY3KKd6db4O4jmZXfX94O4i4tXjLRIwAWBUH00aetJKXEP"
  );

  const handleOpenDialog = (membershipId) => {
    setOpenDialog(true); // Open the dialog
    setMembershipId(membershipId); // Set the membershipId in state
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  const fetchData = async () => {
    try {
      const token = getTokenFromLocalStorage();
      console.log("Retrieved Token:", token);

      const response = await axios.post(
        "membership_list",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response.data);
      setMembershipList(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   const getProfileImages = async () => {
  //     try {
  //       // Get the token
  //       const token = getTokenFromLocalStorage();

  //       // Make the API request using Axios
  //       const response = await axios.get("/get_profile_images", {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       // debugger;

  //       // Set the API response data in the state
  //       // setuserDetails(response.data.data);
  //       setUserDetails({
  //         profile_img: response.data.data.profile_img,
  //         image_2: response.data.data.image_2,
  //         image_3: response.data.data.image_3,
  //         image_4: response.data.data.image_4,
  //       });
  //       // debugger
  //     } catch (error) {
  //       // Handle any errors
  //       console.error("Error:", error.message);
  //     }
  //   };

  //   // Call the function to get profile images
  //   getProfileImages();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const initialGenderValue = response.data.data.gender;
        setProfileData(response.data);
        setProfileId(response.data.data.id);
        // debugger
        // console.log("Profile Data:", response.data);

        const mapGenderToDisplay = (gender) => {
          switch (gender) {
            case 0:
              return "Other";
            case 1:
              return "Male";
            case 2:
              return "Female";
            default:
              return "";
          }
        };
        setUserDetails({
          name: response.data.data.name,
          email: response.data.data.email,
          phone: response.data.data.phone,
          // gender: mapGenderToDisplay(initialGenderValue),
          gender: response.data.data.gender,
          address: response.data.data.address,
          age: response.data.data.age,
          profile_img: response.data.data.profile_img,
          image_2: response.data.data.image_2,
          image_3: response.data.data.image_3,
          image_4: response.data.data.image_4,
          is_ghost: response.data.data.is_ghost,
        });
        // debugger
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, [buttonValue, navigate]);

  const buttons = [
    {
      buttonname: " Profile",
      value: "1",
    },

    {
      buttonname: "Change Password",
      value: "2",
    },
    // {
    //   buttonname: "Membership",
    //   value: "3",
    // },
    {
      buttonname: "Blocked Users",
      value: "4",
    },
  ];
  const commonButtonStyles = {
    "&:hover": {
      backgroundColor: "none",
      borderColor: "#FFF",
    },
    color: "#FFF",
    fontFamily: "Lora",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "none !important",
    fontFeatureSettings: `'clig' off, 'liga' off`,
    borderColor: "#FFF",
    borderRadius: "20px",
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const [buttonValue, setValue] = useState("1");
  const handleInputChange = (event, field) => {
    setUserDetails({
      ...userDetails,
      [field]: event.target.value,
    });
  };
  const [error, setError] = useState("");

  const handleAgeChange = (e) => {
    const { value } = e.target;
    // Check if the value is an integer
    if (value === "" || /^[0-9]+$/.test(value)) {
      setUserDetails({ ...userDetails, age: value });
      setError(""); // Clear error message if input is valid
    } else {
      setError("Age must be an integer");
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleUpdateProfile = () => {
    if (
      !userDetails.name ||
      !userDetails.phone ||
      !userDetails.gender ||
      !userDetails.address ||
      !userDetails.age
    ) {
      // Show error message using toast
      toast.error("All fields are required");
      return; // Stop further execution
    }

    if (isNaN(userDetails.phone)) {
      // Show error message using toast
      toast.error("Phone number must be numeric");
      return; // Stop further execution
    }
    if (parseInt(userDetails.age) < 18) {
      toast.error("Age must be at least 18 years old.");

      return;
    }

    const formData = new FormData();

    formData.append("name", userDetails.name);
    formData.append("email", userDetails.email);
    formData.append("phone", userDetails.phone);
    formData.append("gender", userDetails.gender);
    formData.append("address", userDetails.address);
    formData.append("age", userDetails.age);
    formData.append("user_type", "1");

    if (selectedFile) {
      formData.append("profile_img", selectedFile);
    }

    // Append image_2
    if (image2File) {
      formData.append("image_2", image2File);
    }

    // Append image_3
    if (image3File) {
      formData.append("image_3", image3File);
    }

    // Append image_4
    if (image4File) {
      formData.append("image_4", image4File);
    }

    console.log("FormData:", formData);

    const token = getTokenFromLocalStorage();

    axios
      .post("updateProfile", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        toast.success("Profile updated successfully", {
          onClose: () => {
            const membershipStatus = localStorage.getItem("status");
            if (membershipStatus == "0") {
              navigate("/home");
            } else {
              window.location.reload();
            }
            setIsEditing(false);
          },
        });
      })
      .catch((error) => {
        console.error("Error updating profile", error);
      });
  };

  useEffect(() => {
    const blockedUsersApi = async () => {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const apiUrl = "getEventBlockUser";
        const response = await axios.get(apiUrl, { headers });
        setDataList(response.data.data);
        // Print the response data
        console.log("API Response:", response.data);
        //  debugger
      } catch (error) {
        console.error("Error fetching data from the API:", error.message);
      }
    };

    // Call the blockedUsersApi function
    blockedUsersApi();
  }, []);
  const handleConvergePayment = async (membershipId, profileId) => {
    // handleClosePaymentDialog();

    const apiUrl = "generate_converge_token";

    try {
      const token = getTokenFromLocalStorage();

      const response = await axios.post(
        apiUrl,
        {
          user_id: profileId,
          membership_id: membershipId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Response from Converge API:", response.data.url);
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error occurred while calling Converge API:", error);
    }
  };
  const handleDeleteAccount = async (profileId) => {
    const token = getTokenFromLocalStorage();

    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        "user_delete",
        { user_id: profileId },
        { headers }
      );

      if (response.status === 200) {
        toast.success("Your account has been successfully deleted");
        localStorage.removeItem("authToken");
        navigate("/");
      }
    } catch (error) {
      toast.error("An error occurred while deleting your account");
      console.error("Error deleting account:", error);
    }
  };

  const handleChangePassword = async () => {
    try {
      if (newPassword !== confirmPassword) {
        console.error("New password and confirm password do not match");
        toast.error("New password and confirm password do not match");
        return;
      }
      if (currentPassword.length < 8 || confirmPassword.length < 8) {
        // console.error(
        //   "Current password or confirm password is less than 8 characters"
        // );
        toast.error(
          "Current password or confirm password must be equal to or more than 8 characters"
        );
        return;
      }
      const apiUrl = "resetPassword";
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");

        return;
      }

      console.log("Retrieved Token:", token);

      const response = await axios.post(
        apiUrl,
        {
          email: userDetails.email,
          new_password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response:", response.data);
      if (response.status === 200 && response.data.success) {
        // Show success message from the API response using toast
        toast.success(response.data.message);
      } else {
        // Handle other response statuses or API errors if needed
        console.error("Password reset failed. Status:", response.status);
        toast.error(response.data.message || "Password reset failed");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const unBlockApi = async (user_id) => {
    const token = getTokenFromLocalStorage();

    try {
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "unblockUser";

      const params = {
        user_id: user_id,
      };

      const response = await axios.post(apiUrl, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response:", response.data);
    } catch (apiError) {
      console.error("Error in API call:", apiError);
    }
  };
  const findUserUid = async (userEmail) => {
    try {
      const q = query(collection(db, "users"), where("email", "==", userEmail));

      const qSnapshot = await getDocs(q);
      const userDoc = qSnapshot.docs[0];

      if (userDoc) {
        const userData = userDoc.data();
        const userUid = userData.uid;
        return userUid;
      } else {
        console.log("User not found");
        return null; // or handle the case when the user is not found
      }
    } catch (error) {
      console.error("Error querying Firestore:", error);
      return null; // or handle the error as per your requirement
    }
  };

  const unblockUserFromFireBase = async (userId, userEmail) => {
    try {
      const myId = await findUserUid(userDetails.email);
      const otherPersonId = await findUserUid(userEmail);
      const roomId = [myId, otherPersonId].sort().join("");
      const chatsRef = doc(db, "chats", roomId);

      const unsubscribe = onSnapshot(chatsRef, async (docSnap) => {
        try {
          console.log("Snapshot received", docSnap);

          unsubscribe();

          // const isBlockedByMe = docSnap?.data()?.[myId]?.isBlockedByMe || false;
          // const isBlockedForMe =
          //   docSnap?.data()?.[otherPersonId]?.blocked || false;

          const updatedData = {
            [otherPersonId]: {
              blocked: false,
              isBlockedByMe: false,
            },
            [myId]: {
              blocked: false,
              isBlockedByMe: false,
            },
          };

          await updateDoc(chatsRef, updatedData);
          window.location.reload();
        } catch (error) {
          console.error("Error updating block status:", error);
        }
      });
    } catch (error) {
      console.error("Error toggling block:", error);
    }
  };

  const handleUnblock = async (userId, userEmail) => {
    try {
      await unBlockApi(userId);
      unblockUserFromFireBase(userId, userEmail);
    } catch (error) {
      console.error("Error unblocking user:", error);
    }
  };

  const handleClick = (e) => {
    setButtonValue(e.value);
    if (e.value === "3") {
      fetchData();
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-Lora gap-[40px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />

        <Grid container spacing={2} sx={{ width: "100%", margin: "auto" }}>
          {headerLoading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 9999,
              }}
            >
              <MoonLoader color="#863895" size={80} />
            </div>
          )}
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <Card
              sx={{
                width: "100%",
                margin: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "rgba(160, 195, 255, 0.06)",

                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
              }}
            >
              <CardContent sx={{ flex: "1 1 auto" }}>
                <Stack spacing={2}>
                  {buttons.map((items, index) => {
                    return (
                      <Button
                        variant="outlined"
                        key={index}
                        sx={{
                          border: "1px solid #863895 !important",
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px 25px",
                          gap: "5px",
                          borderRadius: "2px",
                          backgroundColor:
                            items.value == buttonValue
                              ? "#863895 !important"
                              : "transparent !important",
                          color:
                            items.value == buttonValue
                              ? "white"
                              : "#863895 !important",
                          "&:hover": {
                            backgroundColor:
                              items.value == buttonValue
                                ? "#863895 !important"
                                : "none !important",
                          },
                        }}
                        onClick={() => handleClick(items)}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "Lora",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            textTransform: "none !important",
                            fontFeatureSettings: `'clig' off, 'liga' off`,
                          }}
                        >
                          {items.buttonname}
                        </Typography>
                      </Button>
                    );
                  })}
                </Stack>
              </CardContent>
              <CardActions
                sx={{
                  marginTop: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    border: " 0.968px solid rgba(255, 53, 40, 0.80)",
                    backgroundColor: "rgba(255, 53, 40, 0.80)  !important",
                    borderRadius: "6px",
                    padding: "10px 25px",
                    "&:hover": {
                      backgroundColor: "rgba(255, 53, 40, 0.80)",
                      color: "#FFF",
                    },
                    color: "#FFF  !important",
                    fontFamily: "Lora",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    textTransform: "none !important",
                    fontFeatureSettings: `'clig' off, 'liga' off`,
                  }}
                  endIcon={
                    <img
                      src={logout}
                      alt="logo"
                      style={{ width: "18px", height: "18px" }}
                    />
                  }
                  onClick={handleClickOpen}
                >
                  Logout
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent>
              <DialogContentText
                sx={{
                  color: "#404040",
                  fontFamily: "Lora",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "18px",
                  textTransform: "none !important",
                }}
              >
                Are you sure you want to log out?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px", // Adjust the margin as needed
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "15px",
                  textTransform: "none !important",
                  borderColor: "#FF3932",
                  display: " inline-flex",
                  color: "#FF3932",
                  padding: "4px 25px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: " 10px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FF3932  !important",
                  borderRadius: "15px",
                  textTransform: "none !important",
                  display: " inline-flex",
                  padding: "4px 25px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: " 10px",
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            {buttonValue == "1" ? (
              <>
                <Card
                  sx={{
                    position: "relative",
                    backgroundColor: "rgba(160, 195, 255, 0.06)",

                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      top: 0,
                      minHeight: "80px",
                      left: 0,
                      backgroundColor: "#863895",
                      borderRadius: "0px 0px 43.919px 43.919px",
                    }}
                  >
                    <CardActions>
                      <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          padding: "16px",
                        }}
                      >
                        {isEditing ? (
                          <Button
                            variant="outlined"
                            onClick={handleUpdateProfile}
                            sx={{
                              "&:hover": {
                                backgroundColor: "none",
                                borderColor: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              fontFeatureSettings: `'clig' off, 'liga' off`,
                              borderColor: "#FFF",
                              borderRadius: "20px",
                            }}
                          >
                            Update Profile
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={
                              isEditing ? handleUpdateProfile : handleEditClick
                            }
                            sx={commonButtonStyles}
                          >
                            {isEditing ? "Update Profile" : "Edit Profile"}
                            {isEditing && (
                              <img
                                src={symbols_edit}
                                alt="logo"
                                style={{ width: "16px", height: "16px" }}
                              />
                            )}
                          </Button>
                        )}
                      </Stack>
                    </CardActions>
                    <Stack direction="row" spacing={3}>
                      <label htmlFor="profilePictureInput">
                        <img
                          src={userDetails.profile_img}
                          alt="ellipseTwelve"
                          className="md:h-auto sm:ml-[0] sm:mt-0 rounded-[60%] w-[60px] h-[60px]"
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "10%",
                            transform: "translate(-50%, 50%)",
                            zIndex: 1,
                            cursor: isEditing ? "pointer" : "default",
                            pointerEvents: isEditing ? "auto" : "none",
                          }}
                        />
                        {userDetails.is_ghost === 1 && (
                          <img
                            src={ghostTag}
                            alt="ellipseTwelve"
                            style={{
                              width: "28px",
                              height: "28px",
                              flexShrink: 0,
                              position: "absolute",
                              left: "55%",
                              top: "10%",
                              transform: "translate(-50%, 80%)",
                              zIndex: 1,
                              cursor: isEditing ? "pointer" : "default",
                              pointerEvents: isEditing ? "auto" : "none",
                            }}
                            className="sm:left-65"
                          />
                        )}

                        <input
                          type="file"
                          id="profilePictureInput"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={isEditing ? handleFileChange : null}
                        />
                      </label>
                    </Stack>
                  </Paper>

                  <div>
                    <CardContent>
                      <List aria-label="contacts">
                        <ListItem style={{ marginBottom: "-16px" }}>
                          <ListItemText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={ageImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              <TextField
                                variant="standard"
                                value={userDetails.name}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    name: e.target.value,
                                  })
                                }
                                disabled={!isEditing}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                                fullWidth
                              />
                            </div>
                          </ListItemText>
                        </ListItem>

                        <ListItem style={{ marginBottom: "-16px" }}>
                          <ListItemText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={emailImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              <TextField
                                // label="Email"
                                variant="standard"
                                value={userDetails.email}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    email: e.target.value,
                                  })
                                }
                                disabled={true}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                                style={{ width: "100%", maxWidth: "600px" }}
                              />
                            </div>
                          </ListItemText>
                        </ListItem>
                        <ListItem style={{ marginBottom: "-16px" }}>
                          <ListItemText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={callImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              <TextField
                                variant="standard"
                                value={userDetails.phone}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    phone: e.target.value,
                                  })
                                }
                                disabled={!isEditing}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                                style={{ width: "100%", maxWidth: "600px" }}
                              />
                            </div>
                          </ListItemText>
                        </ListItem>
                        <ListItem style={{ marginBottom: "-16px" }}>
                          <ListItemText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={personImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              <TextField
                                variant="standard"
                                value={userDetails.age}
                                onChange={handleAgeChange}
                                disabled={!isEditing}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                                style={{
                                  width: "100%",
                                  marginLeft: "3px",
                                  maxWidth: "600px",
                                }}
                              />
                            </div>
                            {error && (
                              <p style={{ color: "red", margin: "5px 0" }}>
                                {error}
                              </p>
                            )}
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <FormControl
                            variant="standard"
                            sx={{
                              mb: -2,
                              width: "700px",
                              maxWidth: "100% !important",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={genderImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />

                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={userDetails?.gender}
                                onChange={(e) => handleInputChange(e, "gender")}
                                disabled={!isEditing}
                                sx={{
                                  width: "100%",
                                  marginLeft: "3px",

                                  top: 0,
                                  "&:before": {
                                    borderBottom: "none !important",
                                  },
                                  "&:after": {
                                    borderBottom: "none !important",
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                inputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                              >
                                <MenuItem value={0}>Other</MenuItem>
                                <MenuItem value={1}>Male</MenuItem>
                                <MenuItem value={2}>Female</MenuItem>
                              </Select>
                            </div>
                          </FormControl>
                        </ListItem>

                        <ListItem style={{ marginBottom: "-16px" }}>
                          <ListItemText>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid #ccc",
                                padding: "10px",
                                maxWidth: "700px",
                              }}
                            >
                              <img
                                src={addressImg}
                                alt="EventImage"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "10px",
                                }}
                              />
                              <TextField
                                // label="Address"
                                variant="standard"
                                value={userDetails.address}
                                onChange={(e) =>
                                  setUserDetails({
                                    ...userDetails,
                                    address: e.target.value,
                                  })
                                }
                                disabled={!isEditing}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    borderBottom: "1px solid #ccc",
                                    fontFamily: "Lora",
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: true,
                                  style: { fontFamily: "Lora" },
                                }}
                                style={{ width: "100%", maxWidth: "600px" }}
                              />
                            </div>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </CardContent>
                  </div>

                  <Grid container m={3} spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      {" "}
                      <Card
                        style={{
                          width: "100%",
                          flexShrink: 0,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {userDetails && userDetails.profile_img ? (
                          <>
                            <CardMedia
                              component="img"
                              alt="Image 1"
                              image={userDetails.profile_img}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px", // Set the maximum height here
                                objectFit: "contain", // Use "contain" to fit the entire image without cropping
                              }}
                            />
                            <IconButton
                              aria-label="delete"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => handleDelete(setSelectedFile)}
                            >
                              {/* <DeleteIcon /> */}
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <p>No image available</p>
                          </>
                        )}
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              border: "1px solid #863895",
                              backgroundColor: isEditing
                                ? "#863895"
                                : "rgba(134, 56, 149, 0.5)",

                              borderRadius: "3.808px",
                              "&:hover": {
                                backgroundColor: "#863895",
                                color: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            startIcon={<PhotoCameraIcon />}
                            disabled={!isEditing}
                          >
                            Upload
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, 1)}
                            />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      {" "}
                      <Card
                        style={{
                          width: "100%",
                          flexShrink: 0,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {userDetails && userDetails.image_2 ? (
                          <>
                            <CardMedia
                              component="img"
                              alt="Image 2"
                              height="140"
                              image={userDetails.image_2}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px", // Set the maximum height here
                                objectFit: "contain", // Use "contain" to fit the entire image without cropping
                              }}
                            />
                            <IconButton
                              aria-label="delete"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => handleDelete(setImage2File)}
                            >
                              {/* <DeleteIcon /> */}
                            </IconButton>
                          </>
                        ) : (
                          <p>No image available</p>
                        )}
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              border: "1px solid #863895",
                              backgroundColor: isEditing
                                ? "#863895"
                                : "rgba(134, 56, 149, 0.5)",

                              borderRadius: "3.808px",
                              "&:hover": {
                                backgroundColor: "#863895",
                                color: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            startIcon={<PhotoCameraIcon />}
                            disabled={!isEditing}
                          >
                            Upload
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, 2)}
                            />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      {" "}
                      <Card
                        style={{
                          width: "100%",
                          flexShrink: 0,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {userDetails && userDetails.image_3 ? (
                          <>
                            <CardMedia
                              component="img"
                              alt="Image 3"
                              height="140"
                              image={userDetails.image_3}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px", // Set the maximum height here
                                objectFit: "contain", // Use "contain" to fit the entire image without cropping
                              }}
                            />
                            <IconButton
                              aria-label="delete"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => handleDelete(setImage3File)}
                            >
                              {/* <DeleteIcon /> */}
                            </IconButton>
                          </>
                        ) : (
                          <p>No image available</p>
                        )}

                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              border: "1px solid #863895",
                              backgroundColor: isEditing
                                ? "#863895"
                                : "rgba(134, 56, 149, 0.5)",

                              borderRadius: "3.808px",
                              "&:hover": {
                                backgroundColor: "#863895",
                                color: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            startIcon={<PhotoCameraIcon />}
                            disabled={!isEditing}
                          >
                            Upload
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, 3)}
                            />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Card
                        style={{
                          width: "100%",
                          flexShrink: 0,
                          position: "relative",
                          textAlign: "center",
                        }}
                      >
                        {userDetails && userDetails.image_4 ? (
                          <>
                            <CardMedia
                              component="img"
                              alt="Image 4"
                              // height="124"
                              image={userDetails.image_4}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px", // Set the maximum height here
                                objectFit: "contain", // Use "contain" to fit the entire image without cropping
                              }}
                            />
                            <IconButton
                              aria-label="delete"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                              }}
                              onClick={() => handleDelete(setImage4File)}
                            >
                              {/* <DeleteIcon /> */}
                            </IconButton>
                          </>
                        ) : (
                          <p>No image available</p>
                        )}
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              border: "1px solid #863895",
                              backgroundColor: isEditing
                                ? "#863895"
                                : "rgba(134, 56, 149, 0.5)",
                              borderRadius: "3.808px",
                              "&:hover": {
                                backgroundColor: "#863895",
                                color: "#FFF",
                              },
                              color: "#FFF",
                              fontFamily: "Lora",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              textTransform: "none !important",
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            startIcon={<PhotoCameraIcon />}
                            disabled={!isEditing}
                          >
                            Upload
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(event) => handleFileChange(event, 4)}
                            />
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>

                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 12, // Add margin-top
                      mb: 6, // Add margin-bottom
                    }}
                  >
                    {!isEditing && (
                      <Stack direction="row" spacing={2}>
                        <Button
                          variant="outlined"
                          sx={{
                            border: "1px solid #FF4040 !important",
                            borderRadius: "6px",

                            padding: "10px 35px",
                            color: "#FF4040",
                            fontFamily: "Lora",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "normal",
                            textTransform: "none !important",
                            fontFeatureSettings: `'clig' off, 'liga' off`,
                          }}
                          onClick={handleOpen}
                        >
                          Permanently Delete Account
                        </Button>
                        <Dialog
                          fullScreen={fullScreen}
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogContent>
                            <DialogContentText
                              sx={{
                                color: "#404040",
                                fontFamily: "Lora",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "18px",
                                textTransform: "none !important",
                              }}
                            >
                              Are you sure you want delete your account?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "10px", // Adjust the margin as needed
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                borderRadius: "15px",
                                textTransform: "none !important",
                                borderColor: "#FF3932",
                                display: " inline-flex",
                                color: "#FF3932",
                                padding: "4px 25px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: " 10px",
                              }}
                              onClick={handleClose2}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#FF3932  !important",
                                borderRadius: "15px",
                                textTransform: "none !important",
                                display: " inline-flex",
                                padding: "4px 25px",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: " 10px",
                              }}
                              onClick={() => handleDeleteAccount(profileId)}
                            >
                              Confirm
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Stack>
                    )}
                  </CardActions>
                </Card>
              </>
            ) : buttonValue == "2" ? (
              <>
                <Card
                  sx={{
                    position: "relative",
                    backgroundColor: "rgba(160, 195, 255, 0.06)",

                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  <CardContent>
                    <ThemeProvider theme={headingFont}>
                      <Typography variant="h6" align="center" gutterBottom>
                        Change Password
                      </Typography>
                    </ThemeProvider>
                    <ThemeProvider theme={headingFont}>
                      <Typography
                        variant="subtitle1"
                        align="center"
                        gutterBottom
                        sx={{ fontFamily: "Lora", marginBottom: "32px" }}
                      >
                        Want a new password? Simply enter your current password,
                        choose a new password, type it one more time and done!
                      </Typography>
                    </ThemeProvider>
                    <Stack spacing={2}>
                      <TextField
                        label=""
                        placeholder="Current Password"
                        type="text"
                        variant="outlined"
                        fullWidth
                        // style={{
                        //   width: "50%",
                        //   margin: "auto",
                        //   marginBottom: "16px",
                        // }}
                        InputProps={{
                          style: {
                            fontFamily: "Lora",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#212121",
                            lineHeight: "17px",
                            marginTop: "8px",
                            width: "50%",
                            margin: "auto",
                            marginBottom: "16px",
                          },
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff", // Use 'inherit' or the desired color to override
                          },
                          "& [type='text']:focus": {
                            boxShadow: "none", // Remove box shadow on focus for type='text'
                            // borderColor: "#863895 !important",
                          },
                        }}
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        placeholder="New Password"
                        required
                        fullWidth
                        name="password"
                        label=""
                        id="password"
                        autoComplete="New Password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Lora",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#212121",
                            lineHeight: "17px",
                            marginTop: "8px",
                            width: "50%",
                            // margin: "auto",
                            width: "50%",
                            margin: "auto",
                            marginBottom: "16px",
                          },
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff", // Use 'inherit' or the desired color to override
                          },
                          "& [type='text']:focus": {
                            boxShadow: "none", // Remove box shadow on focus for type='text'
                            // borderColor: "#863895 !important",
                          },
                        }}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <TextField
                        variant="outlined"
                        placeholder="Confirm Password"
                        required
                        fullWidth
                        name="password"
                        label=""
                        id="password"
                        autoComplete="current-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HttpsOutlinedIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontFamily: "Lora",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#212121",
                            lineHeight: "17px",
                            marginTop: "8px",
                            width: "50%",
                            // margin: "auto",
                            width: "50%",
                            margin: "auto",
                            marginBottom: "16px",
                          },
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff", // Use 'inherit' or the desired color to override
                          },
                          "& [type='text']:focus": {
                            boxShadow: "none", // Remove box shadow on focus for type='text'
                            // borderColor: "#863895 !important",
                          },
                        }}
                      />
                    </Stack>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        sx={{
                          border: "1px solid #863895",
                          backgroundColor: "#863895  !important",
                          borderRadius: "6px",
                          "&:hover": {
                            backgroundColor: "#863895",
                            color: "#FFF",
                          },
                          color: "#FFF",
                          padding: "8px 30px",
                          fontFamily: "Lora",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                          textTransform: "none !important",
                          fontFeatureSettings: `'clig' off, 'liga' off`,
                        }}
                        onClick={handleChangePassword}
                      >
                        Change Password
                      </Button>
                    </Stack>
                  </CardActions>
                </Card>
              </>
            ) : buttonValue == "4" ? (
              <>
                {dataList.length === 0 ? (
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 1300,
                      textAlign: "center",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(160, 195, 255, 0.06)",

                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <img
                      src={acceptedImg}
                      alt="Mobile"
                      style={{
                        width: "35%",
                        height: "auto",
                        "@media screen and (max-width: 600px)": {
                          width: "50% !important",
                        },
                      }}
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          marginTop: 2,
                          color: "#2F2F2F",
                          textAlign: "center",
                          fontFamily: "Lora",
                          fontSize: "32px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                          "@media screen and (max-width: 600px)": {
                            fontSize: "24px",
                          },
                          "@media screen and (max-width: 400px)": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        This is great! You've not blocked anyone yet.
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <Card
                    sx={{
                      position: "relative",
                      backgroundColor: "rgba(160, 195, 255, 0.06)",

                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    <CardContent>
                      <ThemeProvider theme={headingFont}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          style={{
                            color: "#09090B",
                            fontFamily: "Lora",
                            fontSize: "32px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "normal",
                          }}
                        >
                          Blocked Users
                        </Typography>
                      </ThemeProvider>
                      <List>
                        {dataList.map((item, index) => (
                          <ListItem
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                variant="outlined"
                                sx={{
                                  border: "1px solid #3A9538",
                                  borderRadius: "5px",
                                  "&:hover": {
                                    backgroundColor: "none",
                                  },
                                  color: "#3A9538",
                                  fontFamily: "Lora",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                  fontFeatureSettings: `'clig' off, 'liga' off`,
                                }}
                                onClick={() =>
                                  handleUnblock(item.id, item.email)
                                }
                              >
                                Unblock
                              </IconButton>
                            }
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={item.profile_url}
                                alt="Profile Image"
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={item.name}
                              style={{
                                color: "var(--General-Dark, #222)",
                                fontFeatureSettings: "'clig' off, 'liga' off",
                                fontFamily: "Raleway, sans-serif",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: 700,
                                lineHeight: "30.919px",
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                )}
              </>
            ) : (
              <></>
            )}

            {/* : buttonValue == "3" ? (
              <>
                <Card
                  sx={{
                    position: "relative",
                    boxShadow: 3,
                    backgroundColor: "rgba(134, 56, 149, 0.05)",
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    m={5}
                    sx={{
                      color: "#000",
                      fontFamily: "Lora",
                      fontSize: "24px !important ",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                    }}
                  >
                    Simple Subscription Packages
                  </Typography>

                  <CardContent
                    sx={{
                      marginTop: "50px",
                    }}
                  >
                    <Grid container spacing={3} mt={2}>
                      {membershipList.map((membership, index) => (
                        <Grid
                          key={membership.id}
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          xl={4}
                          style={{
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          <Card
                            sx={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              borderTop: `4px solid ${membership.color_code}`,
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                              backgroundColor: "rgba(160, 195, 255, 0.03)",
                            }}
                          >
                            <CardContent
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  color: cardColors[index % cardColors.length],
                                  fontFamily: "Lora",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "28px",
                                  textTransform: "none !important",
                                  marginBottom: "1rem",
                                }}
                              >
                                {parseInt(membership.duration) === 1
                                  ? `${membership.duration} Month`
                                  : `${membership.duration} Months`}
                              </div>

                              <Typography
                                variant="h4"
                                component="div"
                                mb={2}
                                sx={{
                                  color: cardColors[index % cardColors.length],
                                  fontFamily: "Lora",
                                  fontSize: "42px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                }}
                              >
                                ${membership.price}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#222",
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  // fontWeight: "700",
                                  lineHeight: "28px",
                                  textTransform: "none !important",
                                }}
                                variant="h5"
                                gutterBottom
                                mb={1}
                              >
                                {membership.name}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#222",
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "28px",
                                  textTransform: "none !important",
                                }}
                                variant="h5"
                                gutterBottom
                                mb={1}
                              >
                                {membership.short_description}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#222222",
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "18px",
                                  textTransform: "none !important",
                                }}
                                mb={1}
                              >
                                {membership.description}
                              </Typography>
                            </CardContent>
                            <CardActions
                              sx={{
                                justifyContent: "center", // Center the content horizontally
                                alignItems: "center", // Center the content vertically
                              }}
                            >
                              <Button
                                // onClick={() =>
                                //   handleOpenPaymentDialog(membership.id)
                                // }
                                onClick={() =>
                                  handleConvergePayment(
                                    membership.id,
                                    profileId
                                  )
                                }
                                sx={{
                                  padding: "10px 20px",
                                  border: `1px solid ${membership.color_code}`,
                                  borderRadius: "5px",
                                  color: "#FFFFFF",
                                  backgroundColor: `${membership.color_code} !important`,
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  textTransform: "none !important",
                                }}
                                variant="outlined"
                              >
                                Get Subscription
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                    {membershipId && (
                      <Elements stripe={stripePromise}>
                        <CheckoutForm
                          handlePayment={() => handleOpenDialog(membershipId)}
                          profileId={profileId}
                          membershipId={membershipId}
                          open={openDialog}
                          onClose={handleCloseDialog}
                        />
                      </Elements>
                    )}
                  </CardContent>
                </Card>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div
                    style={{
                      maxWidth: 900,
                      maxHeight: 150,
                      margin: "auto",
                      // backgroundColor: "#F4F4F4",
                      padding: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFamily: "Lora",
                        fontSize: "19px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "28.938px",
                        fontFeatureSettings: `'clig' off, 'liga' off`,
                        textAlign: "center", // Added textAlign="center"
                        "@media (max-width: 600px)": {
                          // Adjust font size for small screens
                          fontSize: "14px",
                        },
                      }}
                    >
                      Ready to enhance your social life, expand their network,
                      and create memorable experiences?
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFamily: "Lora",
                        fontSize: "17px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "28.938px",
                        fontFeatureSettings: `'clig' off, 'liga' off`,
                        textAlign: "center", // Added textAlign="center"
                        "@media (max-width: 600px)": {
                          // Adjust font size for small screens
                          fontSize: "12px",
                        },
                      }}
                    >
                      Choose the plan that best fits your lifestyle and start
                      exploring a world meaningful connections.
                    </Typography>
                  </div>

                  <Typography
                    sx={{
                      color: "var(--General-Dark, #222)",

                      fontFamily: "Lora",
                      fontSize: "28px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "28.938px",
                      fontFeatureSettings: `'clig' off, 'liga' off`,
                      marginTop: "40px",
                    }}
                  >
                    Subscription Features
                  </Typography>
                  <ul
                    style={{
                      marginLeft: "54px",
                      marginTop: "43px",
                      color: "#202226",
                      listStyle: "unset",
                      fontFamily: "Lora",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "31px",
                    }}
                  >
                    <li>Create user profile</li>
                    <li>Respond to any posted activity and event</li>
                    <li>
                      Send request that a profile be ghost-tagged in the
                      unlikely scenario that you are ghosted at an event
                    </li>
                    <li>Post activities or events</li>
                    <li>Community of real people </li>
                    <li>Instant chat feature</li>
                    <li>
                      Share your activity or event directly to another profile
                    </li>
                    <li>Tagging and search filters</li>
                    <li>Clean, uncomplicated web design</li>
                    <li>
                      Lower monthly cost for users who commit to a longer
                      subscription period
                    </li>
                    <li>Billing and membership management</li>
                    <li>
                      After free trial period has ended, subscription is
                      converted into paid subscription and auto-renews at the
                      end of each billing cycle
                    </li>
                    <li>
                      Changes to the subscription plan, features or cost will be
                      communicated to subscribers and/or members either by email
                      or posted to this site
                    </li>
                    <li>
                      Cancel or change your subscription anytime and still be
                      able to use your subscription for the time you've already
                      paid
                    </li>
                    <li>Easy cancellation</li>
                  </ul>
                  <Typography
                    sx={{
                      marginTop: "48px",
                      color: "#F4460F",
                      fontFamily: "Lora",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "31px",
                    }}
                  >
                    *All currency is in Canadian dollars.
                  </Typography>
                  <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sx={{
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CardActions>
                      <Button
                        sx={{
                          width: "100%",
                          maxWidth: 200,
                          border: "0.762px solid #FD5151",
                          borderRadius: "4px",

                          "&:hover": {
                            border: "1px solid #863895",
                            color: "#863895",
                          },
                          color: "#FD5151",
                          fontFamily: "Lora",
                          fontFeatureSettings: `'clig' off, 'liga' off`,
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "normal",
                          textTransform: "none !important",
                          display: "inline-flex",
                          padding: "13.708px 25.892px",
                          alignItems: "center",
                          gap: "9.138px",
                        }}
                        variant="outlined"
                      >
                        Cancel Subscription
                      </Button>
                    </CardActions>
                    <CardActions>
                      <Button
                        sx={{
                          border: "1px solid #863895",
                          backgroundColor: "#863895",
                          borderRadius: "3.808px",
                          "&:hover": {
                            backgroundColor: "#863895",
                            color: "#FFF",
                          },
                          color: "#FFF",
                          fontFamily: "Lora",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "normal",
                          textTransform: "none !important",
                          fontFeatureSettings: `'clig' off, 'liga' off`,
                          display: "inline-flex",
                          padding: "13.708px 25.892px",
                          alignItems: "center",
                          gap: "9.138px",
                        }}
                        variant="contained"
                      >
                        Get Subscription
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )} */}
          </Grid>
        </Grid>

        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          <Footer />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </>
  );
};

export default ProfilePage;
