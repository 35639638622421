import React, { useEffect, useState } from "react";
import { Text } from "../Text";
import Header from "../Header/Header";
import { auth, firestore } from "../../Firebase";

import { useNavigate } from "react-router-dom";
import Header2 from "../Header/Header2";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TransgenderIcon from "@mui/icons-material/Transgender";
import DryCleaningIcon from "@mui/icons-material/DryCleaning";
import BentoIcon from "@mui/icons-material/Bento";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import round_add from "../../assets/duotone.png";
import svgrepo from "../../assets/svgrepo-com.png";
import Tab from "@mui/material/Tab";
import axios from "axios";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Footer from "../FooterPages/Footer";
import { getTokenFromLocalStorage } from "../authService";
import { DrawerWithNav } from "../../pages/Chat/components/DrawerWithNav.jsx";
import { Link } from "react-router-dom";
import noEvent from "../../assets/MyEvents.png";

import { useUserAuth } from "../../context/userAuthContext.js";
import "../../fonts.css";
import calenderImg from "../../assets/ph_calendar-duotone.png";
import timeImg from "../../assets/ph_clock.png";
import ageImg from "../../assets/Age.png";
import genderImg from "../../assets/icons8_gender.png";
import dressImg from "../../assets/dresscode 1.png";
import locationImg from "../../assets/location2.png";
import fareImg from "../../assets/what i will Cover.png";
import descImg from "../../assets/Event_desc.png";
import { MoonLoader } from "react-spinners";
import acceptedImg from "../../assets/Accepted Event.png";
import { DialogTitle, Modal } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import guestsImg from "../.././assets/guests_logo.png";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";

import { db } from "../../Firebase.jsx";

const headingFont = createTheme({
  typography: {
    h6: {
      color: "rgba(13, 15, 19, 0.98)",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "21px",
    },
    subtitle1: {
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Lora",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});
const listtextStyle = {
  color: "#202226",
  fontFamily: "Lora",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingLeft: "10px",
};
const ResponsiveTab = styled(Tab)`
  width: 50%;
  font-size: 0.82em;
  color: #1a1a1a;
  font-weight: bold;

  @media (min-width: 600px) {
    font-size: 1.2em;
  }

  @media (min-width: 960px) {
    font-size: 0.73em;
  }
`;
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const HomePage = () => {
  const [value, setValue] = React.useState("1");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [roomId, setRoomId] = React.useState("");
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedPerson, setSelectedPerson] = React.useState([]);
  const [owner, setOwner] = React.useState([]);
  const [currentUserOnlineStatus, setCurrentUserOnlineStatus] = useState(null);
  const [selectedPersonOnlineStatus, setSelectedPersonOnlineStatus] =
    useState(null);
  const [selectedPersonLastSeen, setSelectedPersonLastSeen] = useState(null);
  const [messages, setMessages] = React.useState([]);
  const [message, setMessage] = React.useState("");
  // const { error, SendDataToFirebase, currentuser } = useUserAuth();
  const [blocked, setBlocked] = useState(false);
  const [chat, setChat] = React.useState([]);
  const [directionUserId, setDirectionUserId] = useState(null);
  const [blockedBySomeone, setBlockedBySomeone] = useState(false);
  const [membershipStatus, setMembershipStatus] = React.useState(1);
  const [renewMembershipStatus, setRenewMembershipStatus] = React.useState(1);
  const [filteredEvents, setFilteredEvents] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [renewMembershipPopup, setRenewMembershipPopup] = React.useState(false);

  const [isHoveredArray, setIsHoveredArray] = useState(
    Array(events.length).fill(false)
  );
  const [chatCount, setChatCount] = useState("0");
  const { user } = useUserAuth();
  const [openPopup, setOpenPopup] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [profileImageData, setProfileImageData] = useState({});
  const [images, setImages] = useState([]);

  const handleImageClick = (event) => {
    setProfileImageData(event);
    setImages([event.profile_img, event.image_2, event.image_3, event.image_4]); // Add all images to the images state
    setCurrentImageIndex(0); // Reset to the first image
    setOpenPopup(true);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const getGenderText = (genderId) => {
    switch (parseInt(genderId, 10)) {
      case 1:
        return "Male";
      case 2:
        return "Female";
      case 3:
        return "Other";
      case 4:
        return "All";
      default:
        return "Unknown";
    }
  };

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHover = (index) => {
    setIsHoveredArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = true;
      return newArray;
    });
  };

  const handleLeave = (index) => {
    setIsHoveredArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = false;
      return newArray;
    });
  };

  const buttonStyle = {
    border: "1px solid #863895",
    color: "#863895",
    fontFamily: "Lora",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "none",
    position: "relative",
    width: "142px",
    height: "34px",
    borderRadius: "4px",
  };

  const popupStyle = {
    width: "178px",
    height: "68px",
    borderRadius: "6px",
    background: "#fff",
    boxShadow: "0px 1px 6px 2px rgba(0, 0, 0, 0.15)", // Add the drop shadow here
    display: isHoveredArray ? "block" : "none",
    position: "absolute",
    top: "-80px", // Adjust the top position as needed
    left: "0",
    padding: "10px",
    fontSize: "12.433px",
    color: "#5B5B5B",
    fontFamily: "Lora",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "21px",
  };

  const headingFont2 = createTheme({
    typography: {
      h6: {
        fontFamily: "Lora",
        fontStyle: "normal",
        lineHeight: "normal",
        marginLeft: "14px",
      },
    },
  });
  const imgStyle = {
    width: "20px",
    height: "20px",
    marginLeft: "12px",
    marginTop: "-18px", // Add bottom margin to adjust the position
  };

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };
  const handleClose = () => {
    setOpen(false);
  };
  const handleRenewMembership = () => {
    setRenewMembershipPopup(false);
    navigate("/membership");
  };

  const findBlockValue = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let nestedObject = obj[key];

        // Check if nestedObject has 'blocked' and 'isBlockedByMe' properties
        if (
          nestedObject.hasOwnProperty("blocked") &&
          nestedObject.hasOwnProperty("isBlockedByMe")
        ) {
          let blockedValue = nestedObject.blocked;
          let isBlockedByMeValue = nestedObject.isBlockedByMe;

          return blockedValue || isBlockedByMeValue;
        }
      }
    }
  };

  function getMessages(roomId) {
    return onSnapshot(
      query(
        collection(db, "chats", roomId, "messages"),
        orderBy("time", "asc")
      ),
      (querySnapshot) => {
        const messages = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setMessages(messages);
      }
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const apiUrl = "header";

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (
          !token ||
          (response.data.status === 401 && !response.data.success)
        ) {
          console.error(
            "Token not found in local storage or unauthorized API access"
          );
          console.log("Token:", token);
          console.log("Response:", response);
          // debugger
          if (response.data.status === 401 && !response.data.success) {
            // Navigate to the login page
            navigate("/login");
          }

          return; // Stop further execution if token is not present or unauthorized access
        }

        setMembershipStatus(response.data.data.membership_status);
        setRenewMembershipStatus(response.data.data.membership_status);

        if (response.status === 200 && response.data.success) {
          const responseData = response.data.data.user_id;

          setDirectionUserId(responseData);
        } else {
          console.error("Failed to get data:", response.data.message);
        }
      } catch (error) {
        console.error("Error while making the API request:", error.message);
      }
    };

    fetchData();
  }, []);

  const handleDirectionClick = (eventId, directionUserId, index) => {
    const token = getTokenFromLocalStorage();

    axios
      .post(
        "/check_event_direction",
        {
          event_id: eventId,
          user_id: directionUserId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.status === 200 && response.data.success) {
          const url = response.data.data.url;
          window.open(url, "_blank");
        } else if (response.data.status === 400) {
          // Show the popup for status 400
          // Assuming you have an array isHoveredArray and an element at the specified index
          // that determines whether the popup should be displayed or not
          isHoveredArray[index] = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMembership = async () => {
    const token = getTokenFromLocalStorage();

    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        "check_membership",
        { is_checked: "1" },
        { headers }
      );

      if (response.data.status === 200) {
        handleClose(); // Close the modal upon success
      }
    } catch (error) {
      toast.error("An error occurred while deleting your account");
      console.error("Error deleting account:", error);
    }
  };

  // UseEffect to update open state when membershipStatus changes
  React.useEffect(() => {
    if (membershipStatus === 0) {
      setOpen(true); // Open the modal if membershipStatus is 0
    } else {
      setOpen(false); // Close the modal otherwise
    }
  }, [membershipStatus]);

  React.useEffect(() => {
    if (renewMembershipStatus === 2) {
      setRenewMembershipPopup(true); // Open the modal if membershipStatus is 0
    } else {
      setRenewMembershipPopup(false); // Close the modal otherwise
    }
  }, [renewMembershipStatus]);

  const handleButtonClick = async (event) => {
    try {
      if (event) {
        const eventId = event.id;
        if (disabledButtons.includes(eventId)) {
          // console.log("Button already disabled for Event ID:", eventId);
          return;
        }

        const token = getTokenFromLocalStorage();
        // console.log("Event ID:", eventId);

        const response = await axios.get(`sendRequest/${eventId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          console.log("Request sent successfully");
          setDisabledButtons((prevDisabledButtons) => [
            ...prevDisabledButtons,
            eventId,
          ]);

          // Reload the page upon successful request
          window.location.reload();
        } else {
          console.error(
            "Error sending request:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error("Event object is undefined or null.");
      }
    } catch (error) {
      console.error("Error sending request:", error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        if (!token) {
          console.error("Token not found in local storage");
          return;
        }

        let filteredEvents = [];

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              const response = await axios.get("alleventlist", {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });

              let eventListData =
                response.data &&
                response.data.data &&
                Array.isArray(response.data.data)
                  ? response.data.data
                  : [];

              eventListData = eventListData.map((event) => {
                const data = { ...event };

                const startDate = new Date(data.starttime);
                const endDate = new Date(data.endtime);
                const startDateOptions = {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                data.startDateComponent = startDate.toLocaleDateString(
                  "en-US",
                  startDateOptions
                );

                const startTimeOptions = {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                };
                data.startTimeComponent = startDate.toLocaleTimeString(
                  "en-US",
                  startTimeOptions
                );

                const endDateOptions = {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                data.endDateComponent = endDate.toLocaleDateString(
                  "en-US",
                  endDateOptions
                );
                const endTimeOptions = {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                };
                data.endTimeComponent = endDate.toLocaleTimeString(
                  "en-US",
                  endTimeOptions
                );

                return data;
              });
              // Filter events within 50km range
              filteredEvents = eventListData.filter((event) => {
                const eventLatitude = parseFloat(event.latitude);
                const eventLongitude = parseFloat(event.longitude);
                const distance = calculateDistance(
                  latitude,
                  longitude,
                  eventLatitude,
                  eventLongitude
                );
                return distance <= 50;
              });

              const modifiedEvents = filteredEvents.map((event) => {
                const data = { ...event };

                const startDate = new Date(data.starttime);
                const endDate = new Date(data.endtime);
                const startDateOptions = {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                data.startDateComponent = startDate.toLocaleDateString(
                  "en-US",
                  startDateOptions
                );

                const startTimeOptions = {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                };
                data.startTimeComponent = startDate.toLocaleTimeString(
                  "en-US",
                  startTimeOptions
                );

                const endDateOptions = {
                  weekday: "short",
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };
                data.endDateComponent = endDate.toLocaleDateString(
                  "en-US",
                  endDateOptions
                );
                const endTimeOptions = {
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                };
                data.endTimeComponent = endDate.toLocaleTimeString(
                  "en-US",
                  endTimeOptions
                );

                return data;
              });

              if (modifiedEvents.length === 0) {
                setFilteredEvents(eventListData);
                console.log("Filtered Events Length:", eventListData.length);
              } else {
                setFilteredEvents(modifiedEvents);
                console.log("Filtered Events Length:", modifiedEvents.length);
              }
              setLoading(false);
            },
            (error) => {
              console.error("Error getting location:", error);
              // Proceed without filtering events based on location
              fetchAllEvents();
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          // Proceed without filtering events based on location
          fetchAllEvents();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const fetchAllEvents = async () => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await axios.get("alleventlist", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let eventListData =
        response.data && response.data.data && Array.isArray(response.data.data)
          ? response.data.data
          : [];

      // Add date and time formatting to eventListData
      eventListData = eventListData.map((event) => {
        const data = { ...event };

        const startDate = new Date(data.starttime);
        const endDate = new Date(data.endtime);
        const startDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.startDateComponent = startDate.toLocaleDateString(
          "en-US",
          startDateOptions
        );

        const startTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.startTimeComponent = startDate.toLocaleTimeString(
          "en-US",
          startTimeOptions
        );

        const endDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.endDateComponent = endDate.toLocaleDateString(
          "en-US",
          endDateOptions
        );
        const endTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.endTimeComponent = endDate.toLocaleTimeString(
          "en-US",
          endTimeOptions
        );

        return data;
      });

      const modifiedEvents = eventListData.map((event) => {
        const data = { ...event };

        const startDate = new Date(data.starttime);
        const endDate = new Date(data.endtime);
        const startDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.startDateComponent = startDate.toLocaleDateString(
          "en-US",
          startDateOptions
        );

        const startTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.startTimeComponent = startDate.toLocaleTimeString(
          "en-US",
          startTimeOptions
        );

        const endDateOptions = {
          weekday: "short",
          day: "numeric",
          month: "short",
          year: "numeric",
        };
        data.endDateComponent = endDate.toLocaleDateString(
          "en-US",
          endDateOptions
        );
        const endTimeOptions = {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        data.endTimeComponent = endDate.toLocaleTimeString(
          "en-US",
          endTimeOptions
        );

        return data;
      });

      setFilteredEvents(modifiedEvents);

      // console.log("Filtered Events Length:", modifiedEvents.length);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
      setLoading(false);
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  const handlePersonChat = async (person) => {
    const myId = user.uid;
    const otherPersonId = person.data.uid;

    if (myId && otherPersonId) {
      const roomId = [myId, otherPersonId].sort().join("");
      const chatsRef = doc(db, "chats", roomId);

      setRoomId(roomId);
      const isBlocked = findBlockValue(person.data.roomDetail);
      // debugger
      setSelectedPerson({ ...person, blocked: isBlocked });
      // setOwner(myId);
      getMessages(roomId);

      const docSnap = await getDoc(chatsRef);
      const isBlockedByMe = docSnap?.data()?.[myId]?.blocked;
      const isBlockedForMe = docSnap?.data()?.[otherPersonId]?.blocked;

      setBlocked(isBlockedForMe);
      setOwner(isBlockedByMe);
      console.log(`My blocked status from Firestore: ${isBlockedByMe}`);

      if (docSnap.exists()) {
        updateDoc(chatsRef, {
          [myId]: {
            ...docSnap.data()[myId],
            unread_count: 0,
          },
        });
      } else {
        await setDoc(chatsRef, {
          [myId]: {
            ...(docSnap.data() || {}),
            unread_count: 0,
          },
        });
      }

      setTimeout(() => {
        scrollToBottom();
      }, 500);

      if (!docSnap.exists()) {
        await setDoc(chatsRef, {
          [myId]: {
            unread_count: 0,
            blocked: false,
          },
        });
      } else {
        const isBlocked = docSnap.data()?.[otherPersonId]?.blocked || false;

        console.log(`Blocked status from Firestore: ${isBlocked}`);

        setSelectedPerson((prevPerson) => ({
          ...prevPerson,
          blocked: isBlocked,
        }));
      }
    }
  };
  const fetchAllAcceptedRequestData = async () => {
    try {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "allAcceptConsiderRequest";

      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("API Response:", response.data);
      return response.data;
      // setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateUnreadCount = async () => {
    const chats_ref = doc(db, "chats", roomId);
    const partnerId = selectedPerson.data.uid;
    const docSnap = await getDoc(chats_ref);

    if (docSnap.exists()) {
      let roomDetail = docSnap.data();
      let partnerUnreadCount =
        roomDetail &&
        roomDetail[partnerId] &&
        roomDetail[partnerId].unread_count;
      updateDoc(chats_ref, {
        [partnerId]: {
          unread_count: partnerUnreadCount ? partnerUnreadCount + 1 : 1,
        },
      }).then(() => {});
    } else {
      await setDoc(chats_ref, {
        [partnerId]: {
          unread_count: 1,
        },
      }).then(() => {
        console.log("unread count added");
      });
    }
  };
  const scrollToBottom = () => {
    var objDiv = document.getElementById("boxData");
    if (objDiv) {
      objDiv.scrollTop = objDiv?.scrollHeight;
    }
  };
  function capitalizeFirstLetter(str) {
    if (typeof str === "string" && str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return str;
  }
  const avatarContent = selectedPerson?.data?.profile_pictures ? (
    <Avatar
      src={selectedPerson.data.profile_pictures}
      aria-label="profile-image"
    />
  ) : (
    <Avatar aria-label="profile-icon">
      {selectedPerson?.data?.displayName
        ? capitalizeFirstLetter(selectedPerson.data.displayName.charAt(0))
        : ""}
    </Avatar>
  );
  // console.log("Renew Membership Status:", renewMembershipStatus);
  return (
    <>
      {/* gap changed on 28jan*/}
      <div
        className="flex flex-col font-lato gap-[28px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />
        <Grid container spacing={1} sx={{ width: "100%" }}>
          {membershipStatus === 0 && (
            <Modal
              open={open}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Card content */}
              <Card
                sx={{
                  width: "500px", // Dimension of the card
                  height: "500px", // Dimension of the card
                  textAlign: "center",
                  borderRadius: "10px", // Square-shaped modal
                  overflow: "hidden", // Hide any overflow
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CardContent>
                  <img
                    src={acceptedImg} // Your PNG image
                    alt="Your Image"
                    style={{
                      width: "100%", // Make the image responsive
                      height: "auto", // Maintain aspect ratio
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "#2F2F2F",
                      textAlign: "center",
                      fontFamily: "Lora",
                      fontSize: "20px", // Initial font size
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      marginTop: 2,
                    }}
                  >
                    Thank you for signing up! Your no-cost, 3-month membership
                    begins today.
                  </Typography>
                  <Button
                    sx={{
                      marginTop: 3,
                      backgroundColor: "#863895",
                      "&:hover": {
                        backgroundColor: "#863895",
                      },
                    }}
                    variant="contained"
                    onClick={() => handleMembership()}
                  >
                    Close
                  </Button>
                </CardContent>
              </Card>
            </Modal>
          )}

          {renewMembershipStatus === 2 && (
            <Modal
              open={renewMembershipPopup}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* Card content */}
              <Card
                sx={{
                  width: "500px", // Dimension of the card
                  height: "450px", // Dimension of the card
                  textAlign: "center",
                  borderRadius: "10px", // Square-shaped modal
                  overflow: "hidden", // Hide any overflow
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/images/verify.png"
                    alt="Your Image"
                    style={{
                      maxWidth: "100%", // Maximum width of the image
                      maxHeight: "400px", // Maximum height of the image
                      width: "auto", // Maintain aspect ratio
                      height: "auto", // Maintain aspect ratio
                      margin: "auto", // Center the image horizontally
                    }}
                  />

                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      color: "#2F2F2F",
                      textAlign: "center",
                      fontFamily: "Lora",
                      fontSize: "20px", // Initial font size
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal",
                      marginTop: 2,
                    }}
                  >
                    Oh no! Your 3 month free membership has expired. You can
                    choose from 3 subscription plans to continue using this
                    website.
                  </Typography>
                  <Button
                    sx={{
                      marginTop: 3,
                      backgroundColor: "#863895",
                      "&:hover": {
                        backgroundColor: "#863895",
                      },
                    }}
                    variant="contained"
                    // onClick={() => handleRenewMembership()}
                    onClick={handleRenewMembership}
                  >
                    Get Subscription
                  </Button>
                </CardContent>
              </Card>
            </Modal>
          )}
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} mt={2}>
            <ThemeProvider theme={headingFont2}>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1A1A1A",
                    fontSize: "32px",
                    fontWeight: 700,
                    mb: 2,
                  }}
                >
                  Chat
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#09090B",
                    fontFamily: "Lora",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    mt: 1,
                    ml: 1,
                  }}
                >
                  ({chatCount})
                </Typography>
              </div>
            </ThemeProvider>
            <Card
              sx={{
                width: "100%",
                borderRadius: "20px",
                margin: "auto",
                backgroundColor: "rgba(160, 195, 255, 0.06)",
              }}
            >
              <CardContent>
                <Link to="/chat">
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <ResponsiveTab
                          // sx={{ width: "50%" }}
                          label="New Events"
                          value="1"
                        />
                        <ResponsiveTab label="Completed Events" value="2" />
                      </TabList>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TabPanel value="1" style={{ padding: 0 }}>
                          {value === "1" && (
                            <DrawerWithNav
                              handlePersonChat={handlePersonChat}
                              setChatCount={setChatCount}
                            />
                          )}
                        </TabPanel>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TabPanel value="2" style={{ padding: 0 }}>
                          <DrawerWithNav
                            handlePersonChat={handlePersonChat}
                            completedEvent={true}
                            setChatCount={setChatCount}
                          />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </TabContext>
                </Link>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} mt={2}>
            <ThemeProvider theme={headingFont2}>
              <div style={{ display: "flex" }}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1A1A1A",
                    fontSize: "32px",
                    fontWeight: 700,
                    mb: 2,
                  }}
                >
                  Events
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#09090B",
                    fontFamily: "Lora",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    mt: 1,
                    ml: 1,
                  }}
                >
                  ({filteredEvents.length || events.length})
                </Typography>
              </div>
            </ThemeProvider>

            <Box>
              <Grid
                container
                s
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                sx={{ width: "100%" }}
              >
                {headerLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 9999,
                    }}
                  >
                    <MoonLoader color="#863895" size={80} />
                  </div>
                )}
                {filteredEvents.length > 0 ? (
                  filteredEvents.map((event, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                      key={event.id}
                    >
                      <Card
                        sx={{
                          width: "100%",
                          borderRadius: "20px",
                          margin: "auto",
                          marginBottom: index < events.length - 1 ? "20px" : 0,
                          minHeight: "558px",
                          maxHeight: "558px",
                          overflow: "hidden",
                          backgroundColor: "rgba(160, 195, 255, 0.06)",
                          // Media query for responsiveness
                          "@media screen and (max-width: 768px)": {
                            minHeight: "300px",
                            maxHeight: "600px",
                          },
                        }}
                      >
                        <CardContent>
                          <div
                            style={{
                              border: "0.956px solid #E1E3E6",
                              minHeight: "500px",
                              maxHeight: "500px",
                              borderRadius: "7.651px",
                              overflowY: "auto",
                              scrollbarWidth: "thin", // For Firefox
                              scrollbarColor: "#86389596 #E1E3E696", // Set scroll color with alpha (transparency)
                              // WebKit-based browsers (Chrome, Safari)
                              WebkitOverflowScrolling: "touch",
                              "&::-webkit-scrollbar": {
                                width: "4px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                background: "#86389596", // Lighter color with transparency
                                borderRadius: "4px",
                              },
                              // Firefox
                              "& scrollbar-width": "thin",
                              "& scrollbar-color": "#86389596 #E1E3E696", // Set scroll color with alpha (transparency)
                            }}
                          >
                            {/* <Stack m={1}>
                            <ThemeProvider theme={headingFont}>
                              <Typography variant="h6" mb={1}>
                                {event.event_name}
                              </Typography>
                            </ThemeProvider>

                            <ThemeProvider theme={headingFont}>
                              <Typography variant="subtitle1">
                                {event.description}
                              </Typography>
                            </ThemeProvider>
                          </Stack> */}
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              spacing={1}
                              sx={{ padding: "10px" }}
                            >
                              <Stack direction="column" alignItems="flex-start">
                                <Typography variant="h6" mb={1}>
                                  {event.event_name}
                                </Typography>

                                <ThemeProvider theme={headingFont}>
                                  <Typography variant="subtitle1">
                                    {event.description}
                                  </Typography>
                                </ThemeProvider>
                              </Stack>

                              <Stack direction="column" alignItems="flex-end">
                                <Avatar
                                  alt="Profile Image"
                                  src={event.profile_img}
                                  onClick={() => handleImageClick(event)}
                                />
                                <Typography
                                  variant="subtitle1"
                                  sx={{
                                    color: "#09090B",
                                    fontFamily: "Lora",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    mt: 1,
                                  }}
                                >
                                  {event.user_name}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Divider sx={{ mx: 2, my: 1.5 }} />
                            <List aria-label="contacts">
                              <ListItem>
                                <img
                                  src={calenderImg}
                                  alt="EventImage"
                                  style={{
                                    width: "24px",
                                    height: "25px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {event.startDateComponent} To{" "}
                                    {event.endDateComponent}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <img
                                  src={timeImg}
                                  alt="EventImage"
                                  style={{
                                    width: "24px",
                                    height: "25px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {event.startTimeComponent} To{" "}
                                    {event.endTimeComponent}
                                  </Typography>
                                </ListItemText>
                              </ListItem>

                              <ListItem>
                                <img
                                  src={ageImg}
                                  alt="EventImage"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {event.age_from} To {event.age_to}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <img
                                  src={genderImg}
                                  alt="EventImage"
                                  style={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {(() => {
                                      const genderId = parseInt(
                                        event.gender,
                                        10
                                      );

                                      switch (genderId) {
                                        case 1:
                                          return "Male";
                                        case 2:
                                          return "Female";
                                        case 3:
                                          return "";
                                        case 4:
                                          return "All";
                                        default:
                                          return "Unknown";
                                      }
                                    })()}{" "}
                                    {""} {event.gender_description}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              <ListItem>
                                <img
                                  src={dressImg}
                                  alt="EventImage"
                                  style={{
                                    width: "24px",
                                    height: "25px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {event.dresscode}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              {event.no_of_invities !== null && (
                                <ListItem>
                                  <img
                                    src={guestsImg}
                                    alt="EventImage"
                                    style={{
                                      width: "25px",
                                      height: "22px",
                                    }}
                                  />
                                  <ListItemText>
                                    <Typography
                                      style={listtextStyle}
                                      variant="subtitle2"
                                    >
                                      {event.no_of_invities}
                                      {"\u00A0"}Guests
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              )}
                              <ListItem>
                                <img
                                  src={locationImg}
                                  alt="EventImage"
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                <ListItemText>
                                  <Typography
                                    style={listtextStyle}
                                    variant="subtitle2"
                                  >
                                    {event.location}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                              {event.eventfeature && (
                                <ListItem>
                                  <img
                                    src={fareImg}
                                    alt="EventImage"
                                    style={{
                                      width: "24px",
                                      height: "25px",
                                    }}
                                  />
                                  <ListItemText>
                                    <Typography
                                      style={listtextStyle}
                                      variant="subtitle2"
                                    >
                                      {event.eventfeature}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              )}
                              {event.activity_details && (
                                <ListItem>
                                  <img
                                    src={descImg}
                                    alt="EventImage"
                                    style={{
                                      width: "24px",
                                      height: "25px",
                                    }}
                                  />
                                  <ListItemText>
                                    <Typography
                                      style={listtextStyle}
                                      variant="subtitle2"
                                    >
                                      {event.activity_details}
                                    </Typography>
                                  </ListItemText>
                                </ListItem>
                              )}
                            </List>
                            <CardActions
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Stack direction="row" spacing={2}>
                                <div style={{ position: "relative" }}>
                                  <button
                                    style={buttonStyle}
                                    onMouseEnter={() => handleHover(index)}
                                    onMouseLeave={() => handleLeave(index)}
                                    onClick={() =>
                                      handleDirectionClick(
                                        event.id,
                                        directionUserId
                                      )
                                    }
                                  >
                                    Directions
                                    <img
                                      src={round_add}
                                      alt="logo"
                                      style={imgStyle}
                                    />
                                  </button>
                                  {event.is_accepted === 0 &&
                                    isHoveredArray[index] && (
                                      <div
                                        style={{
                                          ...popupStyle,
                                          display: isHoveredArray[index]
                                            ? "block"
                                            : "none",
                                        }}
                                      >
                                        Exact location will be provided to the
                                        attendee.
                                      </div>
                                    )}
                                </div>

                                <Button
                                  variant="contained"
                                  sx={{
                                    border: "1px solid #863895",
                                    backgroundColor: "#863895",
                                    gap: "5px",
                                    borderRadius: "6px 0px",
                                    "&:hover": {
                                      backgroundColor: "#863895",
                                      color: "#FFF",
                                    },
                                    color: "#FFF",
                                    fontFamily: "Lora",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    textTransform: "none !important",
                                    fontFeatureSettings: `'clig' off, 'liga' off`,
                                  }}
                                  startIcon={
                                    <img
                                      src={svgrepo}
                                      alt="logo"
                                      style={{ width: "22px", height: "22px" }}
                                    />
                                  }
                                  onClick={() => handleButtonClick(event)}
                                  disabled={event.is_send === 1}
                                >
                                  I'm Interested
                                </Button>
                              </Stack>
                            </CardActions>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Card
                    sx={{
                      width: "100%",
                      maxWidth: 1000,
                      textAlign: "center",
                      height: "auto",
                      margin: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                      backgroundColor: "rgba(160, 195, 255, 0.03)",
                    }}
                  >
                    <img
                      src={noEvent}
                      alt="Mobile"
                      style={{
                        width: "35%", // Initial width for larger screens
                        height: "auto", // Maintain aspect ratio
                        "@media screen and (max-width: 600px)": {
                          width: "50% !important",
                        },
                      }}
                    />

                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          marginTop: 2,
                          color: "#2F2F2F",
                          textAlign: "center",
                          fontFamily: "Lora",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",
                          "@media screen and (max-width: 600px)": {
                            fontSize: "24px", // Adjust the font size for smaller screens
                          },
                          "@media screen and (max-width: 400px)": {
                            fontSize: "16px", // Further adjust the font size for even smaller screens
                          },
                        }}
                      >
                        No events currently listed. Feel inspired and create
                        your own event!
                      </Typography>
                      <Link
                        to="/create_event"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          sx={{
                            marginTop: 2,
                            backgroundColor: "#863895",
                            "&:hover": {
                              backgroundColor: "#863895",
                            },
                            "@media screen and (max-width: 600px)": {
                              width: "60%", // Adjust the button width for smaller screens
                              fontSize: "9px", // Adjust the font size for smaller screens
                            },
                          }}
                          variant="contained"
                        >
                          Create Event
                        </Button>
                      </Link>
                    </CardContent>
                  </Card>
                )}
                <Dialog
                  open={openPopup}
                  onClose={handleClosePopup}
                  fullScreen
                  sx={{ "& .MuiDialog-paper": { overflow: "hidden" } }}
                >
                  <DialogTitle>
                    <IconButton
                      onClick={handleClosePopup}
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        color: "#863895",
                        border: "2px solid #863895",
                        borderRadius: "50%",
                        padding: "8px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Lora",
                        fontSize: {
                          xs: "16px",
                          md: "24px",
                        },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "43.939px",
                      }}
                    >
                      {profileImageData.user_name}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Lora",
                        fontSize: {
                          xs: "16px",
                          md: "24px",
                        },
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "43.939px",
                      }}
                    >
                      {profileImageData.address}
                    </Typography>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        color: "var(--General-Dark, #222)",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "Lora",
                        fontSize: {
                          xs: "14px",
                          md: "20px",
                        },
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "43.939px",
                      }}
                    >
                      {getGenderText(profileImageData.user_gender)}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Card
                      sx={{
                        textAlign: "center",
                        padding: "16px",
                        cursor: "auto",
                        position: "relative",
                      }}
                    >
                      <CardContent></CardContent>
                      <div
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img
                          src={images[currentImageIndex]}
                          alt={`Image ${currentImageIndex + 1}`}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "70vh",
                            margin: "auto",
                          }}
                        />
                        <IconButton
                          onClick={handlePrev}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "15%",
                            transform: "translate(-100%, -50%)",
                            color: currentImageIndex === 0 ? "#ccc" : "#863895",
                            borderColor:
                              currentImageIndex === 0 ? "#ccc" : "#863895",
                          }}
                          disabled={currentImageIndex === 0}
                        >
                          <ChevronLeftIcon
                            fontSize="large"
                            sx={{
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "8px",
                              backgroundColor: "#fff",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={handleNext}
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "15%",
                            transform: "translate(100%, -50%)",
                            color:
                              currentImageIndex === images.length - 1
                                ? "#ccc"
                                : "#863895",
                            borderColor:
                              currentImageIndex === images.length - 1
                                ? "#ccc"
                                : "#863895",
                          }}
                          disabled={currentImageIndex === images.length - 1}
                        >
                          <ChevronRightIcon
                            fontSize="large"
                            sx={{
                              border: "2px solid",
                              borderRadius: "50%",
                              padding: "8px",
                              backgroundColor: "#fff",
                            }}
                          />
                        </IconButton>
                      </div>
                    </Card>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
          <Footer />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
      </div>
    </>
  );
};

export default HomePage;
