import React, { useState } from "react";
import imagefrm from "../assets/image 1.png";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Header2 from "./Header/Header2";
import Footer from "./FooterPages/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Button } from "@mui/material";
import axios from "axios";
import "../fonts.css";
import { getTokenFromLocalStorage } from "./authService.js";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MoonLoader } from "react-spinners";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const headingFont = createTheme({
  typography: {
    h4: {
      color: "#FF3932;",
      fontFamily: "Lora",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h6: {
      color: "#5A5A5A",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "#5A5A5A",
      fontFamily: "Lora",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    body2: {
      width: "85px",
      color: "#A3A3A3",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
      marginTop: "5px",
      marginLeft: "5px",
    },
  },
});

const CreateEventOnePage = () => {
  const textFieldMaxWidth = "400px";
  const borderColor = "#001aff";

  const [selectedGender, setSelectedGender] = React.useState("");
  const [isTextFieldVisible, setTextFieldVisible] = React.useState(false);
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [error, setError] = React.useState("");

  const [formData, setFormData] = React.useState({
    eventName: "",
    eventDescription: "",
    fromDate: "",
    toDate: "",
    fromTime: "",
    toTime: "",
    age_from: "",
    age_to: "",
    gender: "",
    discloseGender: "",
    dressCode: "",
    location: "",
    coveringCost: "",
    activityDetails: "",
    genderDecription: "",
    no_of_invitees: "",
  });

  const [locationCoords, setLocationCoords] = React.useState({
    latitude: "",
    longitude: "",
  });

  const handleGenderChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected Value:", selectedValue);
    setFormData((prevFormData) => ({
      ...prevFormData,

      gender: selectedValue,
    }));
    setTextFieldVisible(selectedValue === "3");
  };
  const navigate = useNavigate();

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  // const handleChange = (e, field) => {

  //   if (e && e.target) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [field]: e.target.value || "",
  //     }));
  //   } else {
  //     console.error("Invalid event or event target");
  //   }
  // };
  const handleChange = (e, field) => {
    const value = e && e.target ? e.target.value : e;

    if (field === "no_of_invitees") {
      if (!/^\d*$/.test(value)) {
        setError("Please enter a valid number");
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: "",
        }));
      } else {
        setError("");
        setFormData((prevFormData) => ({
          ...prevFormData,
          [field]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: value || "",
      }));
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  // const handleChange = (e, field) => {
  //   setFormData((prevFormData) => {
  //     const updatedFormData = { ...prevFormData, [field]: e.target.value };
  //     console.log("Updated FormData:", updatedFormData);
  //     return updatedFormData;
  //   });
  // };

  const [ageRange, setAgeRange] = React.useState([18, 30]);
  const minDistance = 1;

  const handleAgeChange = (event, newValue) => {
    // debugger
    // const { min, max } = event.currentTarget;
    setAgeRange(newValue);
    console.log("AgeRange:", ageRange);
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setLocationCoords({
        latitude: latLng.lat,
        longitude: latLng.lng,
      });

      setFormData({
        ...formData,
        location: address,
      });
    } catch (error) {
      console.error("Error selecting place:", error);
    }
  };

  const handleCreateEvent = async () => {
    try {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        alert("Please login first");
        navigate("/login");
        return;
      }

      const apiUrl = "addEditEvent";

      const genderIdMap = {
        Male: 1,
        Female: 2,
        Other: 3,
        All: 4,
      };

      const requestData = {
        id: "",
        event_name: formData.event_name,
        description: formData.description,
        // endtime: moment(`${formData.endDate} ${formData.endtime}`, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD HH:mm:ss"),
        // starttime: moment(`${formData.startDate} ${formData.starttime}`, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD HH:mm:ss"),
        endtime: `${formData.endDate} ${formData.endtime}`,
        starttime: `${formData.startDate} ${formData.starttime}`,
        age_from: ageRange[0],
        age_to: ageRange[1],
        gender: formData.gender,
        gender_description: formData.gender_description,
        dresscode: formData.dresscode,
        activity_details: formData.activity_details,
        eventfeature: formData.eventfeature,
        longitude: locationCoords.longitude,
        latitude: locationCoords.latitude,
        location: formData.location,
        no_of_invities: parseInt(formData.no_of_invitees),
      };

      // if (!requestData.latitude || !requestData.longitude) {
      //   // Show toast message if latitude or longitude is blank
      //   toast.error("Please choose an address from suggestions", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      //   });
      //   return; // Abort API call if latitude or longitude is blank
      // }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // console.log("Request Data:", requestData);
      // console.log("Config:", config);

      const response = await axios.post(apiUrl, requestData, config);

      // console.log("API Response:", response.data);

      if (response.status === 200 && response.data && response.data.success) {
        // Display the success toast first
        Swal.fire({
          icon: "success",
          title: "Success",
          toast: true,
          text: response.data.message || "Event created successfully",
          position: "top-end",
          customClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          showConfirmButton: false,
          timer: 3000,
          didOpen: (toast) => {
            toast.style.setProperty("top", "50px");
          },
        });

        // Then navigate after a short delay
        setTimeout(() => {
          navigate("/my_events");
        }, 2000);
      } else if (
        response.data.status === 400 &&
        response.data &&
        !response.data.success &&
        response.data.message
      ) {
        // toast.error(response.data.message, {
        //   position: toast.POSITION.TOP_RIGHT,
        //   autoClose: 3000,
        // });
        Swal.fire({
          icon: "error",
          title: response.data.message || "Oops... Something went wrong!",
          position: "top-end",
          toast: true,
          customClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          showConfirmButton: false,
          timer: 3000,
          didOpen: (toast) => {
            toast.style.setProperty("top", "50px");
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Please fill all the required details",
          position: "top-end",
          toast: true,
          customClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation",
          },
          showConfirmButton: false,
          timer: 3000,
          didOpen: (toast) => {
            toast.style.setProperty("top", "50px");
          },
        });
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Please select Date and Time", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };
  // const handleChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

  const toggleStartTimePicker = () => {
    setIsStartTimePickerOpen(!isStartTimePickerOpen);
  };

  const toggleEndTimePicker = () => {
    setIsEndTimePickerOpen(!isEndTimePickerOpen);
  };

  return (
    <>
      <div
        className="bg-gray-100_03 flex flex-col font-Lora gap-[35px] mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />
        <div
          style={{
            // background:
            //   "linear-gradient(167deg, #974097 9.25%, #7E3495 132.4%)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Lora",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "32px",
                color: "#212121",
                lineHeight: "normal",
                margin: "16px", // Add margin on all sides
              }}
              mb={1}
            >
              Create Event
            </Typography>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                backgroundColor: "rgba(160, 195, 255, 0.06)",

                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                borderRadius: "8px",
                // padding: "24px",
                // margin: "24px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                direction="column"
              >
                {headerLoading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 9999,
                    }}
                  >
                    <MoonLoader color="#863895" size={80} />
                  </div>
                )}
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                      }}
                    >
                      Event Name
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    placeholder="Please describe activity in 3 or fewer words"
                    label=""
                    size="small"
                    name="name"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "event_name")}
                    value={formData.event_name}
                    InputLabelProps={{ shrink: false }}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                </Stack>

                <Stack m={2}>
                  {" "}
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                      }}
                    >
                      About Event
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    variant="outlined"
                    size="small"
                    label=""
                    multiline
                    rows={4}
                    InputLabelProps={{ shrink: false }}
                    onChange={(e) => handleChange(e, "description")}
                    value={formData.description}
                    placeholder="Brief description of your activity"
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&:focus": {
                        outline: "none",
                        borderColor: "#ced4da",
                      },
                      "&& textarea:focus": {
                        boxShadow: "none !important",
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                </Stack>
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      mb={1}
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                      }}
                    >
                      Date
                    </Typography>
                  </ThemeProvider>
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    }}
                    spacing={2}
                    mt={1}
                  >
                    <div>
                      <ThemeProvider theme={headingFont}>
                        <Typography
                          component="div"
                          variant="h6"
                          mb={1}
                          sx={{
                            fontFamily: "Lora",
                            fontStyle: "normal",
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#212121",
                            lineHeight: "17px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          From
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        type="date"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "startDate")}
                        value={formData.startDate}
                        fullWidth
                        style={{
                          maxWidth: textFieldMaxWidth,
                          width: "100%",
                          borderColor: borderColor,
                          WebkitAppearance: "none",
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff",
                          },
                          "& [type='date']:focus": {
                            boxShadow: "none",
                          },
                          "& [type='date']::-webkit-calendar-picker-indicator":
                            {
                              display: "block",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M21 4h-1V3a1 1 0 0 0-2 0v1H6V3a1 1 0 0 0-2 0v1H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 15H4V8h16v11z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                            },
                        }}
                        InputProps={{
                          inputProps: {
                            min: currentDate,
                          },
                        }}
                      />
                    </div>

                    <div>
                      <ThemeProvider theme={headingFont}>
                        <Typography
                          component="div"
                          variant="h6"
                          mb={1}
                          sx={{
                            fontFamily: "Lora",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#212121",
                            lineHeight: "17px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          To
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        type="date"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "endDate")}
                        value={formData.endDate}
                        fullWidth
                        style={{
                          maxWidth: textFieldMaxWidth,
                          width: "100%",
                          borderColor: borderColor,
                          WebkitAppearance: "none", // Remove default Safari styles
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff",
                          },
                          "& [type='date']:focus": {
                            boxShadow: "none",
                          },
                          "& [type='date']::-webkit-calendar-picker-indicator":
                            {
                              display: "block",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M21 4h-1V3a1 1 0 0 0-2 0v1H6V3a1 1 0 0 0-2 0v1H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 15H4V8h16v11z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                            },
                        }}
                        InputProps={{
                          inputProps: {
                            min: formData.startDate,
                          },
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    }}
                    spacing={2}
                    mt={1}
                  >
                    <div>
                      <ThemeProvider theme={headingFont}>
                        <Typography component="div" variant="h6" mb={1}>
                          From
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        type="time"
                        size="small"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "starttime")}
                        value={formData.starttime}
                        fullWidth
                        style={{
                          borderColor: borderColor,
                          width: "100%",
                          WebkitAppearance: "none", // Remove default Safari styles
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff",
                          },
                          "& [type='time']:focus": {
                            boxShadow: "none",
                          },
                          "& [type='time']::-webkit-calendar-picker-indicator":
                            {
                              display: "block",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm0-16v8H9.5v2H12l2-2v-8h-2z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                            },
                        }}
                      />

                      {/* <div style={{ position: "relative", width: "100%" }}>
                        <TimePicker
                          showSecond={false}
                          showAmPm={false}
                          value={formData.starttime}
                          onChange={(e) => handleChange(e, "starttime")}
                          // format="h:mm a"
                          placeholder="Start Time"
                          open={isStartTimePickerOpen}
                          onClose={() => setIsStartTimePickerOpen(false)}
                          style={{
                            width: "100%",
                          maxWidth: textFieldMaxWidth,

                            WebkitAppearance: "none",
                            border: "1px solid #863895 !important",
                            height: "45px !important",
                            color: "black", 
                          }}
                        />

                        <AccessTimeIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={toggleStartTimePicker}
                        />
                      </div> */}
                    </div>

                    <div>
                      <ThemeProvider theme={headingFont}>
                        <Typography component="div" variant="h6" mb={1}>
                          To
                        </Typography>
                      </ThemeProvider>
                      <TextField
                        type="time"
                        size="small"
                        // label="Select Time"

                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleChange(e, "endtime")}
                        value={formData.endtime}
                        fullWidth
                        style={{
                          borderColor: borderColor,
                          width: "100%",
                          WebkitAppearance: "none",
                        }}
                        sx={{
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff", // Use 'inherit' or the desired color to override
                          },
                          "& [type='time']:focus": {
                            boxShadow: "none", // Remove box shadow on focus for type='text'
                            // borderColor: "#863895 !important",
                          },
                          "& [type='time']::-webkit-calendar-picker-indicator":
                            {
                              display: "block",
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm0-16v8H9.5v2H12l2-2v-8h-2z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                            },
                        }}
                      />
                      {/* <div style={{ position: "relative", width: "100%" }}>
                        <TimePicker
                          showSecond={false}
                          showAmPm={false}
                          value={formData.endtime}
                          onChange={(e) => handleChange(e, "endtime")}
                          // format="h:mm a"
                          placeholder="End Time"
                          open={isEndTimePickerOpen}
                          onClose={() => setIsEndTimePickerOpen(false)}
                          style={{
                            width: "100%",
                          maxWidth: textFieldMaxWidth,

                            WebkitAppearance: "none",
                            border: "1px solid #863895 !important",
                            height: "45px !important",
                          }}
                        />

                        <AccessTimeIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={toggleEndTimePicker}
                        />
                      </div> */}
                      {/* <div style={{ position: 'relative', width: '100%' }}>
      <TimePicker
        defaultValue={formData.endtime}
        format="h:mm a"
        use12Hours
        inputReadOnly
        minuteStep={5}
        open={isTimePickerOpen}
        onClose={() => setIsTimePickerOpen(false)}
        style={{
          width: '100%',
          border: '1px solid #863895',
          height: '45px',
        }}
      />
      <AccessTimeIcon
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
        }}
        onClick={toggleTimePicker}
      />
      </div>*/}
                    </div>
                  </Stack>
                </Stack>
                <Stack m={2}>
                  <Box
                    sx={{ width: 300, margin: "20px auto", marginLeft: "20px" }}
                  >
                    <h2 style={{ textAlign: "center" }}>Age Range</h2>
                    <Slider
                      getAriaLabel={() => "Age range"}
                      value={ageRange}
                      onChange={handleAgeChange}
                      valueLabelDisplay="auto"
                      valueLabelFormat={(value) => `${value}`}
                      min={18}
                      max={100}
                      step={1}
                      disableSwap
                      sx={{
                        color: "purple",
                      }}
                    />
                  </Box>
                </Stack>
                <Stack m={2}>
                  <FormControl>
                    <ThemeProvider theme={headingFont}>
                      <Typography component="div" variant="h6" mb={1}>
                        Gender
                      </Typography>
                    </ThemeProvider>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formData.gender}
                      onChange={(event) => handleGenderChange(event)}
                    >
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="Female"
                        sx={{
                          fontFamily: "Lora",
                          fontStyle: "normal",

                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#212121",
                          lineHeight: "14px",
                        }}
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="Male"
                        sx={{
                          fontFamily: "Lora",
                          fontStyle: "normal",

                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#212121",
                          lineHeight: "14px",
                        }}
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        label="Other"
                        sx={{
                          fontFamily: "Lora",
                          fontStyle: "normal",

                          fontWeight: "500",
                          fontSize: "12px",
                          color: "#212121",
                          lineHeight: "14px",
                        }}
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio />}
                        label="All"
                        sx={{
                          fontFamily: "Lora",
                          fontStyle: "normal",

                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#212121",
                          lineHeight: "14px",
                        }}
                      />
                    </RadioGroup>
                    {isTextFieldVisible && (
                      <ThemeProvider theme={headingFont}>
                        <Typography component="div" variant="h6" mb={1}>
                          Gender
                        </Typography>
                      </ThemeProvider>
                    )}
                  </FormControl>
                  {isTextFieldVisible && (
                    <TextField
                      onChange={(e) => handleChange(e, "gender_description")}
                      value={formData.gender_description}
                      placeholder="You may disclose your gender if you like "
                      label=""
                      name="name"
                      size="small"
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                      fullWidth
                      sx={{
                        "& fieldset": {
                          borderColor: "#863895 !important",
                        },
                        "&.MuiInputBase-colorPrimary": {
                          color: "#fff", // Use 'inherit' or the desired color to override
                        },
                        "& [type='text']:focus": {
                          boxShadow: "none", // Remove box shadow on focus for type='text'
                          // borderColor: "#863895 !important",
                        },
                      }}
                    />
                  )}
                </Stack>
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      Enter Dress Code
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    placeholder="What's the dress code?"
                    label=""
                    name="name"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    onChange={(e) => handleChange(e, "dresscode")}
                    value={formData.dresscode}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                </Stack>
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      Enter Guest Count
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    placeholder="How may persons would you like to invite? Enter it here"
                    label=""
                    name="no_of_invitees"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    onChange={(e) => handleChange(e, "no_of_invitees")}
                    value={formData.no_of_invitees}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                      },
                    }}
                  />
                  {error && (
                    <Typography
                      component="p"
                      color="error"
                      sx={{ fontSize: "12px" }}
                    >
                      {error}
                    </Typography>
                  )}
                </Stack>

                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      Enter Activity Location
                    </Typography>
                  </ThemeProvider>
                  <PlacesAutocomplete
                    value={formData.location}
                    onChange={(value) =>
                      handleChange({ target: { value } }, "location")
                    }
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <TextField
                          {...getInputProps({
                            placeholder: "Please enter location",
                            name: "location",
                            size: "small",
                            variant: "outlined",
                            margin: "normal",
                            InputLabelProps: { shrink: false },
                            fullWidth: true,
                            InputProps: {
                              style: {
                                fontFamily: "Lora",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#212121",
                                lineHeight: "17px",
                                marginTop: "8px",
                                marginBottom: "10px",
                              },
                            },
                            sx: {
                              "& fieldset": {
                                borderColor: "#863895 !important",
                              },
                              "&.MuiInputBase-colorPrimary": {
                                color: "#fff", // Use 'inherit' or the desired color to override
                              },
                              "& [type='text']:focus": {
                                boxShadow: "none", // Remove box shadow on focus for type='text'
                              },
                            },
                          })}
                        />

                        <div>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#e6e6e6"
                                : "#fff",
                            };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Stack>
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      I'm Paying for…
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    placeholder="Covering the cost of any aspect of your activity? Enter it here "
                    label=""
                    name="name"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    onChange={(e) => handleChange(e, "eventfeature")}
                    value={formData.eventfeature}
                    fullWidth
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='text']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                    }}
                  />
                </Stack>
                <Stack m={2}>
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      Other Details
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    variant="outlined"
                    multiline
                    size="small"
                    InputLabelProps={{ shrink: false }}
                    inputProps={{
                      style: {
                        height: "100px",
                      },
                    }}
                    InputProps={{
                      style: {
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "8px",
                        marginBottom: "10px",
                      },
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#ced4da",
                      },
                      "&& textarea:focus": {
                        boxShadow: "none !important",
                      },
                    }}
                    onChange={(e) => handleChange(e, "activity_details")}
                    value={formData.activity_details}
                    placeholder="Enter any other detail about your activity or yourself that you’d like to share"
                    fullWidth
                    style={{ borderColor: borderColor, width: "100%" }}
                  />
                </Stack>

                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  m={2}
                >
                  <Button
                    onClick={handleCreateEvent}
                    variant="contained"
                    sx={{
                      width: "100%",
                      maxWidth: 200,
                      border: "1px solid #863895",
                      borderRadius: "6px",
                      backgroundColor: "#863895 !important",
                      "&:hover": {
                        backgroundColor: "#863895",
                        border: "1px solid #863895",
                        color: "#FFF",
                      },
                      fontFamily: "Lora",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      textTransform: "none !important",
                    }}
                  >
                    Create Event
                  </Button>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imagefrm}
                  alt="tieTwo"
                  style={{ maxWidth: "90%", height: "auto" }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
          <Footer />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            rtl
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
          />
        </div>
      </div>
    </>
  );
};

export default CreateEventOnePage;
