// authService.js

export const AUTH_TOKEN_KEY = "authToken";
export const USER_ID_KEY = "userId"; 

export const saveTokenToLocalStorage = (token, userId) => {
  localStorage.setItem(AUTH_TOKEN_KEY, token);
  // localStorage.setItem(USER_ID_KEY, userId); 
  // console.log('Token and User ID saved to local storage:', token, userId);
};

export const getTokenFromLocalStorage = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  return token;
};

// export const getUserIdFromLocalStorage = () => {
//   const userId = localStorage.getItem(USER_ID_KEY);
//   return userId;
// };
