import React from "react";
import PropTypes from "prop-types";

const shapes = { square: "rounded-none", round: "rounded-[10px]" };
const variants = {
  fill: {
    white_A700: "bg-white-A700 text-blue_gray-100_02",
    indigo_A400: "bg-indigo-A400 text-white-A700",
    blue_200: "bg-blue-200 text-white-A700",
    deep_purple_300: "bg-deep_purple-300 text-white-A700",
    blue_300_02: "bg-blue-300_02 text-white-A700",
    red_A200_02: "bg-red-A200_02 text-white-A700",
    green_800: "bg-green-800 text-white-A700",
    cyan_600: "bg-cyan-600 text-white-A700",
    light_green_200: "bg-light_green-200 text-white-A700",
    orange_100: "bg-orange-100 text-black-900_8c",
    red_700: "bg-red-700 text-white-A700",
    purple_600_7f: "bg-purple-600_7f",
    gray_300_03: "bg-gray-300_03 text-purple-600",
    indigo_A100_02: "bg-indigo-A100_02 text-white-A700",
    black_900: "bg-black-900",
    blue_gray_100: "bg-blue_gray-100 text-black-900",
    purple_600_87: "bg-purple-600_87 text-white-A700",
    purple_600: "bg-purple-600 shadow-bs text-white-A700",
    blue_A200_02: "bg-blue-A200_02 shadow-bs text-white-A700",
  },
  gradient: {
    cyan_A700_green_400: "bg-gradient3  text-white-A700",
    blue_A200_02_indigo_A200_d3: "bg-gradient1  text-white-A700",
    indigo_A100_02_red_A100_01: "bg-gradient2  text-white-A700",
  },
  outline: {
    blue_gray_100_02:
      "border border-blue_gray-100_02 border-solid text-gray-800_02",
    red_500_02: "border border-red-500_02 border-solid text-red-500_01",
    blue_A200_02: "border border-blue-A200_02 border-solid text-blue-A200_02",
    purple_600: "border border-purple-600 border-solid text-purple-600",
  },
};
// const sizes = {
//   xs: "p-[11px]",
//   sm: "p-3.5",
//   md: "p-[19px]",
//   lg: "p-6 sm:px-5",
// };
const sizes = {
  xs: "p-[8px]", // Adjusted for responsiveness
  sm: "p-3.5",
  md: "p-[16px]", // Adjusted for responsiveness
  lg: "p-4 sm:px-5",
};
const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  size = "",
  variant = "",
  color = "",
  ...restProps
}) => {
  return (
    <button
      className={`${className} ${(shape && shapes[shape]) || ""} ${
        (size && sizes[size]) || ""
      } ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  variant: PropTypes.oneOf(["fill", "gradient", "outline"]),
  color: PropTypes.oneOf([
    "white_A700",
    "indigo_A400",
    "blue_200",
    "deep_purple_300",
    "blue_300_02",
    "red_A200_02",
    "green_800",
    "cyan_600",
    "light_green_200",
    "orange_100",
    "red_700",
    "purple_600_7f",
    "gray_300_03",
    "indigo_A100_02",
    "black_900",
    "blue_gray_100",
    "purple_600_87",
    "purple_600",
    "blue_A200_02",
    "cyan_A700_green_400",
    "blue_A200_02_indigo_A200_d3",
    "indigo_A100_02_red_A100_01",
    "blue_gray_100_02",
    "red_500_02",
  ]),
};

export { Button };
