import React, { useRef, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuth, signOut } from "firebase/auth";
import { updateOnlineStatus } from "./Firebase.jsx";
import InformationPage1Page from "./Components/Information";
import CreateEventOnePage from "./Components/CreateEvent";
import SignUpPage from "./Components/SignUp";
import LoginPage from "./Components/Login";
import AcceptedEvents from "./Components/pages/AcceptedEvents";
import HomePage from "./Components/pages/HomePage";
import MyEvents from "./Components/pages/MyEvents";
// import Chat from "./pages/Chat/Chat.jsx";
import Chat from "./Components/pages/Chat.js";
import SafetyGuidelines from "./Components/pages/SafetyGuidelines";
import ContactUs from "./Components/FooterPages/ContactUs";
import TermsCondition from "./Components/FooterPages/TermsCondition";
import FAQ from "./Components/FooterPages/FAQ";
import ProfilePage from "./Components/pages/ProfileSection/ProfilePage";
import Notification from "./Components/pages/Notification";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider, useGoogleOAuth } from "@react-oauth/google";
import EditEventPage from "./Components/EditEvent";
import { getTokenFromLocalStorage } from "./Components/authService";
import { useUserAuth } from "./context/userAuthContext.js";
import ProtectedRoute from "./Components/ProtectedRoute.js";
import { DrawerContextProvider } from "./context/drawerContext";
// import useUserAuth from './context/userAuthContext.js';
import { UserAuthContextProvider } from "./context/userAuthContext.js";
import MembershipPage from "./Components/pages/ProfileSection/Membership.js";
import PaymentSuccess from "./Components/pages/Success.js";
import PaymentDecline from "./Components/pages/DeclinePayment.js";

const theme = createTheme();

const PrivateRoute = ({ element }) => {
  const token = getTokenFromLocalStorage();

  if (!token) {
    return <Navigate to="/" />;
  }

  return element;
};

function App() {
  // const navigate = useNavigate();
  const inactivityTimeoutRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutRef.current);
    inactivityTimeoutRef.current = setTimeout(
      () => handleLogout(),
      120 * 60 * 1000
    );
  };

  const handleLogout = async () => {
    try {
      const auth = getAuth();

      if (auth.currentUser) {
        await updateOnlineStatus(auth.currentUser.uid, false);

        await signOut(auth);
        // alert("Session timeout. Please login again to continue.");
        const token = getTokenFromLocalStorage();

        if (!token) {
          console.error("Token not found in local storage");
          return;
        }

        const response = await axios.get("logout", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // localStorage.removeItem("authToken");
        localStorage.clear();
        window.location.href = "/login";
        setOpen(false);
      } else {
        console.log("No user is currently authenticated");
      }
    } catch (error) {
      console.error("Logout Failed:", error);
    }
  };

  const handleUserActivity = () => {
    resetInactivityTimeout();
  };
  // const handleLogout = async () => {
  //   // console.log("Logging out due to inactivity");
  //   try {
  //     const auth = getAuth();

  //     // Firebase sign-out
  //     updateOnlineStatus(auth.currentUser.uid, false);
  //     await signOut(auth);

  //     const token = getTokenFromLocalStorage();

  //     if (!token) {
  //       console.error("Token not found in local storage");
  //       return;
  //     }

  //     // console.log("Retrieved Token:", token);

  //     const response = await axios.get("logout", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     localStorage.removeItem("authToken");

  //     setOpen(false);
  //   } catch (error) {
  //     console.error("Logout Failed:", error);
  //   }
  // };
  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Set the initial inactivity timeout
    resetInactivityTimeout();

    // Cleanup function to remove event listeners and clear the timeout when the component unmounts
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(inactivityTimeoutRef.current);
      // console.log("Cleanup function called");
    };
  }, []);

  return (
    <GoogleOAuthProvider
      clientId="973863211692-b5bah969nr381lbfm5mg4on1gd2rmi1c.apps.googleusercontent.com"
      redirectUri="https://accompanied.ca/"
    >
      <ThemeProvider theme={theme}>
        <Router>
          <UserAuthContextProvider>
            <Routes>
              <Route path="/" element={<InformationPage1Page />} />
              <Route
                path="/create_event"
                element={<PrivateRoute element={<CreateEventOnePage />} />}
              />
              <Route
                path="/sign_up"
                element={
                  <useUserAuth>
                    <SignUpPage />
                  </useUserAuth>
                }
              />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/accepted_events"
                element={<PrivateRoute element={<AcceptedEvents />} />}
              />
              <Route
                path="/home"
                element={<PrivateRoute element={<HomePage />} />}
              />
              <Route
                path="/membership"
                element={<PrivateRoute element={<MembershipPage />} />}
              />
              <Route
                path="/my_events"
                element={<PrivateRoute element={<MyEvents />} />}
              />
              {/* <Route path="/chat" element={ <PrivateRoute element={<Chat />} />} />*/}
              <Route
                path="/chat"
                element={
                  <ProtectedRoute>
                    <DrawerContextProvider>
                      <Chat />
                    </DrawerContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route path="/safety_guidelines" element={<SafetyGuidelines />} />
              <Route path="/contact_us" element={<ContactUs />} />
              <Route path="/terms&condition" element={<TermsCondition />} />
              <Route path="/faq" element={<FAQ />} />

              <Route
                path="/profile"
                element={
                  <PrivateRoute
                    element={
                      <ProfilePage
                        handleLogout={handleLogout}
                        open={open}
                        setOpen={setOpen}
                      />
                    }
                  />
                }
              />
              <Route
                path="/edit_event/:id"
                element={<PrivateRoute element={<EditEventPage />} />}
              />
              <Route
                path="/notification"
                element={<PrivateRoute element={<Notification />} />}
              />
              <Route
                path="/payment_success"
                element={<PrivateRoute element={<PaymentSuccess />} />}
              />
              <Route
                path="/payment_declined"
                element={<PrivateRoute element={<PaymentDecline />} />}
              />
            </Routes>
          </UserAuthContextProvider>
        </Router>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
