import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Img } from "../Img";
import { Text } from "../Text";
// import { Button } from "../Button";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Stack, useTheme } from "@mui/system";
import Button from "@mui/material/Button";
import { NavLink, useNavigate } from "react-router-dom";
import MembershipPopup from "../FooterPages/MembershipPopup";
import "./Header2.css";
import "./header.css";
const HeaderSignup = () => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [membershipPopupOpen, setMembershipPopupOpen] = React.useState(false);

  const handleMembershipClick = () => {
    setMembershipPopupOpen(true);
  };

  const handleMembershipPopupClose = () => {
    setMembershipPopupOpen(false);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < theme.breakpoints.values.md
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth < theme.breakpoints.values.md);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header className="bg-gray-300_01 flex md:flex-col flex-row font-lato items-center justify-center mb-[-42px] mx-auto w-full z-[1]">
        <div className="bg-header flex md:flex-1 flex-col items-center justify-start  ml-10 md:ml-[0] rounded-md w-[10%] md:w-full">
          <div className="flex flex-col items-center justify-start w-[69%] md:w-full">
            <Img
              className="h-[30px]"
              src="images/img_group1171274834.svg"
              alt="group1171274834"
            />
            <Text
              className="mt-[7px] text-[8.87px] text-white-A700 tracking-[2.22px]"
              size="txtLatoBold887"
            >
              ACCOMPANIED
            </Text>
            <Text
              className="text-[4px] text-white-A700_8c tracking-[1.11px]"
              size="txtInterRegular443"
            >
              LIVELY. SAVVY. PEOPLE
            </Text>
          </div>
        </div>
        <div className="flex md:flex-1 flex-col items-center justify-start md:ml-[0] ml-[10px] md:mr-[31px] md:mt-0 w-[90%] md:w-full">
          <div className="flex flex-row gap-24 items-center justify-between">
            <ul className="flex md:flex-col flex-row gap-10 md:hidden items-start justify-start w-auto common-row-list hide-on-medium-screen">
              <li>
                <NavLink to="/" className="text-md nav-link">
                  Home
                </NavLink>
              </li>
              <li>
              <NavLink style={{ textDecoration: "none", color: "gray" }}  onClick={() => {
                      handleMembershipClick();
                      handleCloseNavMenu();
                    }}>
                   Membership
                  
                   
                </NavLink>
                {/* <div
                  className="text-md nav-link"
                  onClick={handleMembershipClick}
                  style={{ cursor: "pointer" }}
                >
                  Membership
                </div> */}
              </li>

              <li>
                <NavLink to="/safety_guidelines" className="text-md nav-link">
                  Safety Guidelines
                </NavLink>
              </li>

              {/* <li>
                <NavLink to="/terms&condition" className="text-md nav-link">
                  Terms & Conditions
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/contact_us" className="text-md nav-link">
                  Contact Us
                </NavLink>
              </li>

              <li>
                <NavLink to="/faq" className="text-md nav-link">
                  FAQs
                </NavLink>
              </li>
            </ul>
            <div className="flex md:flex-row font-inter gap-4 items-center justify-end justify-between">
              <div className="flex items-center justify-end w-full md:hidden sm:hidden hide-on-medium-screen">
                <Stack direction="row" spacing={2}>
                  <Link to="/login">
                    {" "}
                    <Button
                      variant="contained"
                      sx={{
                        border: "1px solid #863895",
                        backgroundColor: "#863895 !important",
                        padding: "8px 35px",
                        borderRadius: "6px",
                        "&:hover": {
                          backgroundColor: "#863895",
                          color: "#FFF",
                        },
                        color: "#FFF",
                        fontFamily: "Lora",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "normal",
                        textTransform: "none !important",
                        fontFeatureSettings: `'clig' off, 'liga' off`,
                      }}
                    >
                      Sign In
                    </Button>
                  </Link>
                 
                </Stack>
              </div>
            </div>
            <Toolbar disableGutters>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                  },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon style={{ width: "50px", height: "50px" }} />
                </IconButton>
              </Box>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "100vw",
                    boxSizing: "border-box",
                  },
                }}
              >
                <NavLink to="/" className="text-md nav-link">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Home</Typography>
                  </MenuItem>
                </NavLink>
                <NavLink  className="text-md nav-link">
                <MenuItem
                  onClick={() => {
                    handleMembershipClick();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Membership</Typography>
                </MenuItem>
                </NavLink>
                <NavLink to="/safety_guidelines" className="text-md nav-link">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Safety Guidelines</Typography>
                  </MenuItem>
                </NavLink>
                {/* <NavLink to="/terms&condition" className="text-md nav-link">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Terms & Conditions</Typography>
                  </MenuItem>
                </NavLink> */}
                <NavLink to="/contact_us" className="text-md nav-link">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Contact Us</Typography>
                  </MenuItem>
                </NavLink>
                <NavLink to="/faq" className="text-md nav-link">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">
                      FAQs
                    </Typography>
                  </MenuItem>
                </NavLink>
              </Menu>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  "& > :not(:last-child)": {
                    marginRight: "10px",
                  },
                }}
              >
                <Link to="/login">
                  {" "}
                  <Button
                    variant="contained"
                    sx={{
                      border: "1px solid #863895",
                      backgroundColor: "#863895",
                      padding: "8px 35px",
                      borderRadius: "6px",
                      "&:hover": {
                        backgroundColor: "#863895",
                        color: "#FFF",
                      },
                      color: "#FFF",
                      fontFamily: "Lora",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "normal",
                      textTransform: "none !important",
                      fontFeatureSettings: `'clig' off, 'liga' off`,
                    }}
                  >
                    Sign In
                  </Button>
                </Link>
              </Box>
            </Toolbar>
          </div>
        </div>
      </header>
      <MembershipPopup
        open={membershipPopupOpen}
        onClose={handleMembershipPopupClose}
      />
    </>
  );
};

export default HeaderSignup;
