import React from "react";

const sizeClasses = {
  txtLatoRegular5243: "font-lato font-normal",
  txtMontserratBold16: "font-bold font-montserrat",
  txtLatoBold18: "font-bold font-lato",
  txtLoraRomanBold40: "font-bold font-lora",
  txtInterSemiBold26: "font-inter font-semibold",
  txtInterRegular16Bluegray5001: "font-inter font-normal",
  txtRobotoRomanRegular14Gray300: "font-normal font-roboto",
  txtLatoBold13: "font-bold font-lato",
  txtLoraRomanSemiBold16Gray70001: "font-lora font-semibold",
  txtInterMedium1952: "font-inter font-medium",
  txtGeorgia24: "font-georgia font-normal",
  txtLatoRegular14: "font-lato font-normal",
  txtInterSemiBold32: "font-inter font-semibold",
  txtInterSemiBold30: "font-inter font-semibold",
  txtLoraRomanSemiBold16DeeppurpleA100: "font-lora font-semibold",
  txtLatoBold1003: "font-bold font-lato",
  txtMontserratBold22: "font-bold font-montserrat",
  txtLoraRomanRegular18: "font-lora font-normal",
  txtLoraRomanBold40Black90001: "font-bold font-lora",
  txtLatoRegular16: "font-lato font-normal",
  txtLoraRomanRegular16: "font-lora font-normal",
  txtOpenSansRomanSemiBold36: "font-opensans font-semibold",
  txtPoppinsRegular16: "font-normal font-poppins",
  txtLatoBold1419: "font-bold font-lato",
  txtLoraRomanRegular13: "font-lora font-normal",
  txtInterRegular443: "font-inter font-normal",
  txtLoraRomanRegular20: "font-lora font-normal",
  txtPoppinsSemiBold20: "font-poppins font-semibold",
  txtPoppinsRegular20: "font-normal font-poppins",
  txtInterRegular16: "font-inter font-normal",
  txtSignikaRegular24: "font-normal font-signika",
  txtInterBold26Gray60002: "font-bold font-inter",
  txtRalewayRomanMedium16: "font-medium font-raleway",
  txtInterRegular16Gray30004: "font-inter font-normal",
  txtMontserratBold34: "font-bold font-montserrat",
  txtInterBold26Gray70002: "font-bold font-inter",
  txtMontserratSemiBold14: "font-montserrat font-semibold",
  txtOpenSansRomanSemiBold24: "font-opensans font-semibold",
  txtLoraRomanSemiBold16: "font-lora font-semibold",
  txtMontserratRomanBold40: "font-bold font-montserrat",
  txtPoppinsSemiBold32: "font-poppins font-semibold",
  txtGeorgia48: "font-georgia font-normal",
  txtInterMedium808: "font-inter font-medium",
  txtInterRegular1876Purple600: "font-inter font-normal",
  txtRobotoRomanRegular24: "font-normal font-roboto",
  txtMontserratBold22WhiteA700: "font-bold font-montserrat",
  txtLoraRomanSemiBold16Blue500: "font-lora font-semibold",
  txtInterRegular71: "font-inter font-normal",
  txtLatoMedium18Purple600: "font-lato font-medium",
  txtInterRegular16WhiteA700: "font-inter font-normal",
  txtPoppinsRegular20Black90099: "font-normal font-poppins",
  txtInterSemiBold56: "font-inter font-semibold",
  txtInterRegular502: "font-inter font-normal",
  txtLatoMedium14Bluegray100: "font-lato font-medium",
  txtTahoma24: "font-normal font-tahoma",
  txtLoraRomanSemiBold16Green800: "font-lora font-semibold",
  txtInterSemiBold60: "font-inter font-semibold",
  txtLoraRomanBold18Gray90012: "font-bold font-lora",
  txtRobotoRegular1037: "font-normal font-roboto",
  txtLatoRegular16Bluegray10001: "font-lato font-normal",
  txtRobotoRegular10: "font-normal font-roboto",
  txtInterRegular64: "font-inter font-normal",
  txtVerdana24: "font-normal font-verdana",
  txtInterBold26: "font-bold font-inter",
  txtLatoRegular48: "font-lato font-normal",
  txtOpenSansRomanRegular48: "font-normal font-opensans",
  txtMontserratRomanBold20: "font-bold font-montserrat",
  txtLoraRomanMedium15: "font-lora font-medium",
  txtLoraRomanMedium16: "font-lora font-medium",
  txtOpenSansRomanRegular40: "font-normal font-opensans",
  txtLoraRomanMedium14: "font-lora font-medium",
  txtInterRegular1876: "font-inter font-normal",
  txtLatoBold887: "font-bold font-lato",
  txtInterMedium1952WhiteA700: "font-inter font-medium",
  txtMontserratRomanRegular24: "font-montserrat font-normal",
  txtInterRegular1869: "font-inter font-normal",
  txtMontserratRomanMedium22: "font-medium font-montserrat",
  txtRobotoRomanRegular14: "font-normal font-roboto",
  txtRalewayRegular16: "font-normal font-raleway",
  txtLoraRomanSemiBold16IndigoA10002: "font-lora font-semibold",
  txtInterRegular1876WhiteA700: "font-inter font-normal",
  txtInterRegular40: "font-inter font-normal",
  txtLoraRomanSemiBold16Cyan600: "font-lora font-semibold",
  txtRalewayRegular12: "font-normal font-raleway",
  txtLatoBold18Purple300: "font-bold font-lato",
  txtLoraRomanBold40Gray90010: "font-bold font-lora",
  txtInterSemiBold32Bluegray5001: "font-inter font-semibold",
  txtLatoMedium14Bluegray90003: "font-lato font-medium",
  txtMontserratRomanMedium16: "font-medium font-montserrat",
  txtLoraRomanBold18: "font-bold font-lora",
  txtLoraRomanBold13: "font-bold font-lora",
  txtRalewayBold2061: "font-bold font-raleway",
  txtMontserratMedium16: "font-medium font-montserrat",
  txtRalewayRegular2061: "font-normal font-raleway",
  txtMontserratBold4939: "font-bold font-montserrat",
  txtLatoBold40: "font-bold font-lato",
  txtRalewayRegular24: "font-normal font-raleway",
  txtLatoBold45: "font-bold font-lato",
  txtLatoMedium18: "font-lato font-medium",
  txtLoraRomanSemiBold16Gray700: "font-lora font-semibold",
  txtLatoMedium14: "font-lato font-medium",
  txtRalewayRegular1646: "font-normal font-raleway",
  txtLatoMedium12: "font-lato font-medium",
  txtInterSemiBold26Black900: "font-inter font-semibold",
  txtLoraRomanBold40Black900: "font-bold font-lora",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
