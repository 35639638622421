// PaymentDecline.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { getTokenFromLocalStorage } from "../../Components/authService";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F44336", // Replace with your theme color for decline
    },
  },
  typography: {
    h1: {
      fontFamily: "Lora",
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    body1: {
      fontFamily: "Lora",
      fontSize: "1rem",
    },
  },
});

const PaymentDecline = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const cancelPayment = async () => {
      const apiUrl = "cancel_payment";

      try {
        // Get user_id and membership_id from local storage
        const userId = localStorage.getItem("user_id");
        const membershipId = localStorage.getItem("membership_id");

        // Ensure both values are present before making the API call
        if (userId && membershipId) {
          const token = getTokenFromLocalStorage(); // Assume this function is defined to get the token

          // Make the API call
          const response = await axios.post(
            apiUrl,
            {
              user_id: userId,
              membership_id: membershipId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log("Response from cancel payment API:", response.data);
        } else {
          console.error(
            "user_id or membership_id is missing from local storage"
          );
        }
      } catch (error) {
        console.error(
          "Error occurred while calling cancel payment API:",
          error
        );
      }
    };

    cancelPayment();
  }, []);
  const handleRedirect = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("membership_id");

    navigate("/membership");
  };
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" style={{ textAlign: "center", padding: "50px" }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h1" color="primary">
              Payment Declined
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Unfortunately, your transaction could not be processed.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirect}
              style={{ marginTop: "20px" }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default PaymentDecline;
