// MembershipPage.js

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import { getTokenFromLocalStorage } from "../../authService";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Footer from "../../FooterPages/Footer";
import Header2 from "../../Header/Header2";
import { MoonLoader } from "react-spinners";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkOut";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import stripe_img from "../../../assets/stripe_logo.jpg";
import converge_img from "../../../assets/Converge-pay.jpg";

const headingFont = createTheme({
  typography: {
    h6: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "20px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },

    h5: {
      color: "#000",
      fontFamily: "Lora",
      fontSize: "18px !important ",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    body2: {
      // width: '90px',
      color: "rgba(7, 7, 7, 0.98)",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
    },
  },
});

const MembershipPage = () => {
  const [membershipList, setMembershipList] = useState([]);

  const cardColors = ["#808080", "#863895", "#2E5CFF"];
  const [headerData, setHeaderData] = useState({});
  const [headerLoading, setHeaderLoading] = useState(true);
  const [profileId, setProfileId] = useState(null);
  const [membershipId, setMembershipId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedMembershipId, setSelectedMembershipId] = useState("");

  const handleClosePaymentDialog = () => setOpenPaymentDialog(false);

  const handleOpenPaymentDialog = (membershipId) => {
    setOpenPaymentDialog(true);
    setSelectedMembershipId(membershipId); // Store the selected membership id
  };

  const fetchData = async () => {
    try {
      const token = getTokenFromLocalStorage();
      console.log("Retrieved Token:", token);

      const response = await axios.post(
        "membership_list",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // console.log(response.data);
      setMembershipList(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        // console.log("Retrieved Token:", token);
        const response = await axios.get("getprofile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProfileId(response.data.data.id);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchData();
  }, []);

  // const stripePromise = loadStripe(
  //   "pk_test_51OrcqrSCIbPg0G1BgcfdIZUnERxPFvIt4vBHbmFcSe9nWXmJwIV71ClzgoACt3gr0ugJdfJVj8xw0voOsUjJeuLk00BLXqukeM"
  // );
  const stripePromise = loadStripe(
    "pk_test_51OrhDf042YBPNJH4he7oDW7jWAJHn2LHjJwFq2NwqY8DD34hINl4EY3KKd6db4O4jmZXfX94O4i4tXjLRIwAWBUH00aetJKXEP"
  );

  const handleOpenDialog = (membershipId) => {
    setOpenDialog(true);
    setMembershipId(membershipId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  const handleConvergePayment = async (membershipId, profileId) => {
    // handleClosePaymentDialog();

    const apiUrl = "generate_converge_token";

    try {
      const token = getTokenFromLocalStorage();

      // Store user_id and membership_id in local storage
      localStorage.setItem("user_id", profileId);
      localStorage.setItem("membership_id", membershipId);

      const response = await axios.post(
        apiUrl,
        {
          user_id: profileId,
          membership_id: membershipId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log("Response from Converge API:", response.data.url);

      // Redirect to payment gateway in the same tab
      window.location.href = response.data.url;
    } catch (error) {
      console.error("Error occurred while calling Converge API:", error);
    }
  };

  return (
    <>
      <div
        className="flex flex-col font-lato gap-[28px] items-start justify-start mx-auto w-full"
        style={{ backgroundColor: "rgba(134, 56, 149, 0.1)" }}
      >
        <Header2
          setHeaderData={setHeaderData}
          setHeaderLoading={setHeaderLoading}
        />

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          // sx={{backgroundColor: "rgba(134, 56, 149, 0.1)", }}
        >
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card
              sx={{
                position: "relative",
                boxShadow: 3,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(134, 56, 149, 0.05)",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                m={5}
                sx={{
                  color: "#000",
                  fontFamily: "Lora",
                  fontSize: "24px !important ",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                }}
              >
                Simple Subscription Packages
              </Typography>

              <CardContent
                sx={{
                  marginTop: "30px",
                }}
              >
                <Grid
                  container
                  spacing={3}
                  style={{ justifyContent: "center" }}
                >
                  {headerLoading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 9999,
                      }}
                    >
                      <MoonLoader color="#863895" size={80} />
                    </div>
                  )}
                  {membershipList.map((membership, index) => {
                    return (
                      <Grid
                        key={membership.id}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        style={{
                          marginBottom: "20px",
                          textAlign: "center",
                        }}
                      >
                        <Card
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            borderTop: `4px solid ${membership.color_code}`,
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
                            backgroundColor: "rgba(160, 195, 255, 0.03)",
                            position: "relative",
                          }}
                        >
                          <CardContent
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            {membership.plan_status && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 10,
                                  right: 10,
                                }}
                              >
                                <CheckCircleIcon
                                  style={{ color: "green", fontSize: 30 }}
                                />
                                <Typography
                                  sx={{
                                    color: "green",
                                    fontFamily: "Lora",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "28px",
                                    textTransform: "none !important",
                                  }}
                                  variant="h6"
                                >
                                  Active
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "green",
                                    fontFamily: "Lora",
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "18px",
                                    textTransform: "none !important",
                                  }}
                                  variant="body2"
                                >
                                  {membership.days_remaining} days left
                                </Typography>
                              </div>
                            )}
                            <div
                              style={{
                                color: cardColors[index % cardColors.length],
                                fontFamily: "Lora",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "28px",
                                textTransform: "none !important",
                                marginBottom: "1rem",
                              }}
                            >
                              {parseInt(membership.duration) === 1
                                ? `${membership.duration} Month`
                                : `${membership.duration} Months`}
                            </div>

                            <Typography
                              variant="h4"
                              component="div"
                              mb={2}
                              sx={{
                                color: cardColors[index % cardColors.length],
                                fontFamily: "Lora",
                                fontSize: "42px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                              }}
                            >
                              ${membership.price}
                            </Typography>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{
                                  color: "#222",
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "28px",
                                  textTransform: "none !important",
                                }}
                                gutterBottom
                              >
                                &bull; {membership.name}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#222",
                                  fontFamily: "Lora",
                                  fontSize: "12px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  textTransform: "none !important",
                                }}
                                variant="h5"
                                gutterBottom
                                mb={1}
                              >
                                &bull; {membership.short_description}
                              </Typography>
                            </div>

                            <Typography
                              variant="body2"
                              sx={{
                                color: "#222222",
                                fontFamily: "Lora",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "18px",
                                textTransform: "none !important",
                              }}
                              mb={1}
                            >
                              {membership.description}
                            </Typography>
                          </CardContent>
                          <CardActions
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              onClick={() =>
                                handleConvergePayment(membership.id, profileId)
                              }
                              sx={{
                                padding: "10px 20px",
                                border: `1px solid ${membership.color_code}`,
                                borderRadius: "5px",
                                color: "#FFFFFF",
                                backgroundColor: `${membership.color_code} !important`,
                                fontFamily: "Lora",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                textTransform: "none !important",
                              }}
                              variant="outlined"
                            >
                              Get Subscription
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {membershipId && (
            <Elements stripe={stripePromise}>
              <CheckoutForm
                handlePayment={() => handleOpenDialog(membershipId)}
                profileId={profileId}
                membershipId={membershipId}
                open={openDialog}
                onClose={handleCloseDialog}
              />
            </Elements>
          )}

          <Grid
            container
            alignItems="flex-start" // Align content to the start vertically
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <div
              style={{
                maxWidth: 900,
                maxHeight: 150,
                margin: "auto",
                // backgroundColor: "rgba(134, 56, 149, 0.1)",

                padding: "20px",
              }}
            >
              <Typography
                sx={{
                  color: "var(--General-Dark, #222)",
                  fontFamily: "Lora",
                  fontSize: "19px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "28.938px",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                  textAlign: "center", // Added textAlign="center"
                  "@media (max-width: 600px)": {
                    // Adjust font size for small screens
                    fontSize: "14px",
                  },
                }}
              >
                Ready to enhance your social life, expand their network, and
                create memorable experiences?
              </Typography>
              <Typography
                sx={{
                  color: "var(--General-Dark, #222)",
                  fontFamily: "Lora",
                  fontSize: "17px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "28.938px",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                  textAlign: "center", // Added textAlign="center"
                  "@media (max-width: 600px)": {
                    // Adjust font size for small screens
                    fontSize: "12px",
                  },
                }}
              >
                Choose the plan that best fits your lifestyle and start
                exploring a world meaningful connections.
              </Typography>
            </div>

            <Grid item xs={12}>
              {/* Subscription Features Heading */}

              <Typography
                sx={{
                  color: "var(--General-Dark, #222)",
                  fontFamily: "Lora",
                  fontSize: "28px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "28.938px",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                  marginTop: "16px", // Adjust the margin as needed
                  marginLeft: "8px",
                }}
              >
                Subscription Features
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {/* Subscription Features List */}

              <ul
                style={{
                  marginLeft: "54px",
                  marginTop: "10px", // Adjust the margin as needed
                  color: "#202226",
                  listStyle: "unset",
                  fontFamily: "Lora",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "31px",
                }}
              >
                <li>Create user profile</li>
                <li>Respond to any posted activity and event</li>
                <li>
                  Send request that a profile be ghost-tagged in the unlikely
                  scenario that you are ghosted at an event
                </li>
                <li>Post an activity and event</li>
                <li>Community of real people </li>
                <li>Instant chat feature</li>
                <li>
                  Share your activity or event directly to another profile
                </li>
                <li>Tagging and search filters</li>
                <li>Clean, uncomplicated web design</li>
                <li>
                  Lower monthly cost for users who commit to a longer
                  subscription period
                </li>
                <li>Billing and membership management</li>
                <li>
                  After free trial period has ended, subscription is converted
                  into paid subscription and auto-renews at the end of each
                  billing cycle
                </li>
                <li>
                  Changes to the subscription plan, features or cost will be
                  communicated to subscribers and/or members either by email or
                  posted to this site
                </li>
                <li>
                  Cancel or change your subscription anytime and still be able
                  to use your subscription for the time you’ve already paid
                </li>
                <li>Easy cancellation</li>
                <li
                  style={{
                    marginTop: "24px",
                    color: "#F4460F",
                    fontFamily: "Lora",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "31px",
                    // marginLeft: "1px",

                    listStyleType: "none", // To remove default list item marker
                  }}
                >
                  *All currency is in Canadian dollars.
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
        <div className="flex flex-col md:gap-10 gap-[101px] items-center w-full">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default MembershipPage;
