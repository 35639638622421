// PaymentSuccess.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getTokenFromLocalStorage } from "../../Components/authService";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4CAF50", // Replace with your theme color
    },
  },
  typography: {
    h1: {
      fontFamily: "Lora, sans-serif",
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    body1: {
      fontFamily: "Lora, sans-serif",
      fontSize: "1rem",
    },
  },
});

const PaymentSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const successPayment = async () => {
      const apiUrl = "success_payment";

      const userId = localStorage.getItem("user_id");
      const membershipId = localStorage.getItem("membership_id");

      if (!userId || !membershipId) {
        navigate("/membership");
        return;
      }

      try {
        const token = getTokenFromLocalStorage();

        const response = await axios.post(
          apiUrl,
          {
            user_id: userId,
            membership_id: membershipId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Response from success payment API:", response.data);
      } catch (error) {
        console.error(
          "Error occurred while calling success payment API:",
          error
        );
      }
    };

    successPayment();
  }, [navigate]);
  const handleRedirect = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("membership_id");
    navigate("/membership");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" style={{ textAlign: "center", padding: "50px" }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h1" color="primary">
              Thank You for Your Payment!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Your transaction was successful.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRedirect}
              style={{ marginTop: "20px" }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default PaymentSuccess;
