import React, { useEffect, useState } from "react";
import imagefrm from "../assets/image 1.png";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Footer from "./FooterPages/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Button } from "@mui/material";
import axios from "axios";
import "../fonts.css";
import { getTokenFromLocalStorage } from "./authService.js";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { useEventContext } from "./EventContext.js";
import { toast, ToastContainer } from "react-toastify";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header/Header2.js";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const headingFont = createTheme({
  typography: {
    h4: {
      color: "#FF3932;",
      fontFamily: "Lora",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    h6: {
      color: "",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "normal",
    },
    h5: {
      color: "#5A5A5A",
      fontFamily: "Lora",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "normal",
    },
    body2: {
      width: "85px",
      color: "#A3A3A3",
      fontFamily: "Lora",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px",
      marginTop: "5px",
      marginLeft: "5px",
    },
  },
});
const EditEventPage = () => {
  const { id } = useParams();
  const textFieldMaxWidth = "300px";
  const borderColor = "#001aff";

  const [selectedGender, setSelectedGender] = React.useState("");
  const [isTextFieldVisible, setTextFieldVisible] = React.useState(false);
  const [eventData, setEventData] = React.useState({});
  const [error, setError] = useState("");

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getTokenFromLocalStorage();
        const response = await axios.get(`eventDetails/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Event details:", response.data);
        // // debugger
        // debugger
        setEventData(response.data.data);
        setFormData({
          id: response.data.data.id,
          event_name: response.data.data.event_name,
          description: response.data.data.description,
          endtime: response.data.data.endtime.split(" ")[1],
          starttime: response.data.data.starttime.split(" ")[1],
          age_from: response.data.data.age_from,
          endDate: response.data.data.endtime.split(" ")[0],
          startDate: response.data.data.starttime.split(" ")[0],
          age_to: response.data.data.age_to,
          gender: response.data.data.gender,
          gender_description: response.data.data.gender_description,
          dresscode: response.data.data.dresscode,
          activity_details: response.data.data.activity_details,
          eventfeature: response.data.data.eventfeature,
          longitude: response.data.data.longitude,
          latitude: response.data.data.latitude,
          location: response.data.data.location,
          no_of_invitees: response.data.data.no_of_invities,
        });
        setAgeRange([response.data.data.age_from, response.data.data.age_to]);
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    };

    fetchData();
  }, [id]);

  const [isStartTimePickerOpen, setIsStartTimePickerOpen] = useState(false);
  const [isEndTimePickerOpen, setIsEndTimePickerOpen] = useState(false);

  const toggleStartTimePicker = () => {
    setIsStartTimePickerOpen(!isStartTimePickerOpen);
  };

  const toggleEndTimePicker = () => {
    setIsEndTimePickerOpen(!isEndTimePickerOpen);
  };

  const handleGenderChange = (event) => {
    const selectedValue = event.target.value;
    // console.log("Selected Value:", selectedValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender: selectedValue,
    }));
    setTextFieldVisible(selectedValue === "3");
  };
  // const handleChange = (e, field) => {
  //   // console.log("Event:", e);
  //   // console.log("Field:", field);

  //   if (e && e.target) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [field]: e.target.value || "",
  //     }));
  //   } else {
  //     console.error("Invalid event or event target");
  //   }
  // };

  // const handleChange = (e, field) => {
  //   const selectedTime = e && e.target ? e.target.value : e; // Adjust this line
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [field]: selectedTime || "",
  //   }));
  // };
  const handleChange = (e, field) => {
    const inputValue = e.target.value;
    // Validate if input is a valid integer
    if (/^\d*$/.test(inputValue)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [field]: inputValue,
      }));
      setError(""); // Clear error if input is valid
    } else {
      setError("Please enter only digits."); // Show error message for non-integer input
    }
  };

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setFormData({
        ...formData,
        location: address,
        latitude: latLng.lat,
        longitude: latLng.lng,
      });
    } catch (error) {
      console.error("Error selecting place:", error);
    }
  };

  // const handleChange = (e, field) => {
  //   setFormData({
  //     ...formData,
  //     [field]: e.target.value,
  //   })};
  const navigate = useNavigate();
  const [ageRange, setAgeRange] = React.useState([18, 30]);

  const [ageValues, setAgeValues] = React.useState({
    age_from: ageRange[0],
    age_to: ageRange[1],
  });

  const handleAgeChange = (event, newValue) => {
    setAgeRange(newValue);

    setAgeValues({
      age_from: newValue[0],
      age_to: newValue[1],
    });
  };

  const [formData, setFormData] = React.useState({});

  // if (eventData) {
  //   console.log('Event Data:', eventData);
  //   // Rest of your component logic...
  // } else {
  //   console.error('Event Data is null.');
  // }

  const handleUpdateEvent = async () => {
    console.log("formData.endtime:", formData.endtime);

    try {
      const token = getTokenFromLocalStorage();

      if (!token) {
        console.error("Token not found in local storage");
        return;
      }

      const apiUrl = "addEditEvent";

      const genderIdMap = {
        Male: 1,
        Female: 2,
        Other: 3,
        All: 4,
      };

      const requestData = {
        id: formData.id,
        event_name: formData.event_name,
        description: formData.description,
        // endtime: moment(`${formData.endDate} ${formData.endtime}`, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD HH:mm:ss"),
        // endtime: moment(`${formData.endDate} ${formData.endtime}`, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD HH:mm:ss"),
        // starttime: moment(`${formData.startDate} ${formData.starttime}`, "YYYY-MM-DD h:mm a").format("YYYY-MM-DD HH:mm:ss"),

        // starttime: `${formData.startDate} ${formData.starttime}`,
        endtime: `${formData.endDate} ${formData.endtime}`,
        starttime: `${formData.startDate} ${formData.starttime}`,
        age_from: ageRange[0],
        age_to: ageRange[1],
        gender: formData.gender,
        gender_description: formData.gender_description,
        dresscode: formData.dresscode,
        activity_details: formData.activity_details,
        eventfeature: formData.eventfeature,
        longitude: formData.longitude,
        latitude: formData.latitude,
        location: formData.location,
        no_of_invities: parseInt(formData.no_of_invitees),
      };
      console.log("requestData:", requestData);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // console.log("Request Data:", requestData);
      // console.log("Config:", config);

      const response = await axios.post(apiUrl, requestData, config);

      if (response.data.status === 200) {
        toast.success("Event updated successfully", {
          onClose: () => {
            setTimeout(() => {
              navigate("/my_events");
            }, 1500);
          },
        });
      } else if (response.data.status === 400) {
        toast.error(response.data.message || "Failed to update event");
      } else {
        toast.error("Failed to update event");
      }
    } catch (error) {
      // console.error("API Error:", error);
      toast.error("Failed to update event");
    }
  };

  return (
    <>
      <div className="bg-gray-100_03 flex flex-col font-Lora gap-[30px] items-start justify-start mx-auto w-full">
        <Header2 />
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              direction="column"
              m={2}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Lora",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "40px",
                  color: "#212121",
                  lineHeight: "48px",
                }}
                mb={3}
              >
                Update Event
              </Typography>
              <ThemeProvider theme={headingFont}>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                  }}
                >
                  Event Name
                </Typography>
              </ThemeProvider>
              <TextField
                placeholder="Please Describe activity in 3 or fewer words Name "
                label=""
                size="small"
                margin="normal"
                name="name"
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, event_name: e.target.value })
                }
                value={formData.event_name}
                InputLabelProps={{ shrink: false }}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                    marginTop: "8px",
                    marginBottom: "10px",
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "#863895 !important",
                  },
                  "&.MuiInputBase-colorPrimary": {
                    color: "#fff", // Use 'inherit' or the desired color to override
                  },
                  "& [type='text']:focus": {
                    boxShadow: "none", // Remove box shadow on focus for type='text'
                    // borderColor: "#863895 !important",
                  },
                }}
              />
              <ThemeProvider theme={headingFont}>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                  }}
                >
                  About Event
                </Typography>
              </ThemeProvider>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                label=""
                multiline
                rows={4}
                InputLabelProps={{ shrink: false }}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                value={formData.description}
                placeholder="Brief description of your activity  "
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                    marginTop: "8px",
                    marginBottom: "10px",
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "#863895 !important",
                  },
                  "&:focus": {
                    outline: "none",
                    borderColor: "#ced4da",
                  },
                  "&& textarea:focus": {
                    boxShadow: "none !important",
                    // borderColor: "#863895 !important",
                  },
                }}
              />
              <ThemeProvider theme={headingFont}>
                <Typography
                  component="div"
                  variant="h6"
                  mb={1}
                  sx={{
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                  }}
                >
                  Date
                </Typography>
              </ThemeProvider>
              <Stack direction="row" spacing={2} mt={1}>
                <div>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      mb={1}
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      From
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    type="date"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setFormData({ ...formData, startDate: e.target.value })
                    }
                    value={formData.startDate}
                    fullWidth
                    style={{
                      maxWidth: textFieldMaxWidth,
                      width: "100%",
                      borderColor: borderColor,
                      WebkitAppearance: "none",
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff",
                      },
                      "& [type='date']:focus": {
                        boxShadow: "none",
                      },
                      "& [type='date']::-webkit-calendar-picker-indicator": {
                        display: "block",
                        width: "16px",
                        height: "16px",
                        marginLeft: "4px",
                        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M21 4h-1V3a1 1 0 0 0-2 0v1H6V3a1 1 0 0 0-2 0v1H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 15H4V8h16v11z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        min: currentDate,
                      },
                    }}
                  />
                </div>

                <div>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      mb={1}
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      To
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    type="date"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setFormData({ ...formData, endDate: e.target.value })
                    }
                    value={formData.endDate}
                    fullWidth
                    style={{
                      maxWidth: textFieldMaxWidth,
                      width: "100%",
                      borderColor: borderColor,
                      WebkitAppearance: "none", // Remove default Safari styles
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff",
                      },
                      "& [type='date']:focus": {
                        boxShadow: "none",
                      },
                      "& [type='date']::-webkit-calendar-picker-indicator": {
                        display: "block",
                        width: "16px",
                        height: "16px",
                        marginLeft: "4px",
                        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M21 4h-1V3a1 1 0 0 0-2 0v1H6V3a1 1 0 0 0-2 0v1H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 15H4V8h16v11z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                      },
                    }}
                    InputProps={{
                      inputProps: {
                        min: currentDate,
                      },
                    }}
                  />
                </div>
              </Stack>
              <ThemeProvider theme={headingFont}>
                <Typography
                  component="div"
                  variant="h6"
                  mb={1}
                  sx={{
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                  }}
                >
                  Time
                </Typography>
              </ThemeProvider>
              <Stack direction="row" spacing={2} mt={1}>
                <div>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      mb={1}
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      From
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    type="time"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setFormData({ ...formData, starttime: e.target.value })
                    }
                    value={formData.starttime}
                    fullWidth
                    style={{
                      borderColor: borderColor,
                      width: "100%",
                      WebkitAppearance: "none", // Remove default Safari styles
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff",
                      },
                      "& [type='time']:focus": {
                        boxShadow: "none",
                      },
                      "& [type='time']::-webkit-calendar-picker-indicator": {
                        display: "block",
                        width: "16px",
                        height: "16px",
                        marginLeft: "4px",
                        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm0-16v8H9.5v2H12l2-2v-8h-2z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                      },
                    }}
                  />
                  {/* <div style={{ position: "relative", width: "100%" }}>
                        <TimePicker
                          showSecond={false}
                          showAmPm={false}
                          // value={formData.starttime}
                          // onChange={(e) => handleChange(e, "starttime")}
                          onChange={(e) => setFormData({ ...formData, starttime: e ? e.format("h:mm a") : null })}
                          // value={formData.endtime}
                          value={moment(formData.starttime, "h:mm a")} 
                          format="h:mm a"
                          placeholder="Start Time"
                          open={isStartTimePickerOpen}
                          onClose={() => setIsStartTimePickerOpen(false)}
                          style={{
                            width: "100%",
                          maxWidth: textFieldMaxWidth,

                            WebkitAppearance: "none",
                            border: "1px solid #863895 !important",
                            height: "45px !important",
                            color: "black", 
                          }}
                        />

                        <AccessTimeIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={toggleStartTimePicker}
                        />
                      </div> */}
                </div>

                <div>
                  <ThemeProvider theme={headingFont}>
                    <Typography
                      component="div"
                      variant="h6"
                      mb={1}
                      sx={{
                        fontFamily: "Lora",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#212121",
                        lineHeight: "17px",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      To
                    </Typography>
                  </ThemeProvider>
                  <TextField
                    type="time"
                    size="small"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setFormData({ ...formData, endtime: e.target.value })
                    }
                    value={formData.endtime}
                    fullWidth
                    style={{
                      borderColor: borderColor,
                      width: "100%",
                      WebkitAppearance: "none",
                    }}
                    sx={{
                      "& fieldset": {
                        borderColor: "#863895 !important",
                      },
                      "&.MuiInputBase-colorPrimary": {
                        color: "#fff", // Use 'inherit' or the desired color to override
                      },
                      "& [type='time']:focus": {
                        boxShadow: "none", // Remove box shadow on focus for type='text'
                        // borderColor: "#863895 !important",
                      },
                      "& [type='time']::-webkit-calendar-picker-indicator": {
                        display: "block",
                        width: "16px",
                        height: "16px",
                        marginLeft: "4px",
                        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000' width='16px' height='16px'%3E%3Cpath d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm0-16v8H9.5v2H12l2-2v-8h-2z' fill='%23000000'/%3E%3C/svg%3E") no-repeat center center`,
                      },
                    }}
                  />
                  {/* <div style={{ position: "relative", width: "100%" }}>
                  <TimePicker
                  showSecond={false}
                  showAmPm={false}
                  onChange={(e) => setFormData({ ...formData, endtime: e ? e.format("h:mm a") : null })}
                  // value={formData.endtime}
                  value={moment(formData.endtime, "h:mm a")} 
                  format="h:mm a"
                  placeholder="End Time"
                  open={isEndTimePickerOpen}
                  onClose={() => setIsEndTimePickerOpen(false)}
                  style={{
                    width: "100%",
                    maxWidth: textFieldMaxWidth,
                    WebkitAppearance: "none",
                    border: "1px solid #863895 !important",
                    height: "45px !important",
                  }}
                />
                

                        <AccessTimeIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={toggleEndTimePicker}
                        />
                      </div> */}
                </div>
              </Stack>
              <Box sx={{ width: 300, margin: "20px auto", marginLeft: "20px" }}>
                <h2 style={{ textAlign: "center" }}>Age Range</h2>
                <Slider
                  getAriaLabel={() => "Age range"}
                  value={ageRange}
                  onChange={handleAgeChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `${value}`}
                  min={18}
                  max={100}
                  step={1}
                  disableSwap
                  sx={{
                    color: "purple",
                  }}
                />
              </Box>
              <FormControl>
                <ThemeProvider theme={headingFont}>
                  <Typography component="div" variant="h6" mb={1}>
                    Gender
                  </Typography>
                </ThemeProvider>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={parseInt(formData.gender)}
                  onChange={(event) => handleGenderChange(event)}
                >
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Female"
                    sx={{
                      fontFamily: "Lora",
                      fontStyle: "normal",

                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#212121",
                      lineHeight: "14px",
                    }}
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Male"
                    sx={{
                      fontFamily: "Lora",
                      fontStyle: "normal",

                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#212121",
                      lineHeight: "14px",
                    }}
                  />
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label="Other"
                    sx={{
                      fontFamily: "Lora",
                      fontStyle: "normal",

                      fontWeight: "500",
                      fontSize: "12px",
                      color: "#212121",
                      lineHeight: "14px",
                    }}
                  />
                  <FormControlLabel
                    value={4}
                    control={<Radio />}
                    label="All"
                    sx={{
                      fontFamily: "Lora",
                      fontStyle: "normal",

                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#212121",
                      lineHeight: "14px",
                    }}
                  />
                </RadioGroup>
                {isTextFieldVisible && (
                  <ThemeProvider theme={headingFont}>
                    <Typography component="div" variant="h6" mb={1}>
                      Gender
                    </Typography>
                  </ThemeProvider>
                )}
              </FormControl>
              {isTextFieldVisible && (
                <TextField
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      gender_description: e.target.value,
                    })
                  }
                  value={formData.gender_description}
                  placeholder="You may disclose your gender if you like "
                  label=""
                  name="name"
                  size="small"
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  fullWidth
                  sx={{
                    "& fieldset": {
                      borderColor: "#863895 !important",
                    },
                    "&.MuiInputBase-colorPrimary": {
                      color: "#fff", // Use 'inherit' or the desired color to override
                    },
                    "& [type='text']:focus": {
                      boxShadow: "none", // Remove box shadow on focus for type='text'
                      // borderColor: "#863895 !important",
                    },
                  }}
                />
              )}
              <ThemeProvider theme={headingFont}>
                <Typography component="div" variant="h6" mb={1}>
                  Enter Dress Code
                </Typography>
              </ThemeProvider>
              <TextField
                placeholder="Please enter Name "
                label=""
                name="name"
                size="small"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: false }}
                onChange={(e) =>
                  setFormData({ ...formData, dresscode: e.target.value })
                }
                value={formData.dresscode}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                    marginTop: "8px",
                    marginBottom: "10px",
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "#863895 !important",
                  },
                  "&.MuiInputBase-colorPrimary": {
                    color: "#fff", // Use 'inherit' or the desired color to override
                  },
                  "& [type='text']:focus": {
                    boxShadow: "none", // Remove box shadow on focus for type='text'
                    // borderColor: "#863895 !important",
                  },
                }}
              />
              <Stack>
                <ThemeProvider theme={headingFont}>
                  <Typography component="div" variant="h6" mb={1}>
                    Enter Guest Count
                  </Typography>
                </ThemeProvider>
                <TextField
                  placeholder="How may persons would you like to invite? Enter it here"
                  label=""
                  name="no_of_invitees"
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  onChange={(e) => handleChange(e, "no_of_invitees")}
                  value={formData.no_of_invitees}
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: "Lora",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#212121",
                      lineHeight: "17px",
                      marginTop: "8px",
                      marginBottom: "10px",
                    },
                  }}
                  sx={{
                    "& fieldset": {
                      borderColor: "#863895 !important",
                    },
                    "&.MuiInputBase-colorPrimary": {
                      color: "#fff", // Use 'inherit' or the desired color to override
                    },
                    "& [type='text']:focus": {
                      boxShadow: "none", // Remove box shadow on focus for type='text'
                    },
                  }}
                />
                {error && (
                  <Typography
                    component="p"
                    color="error"
                    sx={{ fontSize: "12px" }}
                  >
                    {error}
                  </Typography>
                )}
              </Stack>
              <ThemeProvider theme={headingFont}>
                <Typography component="div" variant="h6" mb={1}>
                  Enter activity Location
                </Typography>
              </ThemeProvider>
              <PlacesAutocomplete
                value={formData.location}
                onChange={(value) =>
                  handleChange({ target: { value } }, "location")
                }
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <TextField
                      {...getInputProps({
                        placeholder: "Please enter Location",
                        // label: "Location",
                        name: "location",
                        size: "small",
                        variant: "outlined",
                        margin: "normal",

                        InputLabelProps: { shrink: false },
                        fullWidth: true,
                        sx: {
                          "& fieldset": {
                            borderColor: "#863895 !important",
                          },
                          "&.MuiInputBase-colorPrimary": {
                            color: "#fff", // Use 'inherit' or the desired color to override
                          },
                          "& [type='text']:focus": {
                            boxShadow: "none", // Remove box shadow on focus for type='text'
                            // borderColor: "#863895 !important",
                          },
                        },
                      })}
                    />
                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#e6e6e6"
                            : "#fff",
                        };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, { style })}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <ThemeProvider theme={headingFont}>
                <Typography component="div" variant="h6" mb={1}>
                  What l'll be Covering
                </Typography>
              </ThemeProvider>
              <TextField
                placeholder="Covering the cost of any aspect of your activity? Enter it here "
                label=""
                name="name"
                variant="outlined"
                margin="normal"
                InputLabelProps={{ shrink: false }}
                onChange={(e) =>
                  setFormData({ ...formData, eventfeature: e.target.value })
                }
                value={formData.eventfeature}
                fullWidth
                InputProps={{
                  style: {
                    fontFamily: "Lora",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#212121",
                    lineHeight: "17px",
                    marginTop: "8px",
                    marginBottom: "10px",
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "#863895 !important",
                  },
                  "&.MuiInputBase-colorPrimary": {
                    color: "#fff", // Use 'inherit' or the desired color to override
                  },
                  "& [type='text']:focus": {
                    boxShadow: "none", // Remove box shadow on focus for type='text'
                    // borderColor: "#863895 !important",
                  },
                }}
              />
              <ThemeProvider theme={headingFont}>
                <Typography component="div" variant="h6" mb={1}>
                  Activity Detalis
                </Typography>
              </ThemeProvider>
              <TextField
                variant="outlined"
                label=""
                multiline
                size="small"
                margin="normal"
                InputLabelProps={{ shrink: false }}
                inputProps={{
                  style: {
                    height: "100px",
                  },
                }}
                sx={{
                  "& fieldset": {
                    borderColor: "#863895 !important",
                  },
                  "&:focus": {
                    outline: "none",
                    borderColor: "#ced4da",
                  },
                  "&& textarea:focus": {
                    boxShadow: "none !important",
                    // borderColor: "#863895 !important",
                  },
                }}
                onChange={(e) =>
                  setFormData({ ...formData, activity_details: e.target.value })
                }
                value={formData.activity_details}
                placeholder="Enter any other detail about your activity that you’d like to share "
                fullWidth
                style={{ borderColor: borderColor, width: "100%" }}
              />
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                mt={2}
              >
                <Button
                  onClick={handleUpdateEvent}
                  variant="contained"
                  sx={{
                    width: "100%",
                    maxWidth: 200,
                    border: "1px solid #863895",
                    borderRadius: "6px",
                    backgroundColor: "#863895 !important",
                    "&:hover": {
                      backgroundColor: "#863895",
                      border: "1px solid #863895",
                      color: "#FFF",
                    },
                    fontFamily: "Lora",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal",
                    textTransform: "none !important",
                  }}
                >
                  Update Event
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imagefrm}
              alt="tieTwo"
              style={{ maxWidth: "90%", height: "auto" }}
            />
          </Grid>
        </Grid>
        {/* <Footer className="bg-purple-600 flex items-center justify-center md:px-5 w-full" /> */}
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          rtl
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
        />
      </div>
    </>
  );
};

export default EditEventPage;
