// import { createContext, useContext, useEffect, useState } from "react";
// import {
//   onAuthStateChanged,
//   signOut,
//   GoogleAuthProvider,
//   signInWithRedirect,
//   signInWithPopup,
//   createUserWithEmailAndPassword,
//   signInWithEmailAndPassword,
// } from "firebase/auth";
// import { auth, db, updateOnlineStatus } from "../Firebase";
// import { updateDoc, doc, setDoc, Timestamp } from "firebase/firestore";
// import toast from "react-hot-toast";

// const userAuthContext = createContext();

// export function UserAuthContextProvider({ children }) {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const logOut = async () => {
//     if (user) {
//       await updateOnlineStatus(user.uid, false);
//     }
//     await signOut(auth);
//     setUser(null);
//   };

//   const googleSignIn = async () => {
//     const googleAuthProvider = new GoogleAuthProvider();
//     try {
//       if (detectMob()) {
//         await signInWithRedirect(auth, googleAuthProvider);
//       } else {
//         await signInWithPopup(auth, googleAuthProvider);
//       }
//     } catch (err) {
//       alert(err.message);
//     }
//   };

//   const detectMob = () => {
//     const toMatch = [
//       /Android/i,
//       /webOS/i,
//       /iPhone/i,
//       /iPad/i,
//       /iPod/i,
//       /BlackBerry/i,
//       /Windows Phone/i,
//     ];

//     return toMatch.some((toMatchItem) =>
//       navigator.userAgent.match(toMatchItem)
//     );
//   };
//   const formatDisplayName = (email) => {
//     const namePart = email.match(/^([^@]*)@/)[1];
//     // Remove numbers
//     const cleanedName = namePart.replace(/[0-9]/g, "");
//     // Add spacing between words
//     const spacedName = cleanedName.replace(/([a-z])([A-Z])/g, "$1 $2");
//     // Capitalize first letter of each word
//     const formattedName = spacedName.replace(/\b\w/g, (char) =>
//       char.toUpperCase()
//     );
//     return formattedName;
//   };
//   // const profileCreation = async (user, profileImage) => {
//   //   if (user) {
//   //     try {
//   //       const userDocRef = doc(db, "users", user.uid);
//   //       const userData = {
//   //         uid: user.uid,
//   //         displayName: user.displayName
//   //           ? user.displayName
//   //           : user.email.match(/^([^@]*)@/)[1],
//   //         created_at: Timestamp.now(),
//   //         email: user.email,
//   //       };

//   //       if (profileImage) {
//   //         userData.profileImage = profileImage;
//   //       }

//   //       await setDoc(userDocRef, userData, { merge: true });
//   //     } catch (err) {
//   //       console.error("Firestore error during profile creation:", err);
//   //       window.confirm(
//   //         `Something went wrong during profile creation. Please retry. Error: ${err.message}`
//   //       ) === true && window.location.reload();
//   //     }
//   //   }
//   // };
//   const profileCreation = async (user, profileImage) => {
//     if (user) {
//       try {
//         const userDocRef = doc(db, "users", user.uid);
//         let displayName = user.displayName
//           ? user.displayName
//           : formatDisplayName(user.email);

//         const userData = {
//           uid: user.uid,
//           displayName: displayName,
//           created_at: Timestamp.now(),
//           email: user.email,
//         };

//         if (profileImage) {
//           userData.profileImage = profileImage;
//         }

//         await setDoc(userDocRef, userData, { merge: true });

//         // Listen for user profile updates to update the display name
//         // user.onAuthStateChanged(async (updatedUser) => {
//         //   if (
//         //     updatedUser &&
//         //     updatedUser.displayName &&
//         //     updatedUser.displayName !== displayName
//         //   ) {
//         //     displayName = updatedUser.displayName;
//         //     await setDoc(
//         //       userDocRef,
//         //       { displayName: displayName },
//         //       { merge: true }
//         //     );
//         //   }
//         // });
//       } catch (err) {
//         console.error("Firestore error during profile creation:", err);
//         // window.confirm(
//         //   `Something went wrong during profile creation. Please retry. Error: ${err.message}`
//         // ) === true && window.location.reload();
//       }
//     }
//   };
//   const SendDataToFirebase = async (
//     email,
//     fullName,
//     password,
//     blocked,
//     ...profileImages
//   ) => {
//     try {
//       const { user } = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       await addFirestore(
//         user.uid,
//         email,
//         fullName,
//         blocked,
//         profileImages[0],
//         false
//       );
//       await profileCreation(user, profileImages[0]);
//       updateOnlineStatus(user.uid, true);
//     } catch (error) {
//       handleAuthError(
//         error,
//         email,
//         fullName,
//         password,
//         blocked,
//         profileImages[0]
//       );
//     }
//   };

//   const handleAuthError = async (
//     error,
//     email,
//     fullName,
//     password,
//     blocked,
//     profileImage
//   ) => {
//     console.error(
//       `Error during registration - Email: ${email}, FullName: ${fullName}`,
//       error.code,
//       error.message
//     );

//     if (error.code === "auth/invalid-email") {
//       toast.error(
//         "Invalid email format. Please provide a valid email address."
//       );
//     } else if (error.code === "auth/weak-password") {
//       toast.error("Weak password. Please provide a stronger password.");
//     } else if (error.code === "auth/email-already-in-use") {
//       // Attempt to sign in with existing credentials
//       try {
//         const userCredential = await signInWithEmailAndPassword(
//           auth,
//           email,
//           password
//         );
//         await updateFirestore(
//           userCredential.user.uid,
//           email,
//           fullName,
//           blocked,
//           profileImage,
//           true
//         );

//         console.log("User signed in successfully!");
//         await profileCreation(userCredential.user, profileImage);
//         updateOnlineStatus(userCredential.user.uid, true);
//       } catch (signInError) {
//         console.error("Error signing in existing user:", signInError);
//         toast.error("Registration failed. Please try again.");
//       }
//     } else {
//       toast.error("Registration failed. Please try again.");
//     }
//   };

//   const addFirestore = async (
//     uid,
//     email,
//     fullName,
//     blocked,
//     profileImage,
//     online
//   ) => {
//     const userDocRef = doc(db, "users", uid);
//     await setDoc(userDocRef, {
//       uid: uid,
//       displayName: fullName,
//       created_at: Timestamp.now(),
//       email: email,
//       profileImage: profileImage,
//       blocked: blocked || false,
//       online: online || false,
//     });
//   };

//   const updateFirestore = async (
//     uid,
//     email,
//     fullName,
//     blocked,
//     profileImage,
//     online
//   ) => {
//     const userDocRef = doc(db, "users", uid);
//     await updateDoc(userDocRef, {
//       displayName: fullName,
//       email: email,
//       blocked: blocked,
//       profileImage: profileImage,
//       online: online,
//     });
//   };

//   useEffect(() => {
//     setLoading(true);
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       if (currentUser) {
//         setUser(currentUser);
//         profileCreation(currentUser);
//       } else {
//         setUser(null);
//       }
//       setLoading(false);
//       setError(null);
//     });

//     return () => unsubscribe();
//   }, []);

//   return (
//     <userAuthContext.Provider
//       value={{
//         user,
//         loading,
//         googleSignIn,
//         logOut,
//         error,
//         SendDataToFirebase,
//       }}
//     >
//       {children}
//     </userAuthContext.Provider>
//   );
// }

// export function useUserAuth() {
//   return useContext(userAuthContext);
// }
import { createContext, useContext, useEffect, useState } from "react";
import {
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db, updateOnlineStatus } from "../Firebase";
import { updateDoc, doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import toast from "react-hot-toast";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const logOut = async () => {
    if (user) {
      await updateOnlineStatus(user.uid, false);
    }
    await signOut(auth);
    setUser(null);
  };

  const googleSignIn = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    try {
      if (detectMob()) {
        await signInWithRedirect(auth, googleAuthProvider);
      } else {
        await signInWithPopup(auth, googleAuthProvider);
      }
    } catch (err) {
      alert(err.message);
    }
  };

  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) =>
      navigator.userAgent.match(toMatchItem)
    );
  };

  const profileCreation = async (user, fullName, profileImages) => {
    if (user) {
      try {
        const userDocRef = doc(db, "users", user.uid);

        let displayName = fullName;
        if (!displayName) {
          displayName = user.displayName ? user.displayName : user.email;
        }

        const userData = {
          uid: user.uid,
          displayName: displayName,
          created_at: Timestamp.now(),
          email: user.email,
          profileImages: profileImages, // Store all images
        };

        await setDoc(userDocRef, userData, { merge: true });
        // console.log("Profile created or updated in Firestore:", userData);
      } catch (err) {
        console.error("Firestore error during profile creation:", err);
      }
    }
  };

  const SendDataToFirebase = async (
    email,
    fullName,
    password,
    blocked,
    ...profileImages
  ) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await addFirestore(
        user.uid,
        email,
        fullName,
        blocked,
        profileImages,
        false
      );
      await profileCreation(user, fullName, profileImages);
      updateOnlineStatus(user.uid, true);
    } catch (error) {
      handleAuthError(error, email, fullName, password, blocked, profileImages);
    }
  };

  const handleAuthError = async (
    error,
    email,
    fullName,
    password,
    blocked,
    profileImages
  ) => {
    console.error(
      `Error during registration - Email: ${email}, FullName: ${fullName}`,
      error.code,
      error.message
    );

    if (error.code === "auth/invalid-email") {
      toast.error(
        "Invalid email format. Please provide a valid email address."
      );
    } else if (error.code === "auth/weak-password") {
      toast.error("Weak password. Please provide a stronger password.");
    } else if (error.code === "auth/email-already-in-use") {
      try {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        await updateFirestore(
          userCredential.user.uid,
          email,
          fullName,
          blocked,
          profileImages,
          true
        );

        await profileCreation(userCredential.user, fullName, profileImages);
        updateOnlineStatus(userCredential.user.uid, true);
      } catch (signInError) {
        console.error("Error signing in existing user:", signInError);
      }
    } else {
      console.error("Registration failed. Please try again.");
    }
  };

  const addFirestore = async (
    uid,
    email,
    fullName,
    blocked,
    profileImages,
    online
  ) => {
    const userDocRef = doc(db, "users", uid);
    await setDoc(userDocRef, {
      uid: uid,
      displayName: fullName,
      created_at: Timestamp.now(),
      email: email,
      profileImages: profileImages, // Store all images
      blocked: blocked || false,
      online: online || false,
    });
    // console.log("Firestore document added or updated:", {
    //   uid,
    //   displayName: fullName,
    //   email,
    //   profileImages,
    //   blocked,
    //   online,
    // });
  };

  const updateFirestore = async (
    uid,
    email,
    fullName,
    blocked,
    profileImages,
    online
  ) => {
    const userDocRef = doc(db, "users", uid);
    await updateDoc(userDocRef, {
      displayName: fullName,
      email: email,
      blocked: blocked,
      profileImages: profileImages, // Update all images
      online: online,
    });
    // console.log("Firestore document updated:", {
    //   uid,
    //   displayName: fullName,
    //   email,
    //   profileImages,
    //   blocked,
    //   online,
    // });
  };

  const fetchAndLogUserData = async (uid, fullName) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (!userData.displayName || userData.displayName === "") {
          await updateDoc(userDocRef, { displayName: fullName });
          userData.displayName = fullName;
        }
        // console.log("Fetched user data from Firestore:", userData);
      } else {
        console.log("No such user document!");
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const fullName = currentUser.displayName || currentUser.email;
        await profileCreation(currentUser, fullName);
        await fetchAndLogUserData(currentUser.uid, fullName);
      } else {
        setUser(null);
      }
      setLoading(false);
      setError(null);
    });

    return () => unsubscribe();
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        loading,
        googleSignIn,
        logOut,
        error,
        SendDataToFirebase,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
